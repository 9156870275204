<template>
 <div class="wrong_question">
     <div class="recite_tag">
         <span :class="['recite_item',{'active':tagIndex == 0}]" @click="changeTag(0)"  style="border-right:none;">未背</span>
         <span :class="['recite_item',{'active':tagIndex == 1}]" @click="changeTag(1)" style="border-left:none;">已背</span>
         <div class="beiti">
             总题数：<span style="color:#E1860F">{{totalQues.all_count}}</span>
             已背： <span style="color:#2D81E4">{{totalQues.user_all_count}}</span>
             未背：<span style="color:#E1410F">{{totalQues.all_count - totalQues.user_all_count}}</span>
         </div>
     </div>
     <el-table
     v-loading="loading"
        :data="tableData"
        :row-class-name="tableRowClassName"
        @row-click="rowClick"
        :header-cell-style="{background: '#DCDCDC',color:'#333',fontSize:'16px',opacity:0.8,height:'60px'}"
        style="width: 100%">
        <!-- <el-table-column
            type="expand">
            <template slot-scope="props">
                <ul>
                    <li class="menu_list" v-for="(item,index) in props.row.data" :key="index" @click="rowClick(props.row,item)">
                        {{item.content}}
                    </li>
                </ul>
            </template>
        </el-table-column> -->
        <el-table-column
            prop="cate_name"
            label="学科">
            <template slot-scope="scope">
                <div style="cursor: pointer;">
                    {{scope.row.cate_name}}
                </div>
            </template>
        </el-table-column>
        <el-table-column
            prop="cate_count"
            label="题目数">
            <template slot-scope="scope">
                <div style="cursor: pointer;">
                    {{scope.row.cate_count}}
                </div>
            </template>
        </el-table-column>
        <el-table-column
            prop="user_count"
            label="已背">
        </el-table-column>
        <el-table-column
            prop="cate_name"
            label="未背">
            <template slot-scope="scope">
                <div>
                    {{scope.row.cate_count  - scope.row.user_count }}
                </div>
            </template>
        </el-table-column>
        <!-- <el-table-column
            prop="total"
            label="题目数"
            width="180">
        </el-table-column> -->
    </el-table>
 </div>
</template>

<script>
import * as http from '@/assets/api/http.js'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
 export default {
  name: '',
  data () {
   return {
       tableData: [],
       tagIndex:0,
       loading:true,
       totalQues:{}
   }
  },
  created(){
      this.reciteListPc()
  },
  methods:{
      tableRowClassName(){
          return 'tableRow'
      },
      rowClick(row, column){
          this.$router.push({
              path:'/reciteDetails',
              query:{
                  data:row.data,
                  tagType:this.tagIndex,
                  cate_name:row.cate_name
              }
          })
      },
    //   背题列表
     reciteListPc(){
        let params = {
            cate_id:this.cate_id
        }
      http.reciteListPc(params).then(value=>{
            if(value.code == 200){
                this.tableData = value.data.data;
                this.loading = false;
                this.totalQues = value.data.statistics
            }
        })
    },
    // 我的背题列表
    myRecitePc(){
        let params = {
            cate_id:this.cate_id
        }
      http.myRecitePc(params).then(value=>{
            if(value.code == 200){
                this.tableData = value.data.data;
                this.totalQues = value.data.statistics
            }
        })
    },
    // 查看错题
    todetails(item1,item2){
        this.$router.push({
              path:'/reciteDetails',
              query:{
                  subject_id:item1.id,
                  menu_id:item2.menu_id
              }
          })
    },
    changeTag(index){
        this.tagIndex = index;
        if(index == 0){this.reciteListPc()}
        if(index == 1){this.myRecitePc()}
    }
  },
  components: {

  },
  computed:{
    ...mapState(['cate_id'])
  },
 }
</script>

<style scoped lang="scss">
.wrong_question{
    .wrong_number{
        color:#666666;
        font-size:18px;
    }
    /deep/.el-table .tableRow{
        height:74px;
    }
    /deep/.el-table .el-table__expanded-cell{
        padding:0
    }
}
 .menu_list{
     background:#f8f8fb;
     font-size: 14px;
    color: #909399;
    line-height:50px;
    border-bottom: 1px solid #f8f8fb;
    padding-left:30px;
    cursor: pointer;
 }
 .recite_tag{
     display:flex;
     margin-bottom: 15px;
     align-items: center;
     .recite_item{
        width: 91px;
        height: 28px;
        border-radius: 7px;
        line-height:28px;
        text-align: center;
        color:#666666;
        cursor: pointer;
        border:1px solid #DCDCDC;
        
        &.active{
            background: #CF0A00;
            color:#fff;
            border-color:#CF0A00;
        }
     }
 }
 .beiti{
     margin-left:32px;
     font-size: 18px;
     span{
         margin-right:30px;
     }
 }
 /deep/ .el-table__row{
     cursor: pointer;
 }
</style>
