<template>
  <ul class='questionBank'>
                    <li class='questionBank_items' v-for="(item,index) in 5" :key="index">
                        <div class="class_name">
                            第一章   绪论
                        </div>
                        <div class="class_study">
                            3000人学习
                        </div>
                        <div class='error_num'>
                            错3个题
                        </div>
                        <div class='look_error'>
                            查看错题
                        </div>
                        <div class='look_error'>
                            重做错题
                        </div>
                        <div style="cursor:pointer">
                            开始答题
                        </div>
                    </li>
                </ul>
</template>

<script>
export default {
  data() {
    return {

    };
  },
  methods: {

  },
//   created() {

//   },
//   mounted() {

//   },
  components: {},
}
</script>

<style lang="scss" scoped>
.questionBank{
        .questionBank_items{
            height:75px;
            border-bottom: 1px solid #DCDCDC;
            line-height: 75px;
            display:flex;
            padding:0 25px;
            color:#333;
            font-size:14px;
            .class_name{
                width:310px;
            }
            .class_study{
                width: 130px;
            }
            .error_num{
                width: 106px;
            }
            .look_error{
                width:110px;
                cursor: pointer;
            }
            &:last-child{
                border:none
            }
        }
    }
</style>