<template>
 <div  class="my_container">
     <p class="my_page_tit">
          <router-link to="/index">首页</router-link> > 关于我们
      </p>
      <div class="about_us">
          <p class='about_us_title'>关于我们</p>
          <div v-html="content"></div>
      </div>
 </div>
</template>

<script>
import * as http from '@/assets/api/http.js'
 export default {
  name: '',
  data () {
   return {
       content:''
   }
  },
  methods:{
      getSingleArticle(){
          let that = this;
        let formdata = new FormData();
          formdata.append('id',3);
           http.getSingleArticle(formdata).then(value=>{
            if(value.code == 200){
              
              this.content = value.data.content
            }else{
              this.$message.error(value.msg)
            }
        })
      }
  },
  mounted(){
      this.getSingleArticle()
  },
  components: {

  }
 }
</script>

<style scoped lang="scss">
.my_container{
        width:1200px;
        margin:0 auto;
        margin-bottom: 80px;
        .my_page_tit{
            padding:30px 0;
            color:#666;
            font-size:12px;
        }
        .about_us{
            min-height:600px;
            box-sizing: border-box;
            padding:40px 30px;
            background:#fff;
            .about_us_title{
                font-size:24px;
                color:#666;
                margin-bottom:40px;
                text-align: center;
            }
        }
}
</style>
