import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import tool from './utils/tool.js'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.use(tool);
// 测试环境域名
// Vue.prototype.$url = 'https://wenyun.1234ok.cn/'
// 正式环境域名
Vue.prototype.$url = 'https://app.wyfashuo.com/'



// 测试环境分享域名
// Vue.prototype.$shareUrl = 'http://wenyunpc.1234ok.cn/'
// 正式环境分享域名
Vue.prototype.$shareUrl = 'https://wyfs.wyfashuo.com/'
import '../src/assets/style/reset.css'

// 过滤器
Vue.filter('fixImg',(path) =>{
  if(path && path.includes('https')){
    return path
  }
  // 测试环境域名
  // return `https://wenyun.1234ok.cn/${path}`
  // 正式环境域名
  return `https://app.wyfashuo.com/${path}`
})
// 分页器
import pagition from '@/components/pagination/index.vue'

Vue.component("pagition", pagition);

// 视频
import VideoPlayer from 'vue-video-player'
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
//引入 hls,视频直播(m3u8)必须引入的
import 'videojs-contrib-hls'
//播放rtmp视频
import 'videojs-flash'
require('./assets/css/myvideo.css')
Vue.use(VideoPlayer)
  const svue =new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
export default svue;
