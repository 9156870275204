<template>
 <div class="my_container">
        <p class="my_page_tit">
          <router-link to="/index">首页</router-link> > <router-link to="/questionBank">题库</router-link> > 模拟考试
      </p>
    <QuestionLayout
    v-if="show"
     ref="QuestionLayout"
        :questionNumber="questionNumber"
        :hasSubmit="true"
        :islook="islook"
        @submit="handinpaper"
    >
        <template v-slot:title>
            <div class="titCol" >
                    <span>随机组题</span>   
                    <span v-if="seconds != null && !islook">{{hours}}:{{minutes}}:{{seconds}}</span>
                    <span class="submitpaper" v-if="!islook" @click="handinpaper">交卷</span> 
            </div>
        </template>
        <template v-slot:submit>
            <span :class="['subAnswer']" @click="submit"> 提交答案</span>
        </template>
    </QuestionLayout>

    <el-dialog
    :visible.sync="dialogVisible"
    :show-close="false"
    :center="true"
    width="430px">
        <div class="tips_title">
            <img src="../../assets/image/question/tips.png" alt="">
            <span>提示</span>
        </div>
        <div class="tips_content" v-if="no_answer != 0">
            您还有{{no_answer}}题未做，确定要交卷吗，交卷之后将不能继续此次考试
        </div>
        <div class="tips_content" v-if="no_answer == 0">
           您已答完所有题目，是否交卷，交卷之后将不能继续此次考试
        </div>
    <div  class="dialog-footer">
        <span class="tips_btn" @click="cancel">取 消</span>
        <span class="tips_btn tips_btn_submit" type="primary" @click="changeMdoel">提交</span>
    </div>
    </el-dialog>
    <el-dialog
    :visible.sync="timeover"
    :show-close="false"
    :center="true"
    width="430px">
        <div class="tips_title">
            <img src="../../assets/image/question/tips.png" alt="">
            <span>提示</span>
        </div>
        <div class="tips_content" >
            本次考试时间已结束,请点击确认查看本次考试结果
        </div>
    <div  class="dialog-footer">
        <span class="tips_btn tips_btn_submit" type="primary" @click="changeMdoel">确定</span>
    </div>
    </el-dialog>
 </div>
</template>

<script>
let timer;
import * as http from '@/assets/api/http.js'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import QuestionLayout from '@/components/questionLayout/questionLayout.vue'
 export default {
  name: '',
  data () {
   return {
       questionNumber:[],
       minutes:null,
       seconds:null,
       hours:null,
       maxtime:3600,
       dialogVisible:false,
       no_answer:0,
       islook:false,
       timeover:false,
       show:false
   }
  },
  created(){
    //   timer = setInterval(this.CountDown(), 1000);
      this.questionNumber = JSON.parse(this.$route.query.data);
      this.questionNumber.forEach(v=>{
                    if(v.type !=3){
                        this.$set(v,'answerSelect',[])
                        this.$set(v,'answerError',[])
                    }else{
                        this.$set(v,'answerSelect','')
                        this.$set(v,'answerError','')
                    }
                    
                })
                this.maxtime = this.$route.query.time*60;
                timer = setInterval(()=>{
                    this.CountDown()
                },1000)
    this.show = true;
  },
  computed:{
    ...mapState(['cate_id'])
  },
  methods:{
    // 计算倒计时
    CountDown() {
        if (this.maxtime >= 0) {
            let hours,minutes,seconds;
            hours = Math.floor(this.maxtime / 3600);
            minutes = Math.floor(this.maxtime / 60);
            seconds = Math.floor(this.maxtime % 60);
            hours <10 ? hours = "0"+hours : hours;
            minutes <10 ? minutes = "0"+minutes : minutes;
            seconds <10 ? seconds = "0"+seconds : seconds;
            this.hours = hours;
            this.minutes = minutes;
            this.seconds = seconds;
                --this.maxtime;
        } else{
            clearInterval(timer);
                 this.dialogVisible = true;
                this.timeover = true;
            }
    },
    submit(){

    },
    // 交卷
    handinpaper(){
        this.no_answer = 0
        console.log(this.$refs.QuestionLayout.questionBanlList,'this.$refs.questionNumber.questionBanlList')
        this.$refs.QuestionLayout.questionBanlList.forEach(v=>{
            if(!v.answerSelect.length){
                this.no_answer++;
            }
        })
        this.dialogVisible = true;
    },
    // 模拟考场提交
    simulationSubmit(){
        let answers = []
        this.$refs.QuestionLayout.questionBanlList.forEach(v=>{
            let arr = JSON.parse(JSON.stringify(v.answerSelect))
            arr.forEach((v,index)=>{
                    if(v == 1){
                        arr.splice(index,1,'A')
                    }
                    if(v == 2){
                        arr.splice(index,1,'B')
                    }
                    if(v == 3){
                        arr.splice(index,1,'C')
                    }
                    if(v == 4){
                        arr.splice(index,1,D)
                    }
                })
            let obj = {
                exercises_id:v.id,
                answer:arr.join(',')
            }
            answers.push(obj)
        })
        let params = {
            simulation_id:this.$route.query.simulation_id,
            answers
        }
        let that = this;
        let formdata = new FormData();
        
        formdata.append('uid',that.$store.state.uid);
        formdata.append('token',that.$store.state.token);
        formdata.append('simulation_id',that.$route.query.simulation_id);
        formdata.append('answers',JSON.stringify(answers));
      http.simulationSubmit(formdata).then(value=>{
            if(value.code == 200){
                // this.tableData = value.data;
            }
        })
    },
    // 考试完毕提交答案
    changeMdoel(){
        this.dialogVisible = false;
        this.timeover=false;
        this.islook = true;
        console.log(this.$refs.QuestionLayout.questionBanlList,'asds')
        this.simulationSubmit()
        // 对考试提交的答案添加回答正确与否的判断
        this.$refs.QuestionLayout.questionBanlList.forEach(v=>{
            if(v.type != 3){
                
                 v.answerSelect.sort();   //对选项进行排序
                let arr =   v.correct_answer.split(',');
                arr.forEach((v,index)=>{
                    if(v == 'A'){
                        arr.splice(index,1,1)
                    }
                    if(v == 'B'){
                        arr.splice(index,1,2)
                    }
                    if(v == 'C'){
                        arr.splice(index,1,3)
                    }
                    if(v == 'D'){
                        arr.splice(index,1,4)
                    }
                })
                arr.sort()
                this.$set(v,'correct_answer_arr',arr)
                if(arr.join(',') == v.answerSelect.join(',')){
                    this.$set(v,'is_ans_current',true)
                }else{
                    this.$set(v,'is_ans_current',false)
                }
            }
           
        })
        console.log(this.$refs.QuestionLayout.questionBanlList,'this.$refs.QuestionLayout.questionBanlList')
    },
    cancel(){
        this.dialogVisible = false
    }
  },
  destroyed(){
      clearInterval(timer);
  },
  components: {
      QuestionLayout
  }
 }
</script>

<style scoped lang="scss">
.my_container{
        width:1200px;
        margin:0 auto;
        margin-bottom: 80px;
        .my_page_tit{
            padding:30px 0;
            color:#666;
            font-size:12px;
            padding-bottom: 0;
        }
}
.titCol{
    display:flex;
    justify-content: space-between;
    align-items: center;
}
.subAnswer{
                width: 80px;
                height: 30px;
                background: #DC091D;
                border-radius: 8px;
                color:#fff;
                font-size:14px;
                text-align: center;
                line-height: 30px;
                margin:0 20px;
                cursor: pointer;
                // opacity: 0.1;
            }
.tips_title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img{
        width:80px;
        height: 80px;
        margin-bottom: 20px;
    }
}
.tips_content{
    margin-top:15px;
    font-size:16px;
    color:#5F6E78;
    text-align: center;
    line-height:1.5
}
.tips_btn{
    width:162px;
    height:52px;
    line-height:52px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 16px;
    margin-right:10px;
    font-size:16px;
    cursor: pointer;
}
.tips_btn_submit{
    border: 1px solid #DC091D;
    color:#fff;
    background:#DC091D;
}
.dialog-footer{
    display:flex;
    justify-content: center;
    margin-top:35px;
}
/deep/ .el-dialog__header{
    display:none;
}
.submitpaper{
    background: #F3F8FE;
    border-radius: 8px;
    width: 45px;
    height: 24px;
    line-height:24px;
    color:#2D81E4;
    font-size:14px;
    text-align: center;
    margin-right:20px;
    cursor: pointer;
}
</style>
