import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 全局Loading
    fullscreenLoading:true,
    // token
    uid:'',
    token:'',
    personInfo:{},
    searchFlag:false,
    cate_id:null,     //题库类别ID
  },
  mutations: {
    changeLoading(state,status){
      state.fullscreenLoading = status;
    },
    // 存储uid
    saveUid(state,uid){
      state.uid = uid;
      sessionStorage.setItem('uid',JSON.stringify(uid))
    },
    // 存储token
    saveToken(state,token){
      state.token = token;
      sessionStorage.setItem('token',JSON.stringify(token))
    },
    // 存储个人信息
    savePersonInfo(state,personInfo){
      state.personInfo = personInfo;
      sessionStorage.setItem('personInfo',JSON.stringify(personInfo))
    },
    // 退出页面清除缓存
    clearInfo(state){
      sessionStorage.clear();
      state.uid='';
      state.token='';
      state.personInfo={}
    },
    // 更改搜索状态出发修改
    searchFlag(state,value){
      state.searchFlag = value
    },
    // 修改题库选中的下标
    changeQuestionIndex(state,value){
      console.log(888)
      state.cate_id = value;
      sessionStorage.setItem('cate_id',JSON.stringify(value))
    },
  },
  actions: {
  },
  modules: {
  }
})
