<template>
 <div class="wrong_question">
     <el-table
        :data="tableData"
        :row-class-name="tableRowClassName"
        @row-click="rowClick"
        :header-cell-style="{background: '#DCDCDC',color:'#333',fontSize:'16px',opacity:0.8,height:'60px'}"
        style="width: 100%">
        <!-- <el-table-column
            type="expand">
            <template slot-scope="props">
                <ul>
                    <li class="menu_list" v-for="(item,index) in props.row.data" :key="index" @click="todetails(props.row,item)">
                        {{item.content}}
                    </li>
                </ul>
            </template>
        </el-table-column> -->
        <el-table-column
            prop="cate_name"
            label="试卷名称">
            <template slot-scope="scope">
                        <span style="line-height:40px;">{{scope.row.name}}</span>
                        <div v-if="scope.row.is_inner == 1" style="padding-bottom:10px;">
                          <span style="    background: red;
    color: #fff;
    padding: 5px;
    border-radius: 10px;">内部卷</span>
                        </div>
                        <div v-if="scope.row.is_inner != 1" style="padding-bottom:10px;">
                          <div style=" 
    color: #fff;
    height:10px;
    border-radius: 10px;"></div>
                        </div>
                    </template>
        </el-table-column>
        <el-table-column
            prop="total"
            label="更新时间"
            width="180">
            <template slot-scope="scope">
                {{timestampToTime(scope.row.updated_at*1000)}}
            </template>
        </el-table-column>
    </el-table>
    <!-- 提示 -->
    <el-dialog
    :visible.sync="timeover"
    :show-close="false"
    :center="true"
    width="430px">
        <div class="tips_title">
            <img src="../../assets/image/question/tips.png" alt="">
            <span>提示</span>
        </div>
        <div class="tips_content" >
            模考截止时间为{{curInfo.end_at}}
        </div>
    <div  class="dialog-footer">
        <span class="tips_btn tips_btn_submit" type="primary" @click="cancel">确定</span>
    </div>
    </el-dialog>
 </div>
</template>

<script>
import * as http from '@/assets/api/http.js'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
 export default {
  name: '',
  data () {
   return {
       tableData: [],
       tagIndex:0,
       highwrong:{},
       curInfo:{},
       timeover:false
   }
  },
  created(){
      this.simulationList();
  },
  methods:{
      tableRowClassName(){
          return 'tableRow'
      },
      rowClick(row, column){
          this.curInfo = row;
          console.log(this.getNowTime())
          if(this.getNowTime()){
              this.timeover = true;
              return
          }
          
          this.$router.push({
              path:'/imitateTestDetails',
              query:{
                  data:JSON.stringify(row.exercises),
                  simulation_id:row.id,
                  time:row.minute
              }
          })
      },
    //   获取当前时间
        getNowTime(){
            let time = new Date(this.curInfo.end_at).getTime()
            let time2 = new Date().getTime();
            return time2>time
            console.log(time)
        },
        cancel(){
            this.timeover = false;
        },
    //  高频错题 
     simulationList(){
        let params = {
            cate_id:this.cate_id
        }
      http.simulationList(params).then(value=>{
            if(value.code == 200){
                this.tableData = value.data;
            }
        })
    },
  },
  components: {

  },
  computed:{
    ...mapState(['cate_id'])
  },
 }
</script>

<style scoped lang="scss">
.wrong_question{
    .wrong_number{
        color:#666666;
        font-size:18px;
    }
    /deep/.el-table .tableRow{
        height:74px;
    }
    /deep/.el-table .el-table__expanded-cell{
        padding:0
    }
}
 .menu_list{
     background:#f8f8fb;
     font-size: 14px;
    color: #909399;
    line-height:50px;
    border-bottom: 1px solid #f8f8fb;
    padding-left:30px;
    cursor: pointer;
 }
 .recite_tag{
     display:flex;
     margin-bottom: 15px;
     .recite_item{
        width: 91px;
        height: 28px;
        border-radius: 7px;
        line-height:28px;
        text-align: center;
        color:#666666;
        cursor: pointer;
        border:1px solid #DCDCDC;
        
        &.active{
            background: #CF0A00;
            color:#fff;
            border-color:#CF0A00;
        }
     }
 }
 .point_tips{
     color:#E1410F;
     padding:9px 14px;
     background:#FCECE7;
     opacity: 0.5;
     margin-bottom: 20px;;
 }
 .tips_title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img{
        width:80px;
        height: 80px;
        margin-bottom: 20px;
    }
}
.tips_content{
    margin-top:15px;
    font-size:16px;
    color:#5F6E78;
    text-align: center;
    line-height:1.5
}
.tips_btn{
    width:162px;
    height:52px;
    line-height:52px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 16px;
    margin-right:10px;
    font-size:16px;
    cursor: pointer;
}
.dialog-footer{
    display:flex;
    justify-content: center;
    margin-top:35px;
}
</style>
