<template>
  <div class="my_order">
      <el-table
            :data="tableData"
            :header-cell-style="{background: '#DCDCDC',color:'#333',fontSize:'16px',opacity:0.8}"
            style="width: 100%">
            <el-table-column
                    prop="code"
                    label="课程名称"
                    >
                    <template slot-scope="scope">
                        <span style="line-height:40px;">{{scope.row.course_name}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="code"
                    label="支付码"
                    width="400"
                    >
                    <template slot-scope="scope">
                        <span style="line-height:40px;">{{scope.row.code}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="content"
                    label="使用状态"
                    
                    >
                     <template slot-scope="scope">
                        <span class="message_content" v-if="scope.row.status == 1" style="line-height:40px;">已使用</span>
                        <span class="message_content" v-if="scope.row.status != 1" style="line-height:40px;">未使用</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="type"
                    label="使用时间"
                    width="200"
                    >
                     <template slot-scope="scope">
                        <span style="line-height:40px;">{{timestampToTime(scope.row.created_at*1000)}}</span>
                    </template>
                </el-table-column>
            </el-table>
  </div>
</template>

<script>
import * as http from '@/assets/api/http.js'
export default {
  data() {
    return {
        tableData: []
    };
  },
  methods: {
    //   获取系统消息
    paycodeList(){
      let that = this;
      let formdata = new FormData();
      formdata.append('uid',that.$store.state.uid);
      formdata.append('token',that.$store.state.token);
      formdata.append('type',2);
      http.paycodeList(formdata).then(value=>{
            if(value.code == 200){
              this.tableData = value.data;
            }else{
              this.$message.error(value.msg)
            }
        })
    },
  },
  created() {
      this.paycodeList()
  },
//   mounted() {

//   },
  components: {},
}
</script>

<style lang="scss" scoped>
.my_order{
    padding:40px 30px;
    /deep/ .el-table td, .el-table th{
        padding:0
    }
    .message_content{
        color:#333333;
        line-height:1;
        font-size:14px;
        overflow : hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}
</style>