<template>
  <div class="my_order">
      <h3 class="class_title">客服</h3>
      <div class="tips">温馨提示：咨询客服时间工作日10：00-18：00</div>
      <div class="phone">
          电话：18518090001
      </div>
      <div class="phone">
          QQ：996901186
      </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    };
  },
  methods: {

  },
//   created() {

//   },
//   mounted() {

//   },
  components: {},
}
</script>

<style lang="scss" scoped>
.my_order{
    padding:40px 30px;
    .class_title{
        color:#666666;
        font-size:24px;
        margin-bottom: 30px;
    }
    .tips{
        color:#999999;
        font-size:14px;
    }
    .phone{
        color:#333333;
        font-size:14px;
        margin-top:25px;
    }
}
</style>