<template >
 <div v-loading="loading">
     <ul >
         <li class="ques_list" v-for="(item,index) in quesList" :key="index">
             <el-card  style="cursor: pointer;" >
                 <div @click="toDetails(item)">
                     <p>{{item.title}}</p>
                 </div>
                 
             </el-card>
         </li>
     </ul>
 </div>
</template>

<script>
import * as http from '@/assets/api/http.js'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import QuestionLayout from '@/components/questionLayoutRecord/questionLayoutRecord.vue'
 export default {
  name: '',
  data () {
   return {
       quesList:[],
       loading:true
   }
  },
  methods:{
      statuteList(){
        let params = {
            search:this.$route.query.keyword
        }
      http.statuteList(params).then(value=>{
            if(value.code == 200){
                this.quesList = value.data;
                this.loading = false;
            }
        })
    },
    toDetails(item){
        console.log(item,'ii')
        this.$router.push({
            path:'/lawsDetails',
              query:{
                  data:JSON.stringify(item)
              }
        })
    }
  },
  created(){
      this.statuteList()
  },
  components: {

  },
//   watch:{
//     "$route":{
//         handler(value){
//         console.log(value,'aaa');
//         this.statuteList()
//       }
//     }
//   }
 }
</script>

<style scoped lang="scss">

 .ques_list{
     background:#fafafa;
     margin-bottom: 10px;
 }
</style>
