<template>
 <div class="my_container">
        <p class="my_page_tit">
          <router-link to="/index">首页</router-link> > 法学 >第一章
      </p>
    <QuestionLayout>
        <template v-slot:content>
            <div class="diffcult">
               难度：
               <img v-for="(item,index) in 4" :key="index" src="../../assets/image/question/shoucang.png" alt="">
               <img v-for="(item,index) in 1" :key="index" src="../../assets/image/question/no_shoucang.png" alt="">
            </div>
            <div class='statistics'>
                统计：全部考生答17566次，对8858次，正确率50.4%，本人作答1次，对0次，正确率0%
            </div>
            <div class="explain">
                <div class="current_answer">
                    [ 正确答案 ]&nbsp;&nbsp;A
                </div>
                <div class="deacidizing">
                    [ 考点还原 ]
                </div>
                <div class="testing_ceter">
                    （2018考试分析P120）民事法律事实的分类  （2017考试分析P287）民事法律事实的分类  
                </div>
                <div class="deacidizing">
                    [ 考点还原 ]
                </div>
                <div class="testing_ceter" >
                    的意志有关。本题主要考查民事法律事实。根据客观事实是否与主体的意志有关。本题主要考查民事法律事实。根据客观事实是否与主体的意志有关。本题主要考查民事法律事实。根据客观事实是否与主体的意志有关。本题主要考查民事法律事实。根据客观事实是否与主体的意志有关。本题主要考查民事法律事实。根据客观事实是否与主体的意志有关。本题主要考查民事法律事实。根据客观事实是否与主体的意志有关。本题主要考查民事法律事实。根据客观事实是否与主体的意志有关。本题主要考查民事法律事实。根据客观事实是否与主体的意志有关。本题主要考查民事法律事实。根据客观事实是否与主体的意志有关。
                </div>
            </div>
        </template>
    </QuestionLayout>
 </div>
</template>

<script>
import QuestionLayout from '@/components/questionLayout/questionLayout.vue'
 export default {
  name: '',
  data () {
   return {

   }
  },
  methods:{
      
  },
  components: {
      QuestionLayout
  }
 }
</script>

<style scoped lang="scss">
.my_container{
        width:1200px;
        margin:0 auto;
        margin-bottom: 80px;
        .my_page_tit{
            padding:30px 0;
            color:#666;
            font-size:12px;
            padding-bottom: 0;
        }
}
.diffcult{
    line-height:15px;
    margin-top:30px;
    img{
        width:15px;
        height:15px;
        margin-right:5px;
    }
}
.statistics{
    margin-top:20px;
    color:#8699A6;
    font-size:14px;
}
.explain{
    background:rgb(248,248,251);
    padding:20px;
    padding-bottom:25px;
    font-size:14px;
    margin-top:20px;
    margin-bottom: 30px;
    .current_answer{
        color:#2D81E4
    }
    .deacidizing{
        color:#EE2B0A;
        margin-top:20px;
    }
    .testing_ceter{
        color:#8699A6;
        margin-top:20px;
    }
}
</style>
