<template>
  <ul class='class_list'>
          <li class="class_list_items" v-for="(item,index) in dataList" :key="index" @click="toPath(item)">
              <img class="class_img" :src="item.cover | fixImg" alt="">
              <div class="class_details">
                  <p class='class_name'>
                      {{item.name}}
                  </p>
                  <p class="class_teacher">
                      {{item.teacher_name}}
                  </p>
                  <div class="class_info">
                      <div class="class_number">
                          <img src="../../assets/image/my/classIcon.png" alt="">
                          <span>{{item.look_count}}</span>
                      </div>
                      <div class="class_price" v-if="item.is_free != 1">
                          ￥{{item.price}}
                      </div>
                      <div class="class_price" v-if="item.is_free == 1">
                          免费
                      </div>
                  </div>
              </div>
          </li>
          <li  class="class_list_items" v-for="(item,index) in 3" :key="`${index}a2`" style="background:transparent;height:0;margin-bottom:0"></li>
      </ul>
</template>

<script>
export default {
  data() {
    return {

    };
  },
  props:{
      dataList:{
          default(){
              return []
          }
      }
  },
  methods: {
      toPath(item){
          this.$router.push({
              path:'/curriculumDetails',
              query:{
                  course_id:item.id
              }
          })
      }
  },
//   created() {

//   },
//   mounted() {

//   },
  components: {},
}
</script>

<style lang="scss" scoped>
.class_list{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .class_list_items{
            width:280px;
            border-radius:8px;
            background: #fff;    
            
            margin-bottom: 20px;
            cursor: pointer;
            border-radius:20px 20px 8px 8px;
            &:hover{
                box-shadow: 0px 4px 16px 0px rgba(191, 191, 191, 0.79);
            }
            .class_img{
                width:280px;
                height:160px;
                border-radius: 20px 20px 0 0 ;
            }
            .class_details{
                padding:20px;
                padding-bottom: 25px;
                .class_name{
                    color:#333333;
                    font-size:18px;
                    margin-bottom:10px;
                    overflow: hidden;
                    text-overflow:ellipsis;
                    white-space: nowrap;
                }
                .class_teacher{
                    color:#D1A366;
                    font-size:14px;
                    margin-bottom: 14px;
                }
                .class_info{
                    display:flex;
                    justify-content: space-between;
                    .class_price{
                        font-family: 'PingFang-SC-Bold';
                        color:#DC091D;
                        font-size:20px;
                        font-weight: bold;
                    }
                    .class_number{
                        display:flex;
                        align-items: center;
                        color:#666666;
                        font-size:14px;
                        img{
                            width:15px;
                            height:17px;
                            margin-right:4px;
                        }
                    }
                }
            }
        }
    }
</style>