<template>
    <div style="text-align:center">
          <el-pagination
            background
            prev-text="上一页"
            next-text='下一页'
            class="pagition"
            layout="prev, pager, next"
            @current-change="currentChange"
            :page-size="pageSize"
            :total="count">
            </el-pagination>
      </div>
</template>
<script>
export default {
    props:{
        count:{
            default(){
                return 0;
            }
        },
        pageSize:{
            default(){
                return 10
            }
        }
    },
    methods:{
        currentChange(val){
            this.$emit('currentChange',val)
        }
    }
}
</script>
<style lang="scss" scoped>
    .pagition{
    /deep/ .active{
        background:red !important;
    }
    /deep/ .el-pager li{
        font-family: 'MicrosoftYaHei';
        padding:0;
        font-size:16px;
        line-height:38px;
        height: 38px;
        width: 38px;
        text-align: center;
        font-weight: normal;
        color:#333;
    }
    /deep/.btn-prev{
        font-family: 'MicrosoftYaHei';
        padding:0;
        font-size:16px !important;
        line-height:38px !important;
        height: 38px;
        width: 74px;
        text-align: center;
        font-weight: normal;
        color:#333;
        span{
            font-size:16px !important;
            line-height:38px !important;
        }
    }
    /deep/.btn-next{
        font-family: 'MicrosoftYaHei';
        padding:0;
        font-size:16px !important;
        line-height:38px !important;
        height: 38px;
        width: 74px;
        text-align: center;
        font-weight: normal;
        color:#333;
        span{
            font-size:16px !important;
            line-height:38px !important;
        }
    }
}
</style>