<template>
 <div class="wrong_question">
     <div class="wrong_number">
         <!-- 总错题数：<span style="color:#E1410F">340</span> -->
     </div>
     <el-table
        :data="tableData"
        :row-class-name="tableRowClassName"
        ref="table"
        @row-click="rowClick"
        :header-cell-style="{background: '#DCDCDC',color:'#333',fontSize:'16px',opacity:0.8,height:'60px'}"
        style="width: 100%">
        <el-table-column
            type="expand">
            <template slot-scope="props">
                <ul>
                    <li class="menu_list" v-for="(item,index) in props.row.menu_list" :key="index" @click="toWrong(props.row,item)">
                        <span>{{item.menu_name}}</span>
                        <span>{{item.total}}</span>
                    </li>
                </ul>
            </template>
        </el-table-column>
        <el-table-column
            prop="subject_name"
            label="学科">
        </el-table-column>
        <el-table-column
            prop="total"
            label="收藏数"
            width="180">
        </el-table-column>
    </el-table>
 </div>
</template>

<script>
import * as http from '@/assets/api/http.js'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
 export default {
  name: '',
  data () {
   return {
       tableData: [],
   }
  },
  created(){
      this.exercisesCollectsList()
  },
  methods:{
      rowClick(row){
          console.log(row)
        this.tableData.forEach(v=>{
            if(v.subject_name != row.subject_name){
                this.$refs.table.toggleRowExpansion(v,false)
            }else{
                this.$refs.table.toggleRowExpansion(row)
            }
        })
      },
      tableRowClassName(){
          return 'tableRow'
      },
    //   rowClick(row, column){
    //       console.log(row, column)
    //       this.$router.push({
    //           path:'/wrongAnswer'
    //       })
    //   },
    //   错题列表
     exercisesCollectsList(){
        let params = {
            cate_id:this.cate_id
        }
      http.exercisesCollectsList(params).then(value=>{
            if(value.code == 200){
                this.tableData = value.data;
            }
        })
    },
    // 查看错题
    toWrong(item1,item2){
        this.$router.push({
              path:'/collectDetails',
              query:{
                  subject_id:item1.id,
                  menu_id:item2.menu_id
              }
          })
    }
  },
  components: {

  },
  computed:{
    ...mapState(['cate_id'])
  },
 }
</script>

<style scoped lang="scss">
.wrong_question{
    .wrong_number{
        color:#666666;
        font-size:18px;
    }
    /deep/.el-table .tableRow{
        height:74px;
    }
     /deep/.el-table .el-table__expanded-cell{
        padding:0
    }
}
 .menu_list{
     background:#f8f8fb;
     font-size: 14px;
    color: #909399;
    line-height:50px;
    border-bottom: 1px solid #f8f8fb;
    padding-left:30px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding-right:160px;
 }
</style>
