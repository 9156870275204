<template>
  <div class="my_container">
      <p class="my_page_tit">
          <router-link to="/index">首页</router-link> ><router-link to="/my/myOrder">个人中心</router-link>   > {{goodDetails.name}}
      </p>
      <div class="order_details">
          <div class="order_title">
              <div class="order_num">
                  订单编号：{{details.order_sn}}
              </div>
              <div>
                  {{timestampToTime(details.created_at*1000)}}
              </div>
          </div>
          <div class="goods_details">
              <img :src="goodDetails.cover | fixImg " alt="">
              <div class="goods_content">
                  <div class="goods_name">
                      {{goodDetails.name}}
                  </div>
                  <div class="goods_stro" v-html="goodDetails.content">
                  </div>
                  <div class="goods_price">
                      <span class="now_price">￥{{goodDetails.price}}</span>
                      <span style="text-decoration:line-through">
                          <!-- {{goodDetails.price}} -->
                      </span>
                      
                  </div>
              </div>
          </div>  
      </div>
      <!-- 地址列表 -->
      <!-- <div class="address_container">
        <p class="address_title">收货人信息</p>
        <ul class="address_list">
            <li class="address_list_item" v-for="item in 1">
                <div class="address_name">
                    <span>张三</span>
                    <span class="edit">编辑</span>
                </div>
                <div class="address_phone">
                    18181818181
                </div>
                <div class="address">
                    北京  北京市  朝阳区
        博雅国际B1727   
                </div>
            </li>
            <li  class="address_list_item add_address">
                <span>sd</span>
                <span style="margin-top:17px">添加新收货地址</span>
            </li>
            <li  class="address_list_item add_address" style="background:transparent;height:0;margin-bottom:0" v-for="item in 3">
            </li>
        </ul>
      </div> -->
      <!-- 支付方式 -->
      <div class="pay_type">
          <p class="pay_title">支付方式</p>
          <div class="pay_list">
              
              <div :class="[{'active':pay_index == index}]" v-for="(item,index) in pay_list" :key="index" @click="select_Pay(index)">
                  <div  class="pay_list_item">
                      <img :src="item.img" alt="">
                      {{item.name}}
                  </div>
                  
              </div>
              <div class="pay_list_item" v-if="pay_index == 3">
                  <input type="text" placeholder="输入支付码" v-model="code">
              </div>
          </div>

          <div class="order_money_container">
              <div class="order_money_details" >
                  <div class="price_info">
                      商品总额: ¥{{details.price}}
                  </div>
              </div>
              <!-- <div class="order_money_details" >
                  <div class="price_info">
                      优惠金额: ¥262.00
                  </div>
              </div> -->
              <div class="order_money_details" >
                  <div class="price_info">
                      应付总额: <span class="pay_money">¥{{details.single_price}}</span> 
                  </div>
              </div>
              <div class="order_money_details" >
                  <div class="pay_btn" @click="pay">
                        去支付
                    </div>
                <!-- <el-popover
                    placement="bottom"
                    width="200"
                     
                    trigger="click">
                    <img class="weChatImg" :src="weChatImg | fixImg" alt="">
                    <div slot="reference" class="pay_btn" @click="pay" v-if="">
                        去支付
                    </div>
                 </el-popover> -->
              </div>
              
          </div>
      </div>
      <el-dialog
        title="请扫码支付"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose">
        <div style="text-align:center">
            <img :src="weChatImg | fixImg" alt="" style="width:200px;height:200px;margin:0 auto;">
            <p>请扫描二维码付款</p>
        </div>
        <!-- <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
        </span> -->
      </el-dialog>
  </div>
</template>

<script>
import * as http from '@/assets/api/http.js'
import zfb from '@/assets/image/mall/zfb.png'
import weixin from '@/assets/image/mall/weixin.png'
import mima from '@/assets/image/mall/mima.png'
import yue from '@/assets/image/mall/yue.png'
let timer; ///轮训定时器
export default {
  data() {
    return {
        selectMenu:null,
        details:{},
        code:'',
        pay_index:'',
        weChatImg:'',
        goodDetails:{},
        dialogVisible:false,
        pay_list:[
            {
                name:'支付宝',
                img:zfb
            },
            {
                name:'微信支付',
                img:weixin
            },
            {
                name:'余额支付',
                img:yue
            },
            {
                name:'支付码支付',
                img:mima
            },
        ]
    };
  },
  methods: {
      handleClose(){
          this.dialogVisible= false;
          setTimeout(()=>{
              clearInterval(timer)
          },2000)
      },
      select_Pay(index){
          this.pay_index = index;
      },
      pay(){
          if(!this.pay_index && this.pay_index != 0){
              this.$message.error('请选择支付方式')
              return
          }
          if(this.pay_index == 0){
              this.alipayPc()
          }
          if(this.pay_index == 1){
                this.wechatpayPc()
          }else if (this.pay_index == 2){
                this.balancePay()
          }else if (this.pay_index == 3){
                this.codePay()
          }
      },
      //   支付宝支付创建链接
    createLink(link){
        const div = document.createElement('div') 
        div.innerHTML = link;
        document.body.appendChild(div)
        document.forms[0].submit() //重要，这个才是点击跳页面的核心
    },
    // 支付宝支付
    alipayPc(){
        let that = this;
        let formdata = new FormData();
        
        formdata.append('uid',that.$store.state.uid);
        formdata.append('token',that.$store.state.token);
        formdata.append('order_id',that.details.id);
        http.alipayPc(formdata).then(value=>{
                if(value.code == 200){
                   this.createLink(value.data)
                }else{
                this.$message.error(value.msg)
                }
            })
    },
    //   生成订单
    addOrder(){
        let that = this;
        let formdata = new FormData();
        //   分类
        formdata.append('uid',that.$store.state.uid);
        formdata.append('token',that.$store.state.token);
        formdata.append('goods_id',that.$route.query.goods_id);
        formdata.append('price',that.$route.query.price);
        formdata.append('single_price',that.$route.query.price);
        formdata.append('goods_type',1);
        formdata.append('type',2);
        http.addOrder(formdata).then(value=>{
                if(value.code == 200){
                   this.$message.success('操作成功')
                   this.details = value.data;
                }else{
                this.$message.error(value.msg)
                }
            })
    },
    // 支付码支付
    codePay(){
        let that = this;
        let formdata = new FormData();
        //   分类
        formdata.append('uid',that.$store.state.uid);
        formdata.append('token',that.$store.state.token);
        formdata.append('order_id',that.details.id);
        formdata.append('code',that.code);
        formdata.append('type',2);
        http.codePay(formdata).then(value=>{
                if(value.code == 200){
                   this.$message.success(value.msg)
                   this.$router.push({
                       path:'/my/myOrder'
                   })
                }else{
                this.$message.error(value.msg)
                }
            })
    },
    // 余额支付
    balancePay(){
        let that = this;
        let formdata = new FormData();
        //   分类
        formdata.append('uid',that.$store.state.uid);
        formdata.append('token',that.$store.state.token);
        formdata.append('order_id',that.details.id);
        formdata.append('type',2);
        http.balancePay(formdata).then(value=>{
                if(value.code == 200){
                   this.$message.success(value.msg)
                   this.$router.push({
                       path:'/my/myOrder'
                   })
                }else{
                this.$message.error(value.msg)
                }
            })
    },
    // 微信支付
    wechatpayPc(){
        let that = this;
        let formdata = new FormData();
        //   分类
        formdata.append('uid',that.$store.state.uid);
        formdata.append('token',that.$store.state.token);
        formdata.append('order_id',that.details.id);
        http.wechatpayPc(formdata).then(value=>{
                if(value.code == 200){
                   this.weChatImg = value.data;
                   this.dialogVisible = true;
                   timer = setInterval(()=>{
                            this.getWechatpayResult()
                    },300)
                }else{
                this.$message.error(value.msg)
                }
            })
    },
    // 轮训查看支付状态
    getWechatpayResult(){
        let that = this;
        let formdata = new FormData();
        //   分类
        formdata.append('order_sn',that.details.order_sn);
        http.getWechatpayResult(formdata).then(value=>{
                if(value.code == 200){
                  if(value.data.trade_state == 'SUCCESS'){
                        this.$router.push({
                            path:'/my/myWallet'
                        })
                    }
                }else{
                this.$message.error(value.msg)
                }
            })
    },
  },
  created() {
      console.log(this.$route.query.goodsInfo,'this.details')
      if(this.$route.query.from == 'orderList'){
          this.goodDetails = JSON.parse(decodeURIComponent(this.$route.query.goodsInfo));
          this.details = JSON.parse(decodeURIComponent(this.$route.query.goodsInfo));
      }else{
          this.addOrder();
          this.goodDetails = JSON.parse(decodeURIComponent(this.$route.query.goodsInfo));
      }
      
      
  },
   destroyed(){
     clearInterval(timer)
  },
//   mounted() {

//   },
  components: {},
  watch:{
  }
}
</script>

<style lang="scss" scoped>
    .my_container{
        width:1200px;
        margin:0 auto;
        margin-bottom: 80px;
        .my_page_tit{
            padding:30px 0;
            color:#666;
            font-size:12px;
        }
        .order_details{
            background: #fff;
            padding:0 26px;
            .order_title{
                line-height:76px;
                display: flex;
                border-bottom:1px solid #E2E2E2;
                .order_num{
                    color:#333;
                    font-size:18px;
                    margin-right:40px;
                }
            }
            .goods_details{
                padding-top:26px;
                padding-bottom: 40px;
                display:flex;
                img{
                    width:245px;
                    height:184px;
                    margin-right:30px;
                }
                .goods_content{
                    display:flex;
                    flex-direction: column;
                    .goods_name{
                        color:#222222;
                        font-size:20px;
                        margin-bottom: 20xp;
                    }
                    .goods_stro{
                        color:#666666;
                        font-size:14px;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 3;
                        overflow: hidden;
                    }
                    .goods_price{
                        margin-top:auto;
                        color:#999999;
                        font-size:16px;
                        .now_price{
                            color:#CF0A00;
                            font-size:24px;
                            margin-right:12px;
                        }
                    }
                }
            }
        }
        .address_container{
            padding:35px 44px 40px 26px;
            margin-top: 20px;
            background:#fff;
            .address_title{
                color:#333;
                font-size:16px;
                margin-bottom: 30px;
            }
            .address_list{
                display:flex;
                justify-content: space-between;
                flex-wrap: wrap;
                .address_list_item{
                    width:260px;
                    height:160px;
                    background:#F1F1F1;
                    padding:24px;
                    border-radius: 5px;
                    color:#666;
                    font-size:14px;
                    box-sizing: border-box;
                    margin-bottom: 20px;
                    .address_name{
                        display:flex;
                        justify-content: space-between;
                    }
                    .address_phone{
                        margin-bottom:13px;
                    }
                    .address{
                        margin-top:20px;
                    }
                }
                .add_address{
                    display:flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }
    .pay_type{
        margin-top:20px;
        background:#fff;
        padding:35px 44px 40px 26px;
        .pay_title{
            color:#333;
            font-size:16px;
        }
        .pay_list{
            margin-top:34px;
            display:flex;
            .active{
                .pay_list_item{
                    border: 1px solid #E1410F;
                }
            }
            .pay_list_item{
                margin-right:20px;
                padding:0 15px;
                border: 1px solid #A4A4A4;
                border-radius: 5px;
                cursor: pointer;
                font-size:14px;
                color:#666666;
                display:flex;
                height:42px;
                box-sizing: border-box;
                align-items: center;
                img{
                    width:25px;
                    height:25px;
                    margin-right:8px;
                }
                input{
                    border:none;
                    font-size:14px;
                    color:#666666;
                    padding:0;
                }
            }
        }
        .order_money_container{
            margin-top:64px;
            color:#333;
            font-size:16px;
            .order_money_details{
                margin-bottom: 15px;
                display:flex;
                justify-content: flex-end;
                align-items: center;
                .price_info{
                    width:182px;
                }
                .pay_money{
                    color:#CF0A00;
                    font-size:24px;
                }
            }
            .pay_btn{
                width: 182px;
                height: 50px;
                background: #CF0A00;
                border-radius: 5px;
                color:#fff;
                line-height: 50px;
                font-size: 18px;
                text-align: center;
                cursor: pointer;
            }
        }
        .weChatImg{
            width:200px;
            height:200px;
            margin:0 auto;
        }
    }
</style>