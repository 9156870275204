<template>
  <div class="my_order">
      <h3 class="class_title">地址管理</h3>
        <addRess></addRess>
  </div>
</template>

<script>
import addRess from '@/components/addRess/address.vue'
export default {
  data() {
    return {

    };
  },
  methods: {

  },
  created() {

  },
//   mounted() {

//   },
  components: {
      addRess
  },
}
</script>

<style lang="scss" scoped>
.my_order{
    padding:40px 30px;
    .class_title{
        color:#666666;
        font-size:24px;
        margin-bottom: 30px;
    }
    .address_list{
        display:flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .address_list_item{
            width:260px;
            height: 160px;
            margin-bottom: 20px;
            border-radius: 5px;
            background: #F1F1F1;
            padding:25px;
            position: relative;
            box-sizing: border-box;
            font-size:14px;
            color:#666;
            cursor: pointer;
            border:1px solid transparent;
            &:hover{
                background: rgb(255,250,248);
                border:1px solid #E1410F;
            }
            .address_title{
                display:flex;
                color:#333;
                font-size:16px;
                justify-content: space-between;
                .edit_address{
                    color:#E1410F;
                    font-size: 14px ;
                }
            }
            .address_phone{
                margin-top:10px;
            }
            .address{
                margin-top:20px;
                line-height:1.5;
                width:100%
            }
            .moren_address{
                position: absolute;
                right:0;
                bottom: 0;
                background:#E1410F;
                color:#fff;
                text-align: center;
                line-height:28px;
                width:84px;
            }
        }
        .address_list_item2{
            cursor:auto;
            &:hover{
                background: rgb(255,250,248);
                border:none;
            }
        }
        .address_list_add{
            display:flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            
            img{
                width:40px;
                height:40px;
                margin-bottom:17px;
            }
        }
    }
    /deep/.el-dialog{
        border-radius:20px;
    }
    .add_form{
        margin-bottom: 24px;
        .addinput{
            width: 470px;
        }
        
    }
    .btn_box{
        display:flex;
        justify-content: center;
        font-size:16px;
        color:#E1410F;
        span{
           border: 1px solid #E1410F;
           border-radius: 5px;
           width:148px;
           line-height: 44px;
           text-align: center;
           cursor: pointer;
        }
        .save_address{
            color:#fff;
            background-color: #E1410F;
            margin-right: 20px;
        }
    }
}
</style>