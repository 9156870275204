<template>
 <div class="my_container">
        <p class="my_page_tit">
          <router-link to="/index">首页</router-link> > <router-link to="/questionBank">题库</router-link> > 随机组题
      </p>
    <QuestionLayout
     ref="QuestionLayout"
        :questionNumber="questionNumber"
        :hasSubmit="true"
        :islook="islook"
        @submit="changeMdoel"
        model="pratice"
    >
        <template v-slot:title>
            <div class="titCol" >
                    <span>随机组题</span>   
            </div>
        </template>
    </QuestionLayout>
 </div>
</template>

<script>
import * as http from '@/assets/api/http.js'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import QuestionLayout from '@/components/questionLayoutPratice/questionLayoutPratice.vue'
 export default {
  name: '',
  data () {
   return {
       questionNumber:[],
       islook:false,
   }
  },
  created(){
      this.randomList()
      
  },
  computed:{
    ...mapState(['cate_id'])
  },
  methods:{
    //   随机题组
    randomList(){
        let params = {
            subject_id:this.$route.query.subject_id,
            type:this.$route.query.type,
            number:this.$route.query.number,
            pattern:this.$route.query.pattern
        }
      http.randomList(params).then(value=>{
            if(value.code == 200){
                this.questionNumber = value.data;
                this.questionNumber.forEach(v=>{
                   
                    if(v.type != 3){
                         this.$set(v,'answerSelect',[])
                         this.$set(v,'correct_answer_arr',this.answer_change(v.correct_answer))
                    }else{
                        this.$set(v,'answerSelect','')
                        this.$set(v,'correct_answer_arr',v.correct_answer)
                    }
                    this.$set(v,'answerError',[])
                    this.$set(v,'islook',false)
                    
                })
            }
        })
    },
    // A->1,B->2....
    answer_change(value){
        let arr =   value.split(',');
        arr.forEach((v,index)=>{
            if(v == 'A'){
                arr.splice(index,1,1)
            }
            if(v == 'B'){
                arr.splice(index,1,2)
            }
            if(v == 'C'){
                arr.splice(index,1,3)
            }
            if(v == 'D'){
                arr.splice(index,1,4)
            }
        })
        arr.sort()
        return arr
    },
    // 考试完毕提交答案
    changeMdoel(){
        this.islook = true;
        // 对考试提交的答案添加回答正确与否的判断
        this.$refs.QuestionLayout.questionBanlList.forEach(v=>{
            v.answerSelect.sort();   //对选项进行排序
          if(v.correct_answer_arr.join(',') == v.answerSelect.join(',')){
              this.$set(v,'is_ans_current',true)
          }else{
              this.$set(v,'is_ans_current',false)
          }
        })
    },
  },
  destroyed(){
  },
  components: {
      QuestionLayout
  }
 }
</script>

<style scoped lang="scss">
.my_container{
        width:1200px;
        margin:0 auto;
        margin-bottom: 80px;
        .my_page_tit{
            padding:30px 0;
            color:#666;
            font-size:12px;
            padding-bottom: 0;
        }
}
.titCol{
    display:flex;
    justify-content: space-between;
    align-items: center;
}
.subAnswer{
                width: 80px;
                height: 30px;
                background: #DC091D;
                border-radius: 8px;
                color:#fff;
                font-size:14px;
                text-align: center;
                line-height: 30px;
                margin:0 20px;
                cursor: pointer;
                // opacity: 0.1;
            }
.tips_title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img{
        width:80px;
        height: 80px;
        margin-bottom: 20px;
    }
}
.tips_content{
    margin-top:15px;
    font-size:16px;
    color:#5F6E78;
    text-align: center;
    line-height:1.5
}
.tips_btn{
    width:162px;
    height:52px;
    line-height:52px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 16px;
    margin-right:10px;
    font-size:16px;
    cursor: pointer;
}
.tips_btn_submit{
    border: 1px solid #DC091D;
    color:#fff;
    background:#DC091D;
}
.dialog-footer{
    display:flex;
    justify-content: center;
    margin-top:35px;
}
/deep/ .el-dialog__header{
    display:none;
}
.submitpaper{
    background: #F3F8FE;
    border-radius: 8px;
    width: 45px;
    height: 24px;
    line-height:24px;
    color:#2D81E4;
    font-size:14px;
    text-align: center;
    margin-right:20px;
    cursor: pointer;
}
</style>
