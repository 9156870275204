<template>
  <div class="my_order">
    <el-select size="mini" style="margin-bottom:20px;" v-model="cate_id" placeholder="请选择" @change="changeCate">
            <el-option
            v-for="item in questionList"
            :key="item.id"
            :label="item.name"
            :value="item.id">
            </el-option>
        </el-select>
      <el-table
      max-height="500px"
            :data="tableData"
            :header-cell-style="{background: '#DCDCDC',color:'#333',fontSize:'16px',opacity:0.8}"
            style="width: 100%">
            <el-table-column
                    prop="code"
                    label="试卷名称"
                    width="300"
                    >
                    <template slot-scope="scope">
                        <span style="line-height:40px;">{{scope.row.exam_name}}</span>
                        <div v-if="scope.row.is_inner == 1" style="padding-bottom:10px;">
                          <span style="    background: red;
    color: #fff;
    padding: 5px;
    border-radius: 10px;">内部卷</span>
                        </div>
                        <div v-if="scope.row.is_inner != 1" style="padding-bottom:10px;">
                          <div style=" 
    color: #fff;
    height: 10px;
    border-radius: 10px;"></div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="code"
                    label="分数"
                    >
                    <template slot-scope="scope">
                        <span style="line-height:40px;">{{scope.row.point}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="content"
                    label="交卷时间"
                    >
                     <template slot-scope="scope">
                        <span class="message_content"  style="line-height:40px;">{{timestampToTime(scope.row.created_at*1000)}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="content"
                    label="操作"
                    
                    >
                     <template slot-scope="scope">
                       <el-button size="mini" type="text" @click="toDetails(scope.row)">查看详情</el-button>
                       <el-button size="mini" type="text" @click="delExamLog(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
  </div>
</template>

<script>
import * as http from '@/assets/api/http.js'
export default {
  data() {
    return {
        tableData: [],
        questionList:[
            {
                id:9,
                name:'法学'
            },
            {
                id:11,
                name:'非法学'
            }
        ],
        cate_id:9
    };
  },
  methods: {
    //   获取列表
    simulationUserList(){
      let that = this;
      let formdata = new FormData();
      formdata.append('uid',that.$store.state.uid);
      formdata.append('token',that.$store.state.token);
      formdata.append('cate_id',that.cate_id);
      http.simulationUserList(formdata).then(value=>{
            if(value.code == 200){
              this.tableData = value.data;
            }else{
              this.$message.error(value.msg)
            }
        })
    },
    // 删除
    delExamLog(log_id){
      this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let that = this;
          let formdata = new FormData();
          formdata.append('uid',that.$store.state.uid);
          formdata.append('token',that.$store.state.token);
          formdata.append('log_id',log_id);
          http.delExamLog(formdata).then(value=>{
                if(value.code == 200){
                  this.simulationUserList()
                  this.$message({
                type: 'success',
                message: '删除成功!'
              });
                }else{
                  this.$message.error(value.msg)
                }
            })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
      
    },
    changeCate(e){
          this.simulationUserList()
      },
      toDetails(content){
        this.$router.push({
          path:'/textDetails',
          query:{
            content:JSON.stringify(content.content)
          }
        })
      }
  },
  created() {
      this.simulationUserList()
  },
//   mounted() {

//   },
  components: {},
}
</script>

<style lang="scss" scoped>
.my_order{
    padding:40px 30px;
    /deep/ .el-table td, .el-table th{
        padding:0
    }
    .message_content{
        color:#333333;
        line-height:1;
        font-size:14px;
        overflow : hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}
</style>