<template>
  <div v-loading="loading">
      <div class="tabs_list">
          <span :class="['tabs_list_item',{'active':tabIndex == index}]" v-for="(item,index) in tabList" :key="index" @click="changeTag(index)">
              {{item.name || item.subject_name}}
          </span>
      </div>
      <div class="person_statistics">
          个人统计
      </div>
      <div class="statistics_con">
          <span class="statistics_con_label">
              本人作答
          </span>
          <span class="statistics_con_value" style="color:#CF0A00">
              {{curInfo.user_all_total}}
          </span>
      </div>
      <div class="statistics_con">
          <span class="statistics_con_label">
              答对             
          </span>
          <span class="statistics_con_value" style="color:#2D81E4">
              {{curInfo.user_correct_total}}
          </span>
      </div>
      <div class="statistics_con">
          <span class="statistics_con_label">
              正确率             
          </span>
          <span class="statistics_con_value" style="color:#2D81E4">
              <el-progress type="circle" :percentage="((curInfo.user_correct_total/curInfo.user_all_total)*100).toFixed(1)"></el-progress>
          </span>
      </div>
      <div class="person_statistics">
          题库统计
      </div>
      <div class="person_statistics_tips">
          本题库所有学员作答{{curInfo.all_total}}次，对{{curInfo.correct_total}}次，正确率{{((curInfo.correct_total/curInfo.all_total)*100).toFixed(1)}}%
      </div>
      <div class="person_statistics_tips">
         您与题库中所有学员的正确率对比差值为：{{(((curInfo.user_correct_total/curInfo.user_all_total)*100).toFixed(1)-((curInfo.correct_total/curInfo.all_total)*100).toFixed(1)).toFixed(1)}}%
      </div>
      <div class="person_statistics">
          统计说明
      </div>
      <div class="person_statistics_tips">
          “作答”次数，“对”次数为累加所得
      </div>
      <div class="person_statistics_tips">
          “正确率”为“正确题目总次数÷作答题目总次数”计算所得
      </div>
  </div>
</template>

<script>
import * as http from '@/assets/api/http.js'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
        tabList:[],
        tabIndex:0,
        curInfo:{},
        loading:true
    };
  },
  computed:{
    ...mapState(['cate_id'])
  },
  methods: {
    //   统计
    exercisesStatistics(){
        let params = {
            cate_id:this.cate_id
        }
     return http.exercisesStatistics(params).then(value=>{
            if(value.code == 200){
                this.tabList = value.data;
                this.curInfo = this.tabList[0]
                this.loading = false
            }
        })
    },
    changeTag(index){
        this.tabIndex = index;
        this.curInfo = this.tabList[index]
    }
  },
  created() {
      this.exercisesStatistics()
  },
  components: {},
}
</script>

<style lang="scss" scoped>
.tabs_list{
    display:flex;
    flex-wrap: wrap;
    .tabs_list_item{
        padding:7px 16px;
        color:#333333;
        border-radius: 9px;
        margin-right:10px;
        cursor: pointer;
        margin-bottom: 10px;
        &.active{
            background:#E1410F;
            color:#fff;
        }
    }
}
.person_statistics{
    margin-top:30px;
    font-size:18px;
    margin-bottom:10px;
    color:#333;
}
.statistics_con{
    display:flex;
    align-items: center;
    
    margin-top:20px;
    .statistics_con_label{
        font-size:14px;
        color:#666;
        
        width:60px;
        margin-right:15px;
    }
    .statistics_con_value{
        font-size:14px;
    }
}
.person_statistics_tips{
    color:#666666;
    font-size:14px;
    margin-top: 20px;;
}
</style>