<template>
  <div>
      <!-- <div class="tabs_list">
          <span :class="['tabs_list_item',{'active':tabIndex == index}]" v-for="(item,index) in tabList" :key="index" @click="changeTag(index)">
              {{item.name || item.subject_name}}
          </span>
      </div> -->
      <el-table
      v-loading="loading"
        :data="tabList"
        :row-class-name="tableRowClassName"
        :header-cell-style="{background: '#DCDCDC',color:'#333',fontSize:'16px',opacity:0.8,height:'60px'}"
        @row-click = "rowClick"
        ref="table"
        style="width: 100%">
        <el-table-column
            type="expand">
            <template slot-scope="props">
                <ul>
                    <li class="menu_list" v-for="(item,index) in props.row.statute" :key="index" @click.stop="toDetails(item,item)">
                        {{item.title}}
                    </li>
                </ul>
            </template>
        </el-table-column>
        <el-table-column
            prop="name"
            label="学科">
        </el-table-column>
        <!-- <el-table-column
            prop="total"
            label="题目数"
            width="180">
        </el-table-column> -->
    </el-table>
  </div>
</template>

<script>
import * as http from '@/assets/api/http.js'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
        tabList:[],
        curInfo:{},
        tabIndex:0,
        loading:true
    };
  },
  methods: {
      tableRowClassName(){
          return 'tableRow'
      },
      //   统计
    statuteList(){
        let params = {
            cate_id:this.cate_id
        }
     return http.statuteList(params).then(value=>{
            if(value.code == 200){
                this.tabList = value.data;
                this.curInfo = this.tabList[0]
                this.loading = false;
            }
        })
    },
    changeTag(index){
        this.tabIndex = index;
        this.curInfo = this.tabList[index]
    },
    rowClick(row){
        this.tabList.forEach(v=>{
            if(v.name != row.name){
                this.$refs.table.toggleRowExpansion(v,false)
            }else{
                this.$refs.table.toggleRowExpansion(row)
            }
        })
    },
    toDetails(row, column){
        console.log(row,'row')
          this.$router.push({
              path:'/lawsDetails',
              query:{
                  data:JSON.stringify(row)
              }
          })
      },
  },
  created() {
      this.statuteList()
  },
  components: {},
}
</script>

<style lang="scss" scoped>
.tabs_list{
    display:flex;
    flex-wrap: wrap;
    .tabs_list_item{
        padding:7px 16px;
        color:#333333;
        border-radius: 9px;
        margin-right:10px;
        cursor: pointer;
        margin-bottom: 10px;
        &.active{
            background:#E1410F;
            color:#fff;
        }
    }
}
.menu_list{
     background:#f8f8fb;
     font-size: 14px;
    color: #909399;
    line-height:50px;
    border-bottom: 1px solid #f8f8fb;
    padding-left:30px;
    cursor: pointer;
 }
 /deep/.el-table .tableRow{
        height:74px;
    }
     /deep/.el-table .el-table__expanded-cell{
        padding:0
    }
</style>