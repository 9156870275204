<template>
<!-- 回显已有数据+做一道回显一道 -->
      <div class="answer_wrong">
          <!-- 题目编号 -->
          <div class="question_order">
              <slot  class="title_question" name="title">错题</slot >
              <div class="order_list">
                  <span :class="['order_list_item',{'active':(index == orderSelect) && !questionBanlList[orderSelect].islook}]" v-for="(item,index) in questionBanlList" :key="index" @click="changeOrder(index)">
                      {{index+1}}
                  </span>
              </div>
          </div>
          <!-- 题目内容 -->
          
          <div class="question_moudle" v-if="questionBanlList.length ">
             
              <div class="question_content">
                  {{orderSelect+1}}、{{questionBanlList[orderSelect].title}}
                  <!-- <el-button size="mini" type="primary" @click="toPath">重做</el-button> -->
                  <slot  name="operation"></slot >
              </div>
              <slot name="content"></slot>  
              <div>

                        <div class="explain">
                            <div class="testing_ceter" v-html="questionBanlList[orderSelect].content"> </div>
                        </div>
              </div>
              
                <div class="btn_list" v-if="showChange">
                        <span class="np_active" size="mini" @click="next"  > 下一题</span >
                </div>
          </div>
      </div>
</template>

<script>
import no_select from '@/assets/image/question/no_select.png'
import select_current from '@/assets/image/question/select_current.png'
import select_error from '@/assets/image/question/select_error.png'
import * as http from '@/assets/api/http.js'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
 export default {
  name: '',
  data () {
   return {
       orderSelect:0,
       answerSelect:null,     //每道题回答的集合
       answerError:null,
       curQuestion:{},
       no_select,select_current,select_error,
       showChange:true,
       textarea:''
   }
  },
  props:{
      questionNumber:{
          default(){
              return []
          }
      },
  },
  computed:{
      questionBanlList:{
          get(){
              return JSON.parse(JSON.stringify(this.questionNumber))
          },
          set(){}
      }
  },
  methods:{
      changeOrder(index){
        this.orderSelect = index;
      },
      toPath(){
          this.$router.push({
              path:'/wrongAnswer'
          })
      },
    //   下一题
      next(){
          this.orderSelect++;
      },

  },
  components: {

  }
 }
</script>

<style scoped lang="scss">
.my_container{
        width:1200px;
        margin:0 auto;
        margin-bottom: 80px;
        .my_page_tit{
            // padding:30px 0;
            color:#666;
            font-size:12px;
            padding-bottom: 0;
        }
}
 .answer_wrong{
     display:flex;
     margin-top:20px;
    .question_order{
        min-height:588px;
        padding:20px;
        padding-right:0;
        width:260px;
        box-sizing: border-box;
        background: #fff;
        font-size:18px;
        color:#333;
        .order_list{
            margin-top:18px;
            display:flex;
            flex-wrap: wrap;
            .order_list_item{
                width:49px;
                height:49px;
                border-radius: 16px;
                background:#F0F2F3;
                color:#333333;
                font-size:14px;
                text-align: center;
                line-height:49px;
                margin-bottom: 10px;
                margin-right:8px;
                cursor: pointer;
                &.active{
                    background:#DC091D;
                    color:#fff;
                }
            }
            .look_true{
                background:#66ac28;
                color:#fff;
            }
            .look_false{
                background:#DC091D;
                    color:#fff;
            }
        }
    }
    .question_moudle{
        padding:30px;
        padding-bottom:50px;
        font-size:18px;
        color:#333;
        flex:1;
        background:#fff;
        margin-left:20px;
        display: flex;
        flex-direction: column;
        .question_content{
            margin-bottom:40px;
        }
        .answer_list{
            .answer_list_item{
                display:flex;
                cursor: pointer;
                .answer_check{
                    width:28px;
                    height:28px;
                    margin-right:20px;
                }
            }
        }
        .btn_list{
            margin-top:auto;
            display:flex;
            justify-content: center;
            .previous_question{
                width: 80px;
                height: 30px;
                border-radius: 8px;
                font-size:14px;
                text-align: center;
                line-height: 30px;
                border: 1px solid #D2D2D2;
                color:#333;
                cursor: pointer;
            }
            .subAnswer{
                width: 80px;
                height: 30px;
                background: #DC091D;
                border-radius: 8px;
                color:#fff;
                font-size:14px;
                text-align: center;
                line-height: 30px;
                margin:0 20px;
                cursor: pointer;
                // opacity: 0.5;
            }
            .active{
                opacity: 1;
            }
            span{
                    width: 220px;
                    height: 44px;
                    background: #dc091d;
                    border-radius: 12px;
                    font-size: 14px;
                    font-weight: 500;
                    color: #fff;
                    text-align: center;
                    line-height: 44px;
                    &.np_active{
                        background-color: #fff;
                        border: 1px solid #8497a4;
                            color: #222f3e;
                    }
            }
        }
    }
 }
 .diffcult{
    line-height:15px;
    margin-top:30px;
    img{
        width:15px;
        height:15px;
        margin-right:5px;
    }
}
.statistics{
    margin-top:20px;
    color:#8699A6;
    font-size:14px;
}
.explain{
    background:rgb(248,248,251);
    padding:20px;
    padding-bottom:25px;
    font-size:14px;
    margin-top:20px;
    margin-bottom: 30px;
    .current_answer{
        color:#2D81E4
    }
    .deacidizing{
        color:#EE2B0A;
        margin-top:20px;
    }
    .testing_ceter{
        color:#8699A6;
        margin-top:20px;
    }
}
.answer_text{
        background: #f8f8fb;
        padding: 16px;
        .answer_area{
            width:100%;
            height:200px;
            border:none;
            outline:none;
            resize:none;
            background: #f8f8fb;
        }
}
</style>
