<template>
  <div class="my_container">
      <p class="my_page_tit">
          <router-link to="/index">首页</router-link>
           > 
           <span>个人中心</span>
      </p>
      <div class="my_infos">
          <ul class="left_menu">
              <li  :class="['left_menu_items',{'active':selectMenu == index}]" v-for="(item,index) in leftMenu" :key="index" @click="changeMenu(index,item.path)">
                  {{item.name}}
              </li>
          </ul>
          <div class="right_view">
              <router-view></router-view>
          </div>
      </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
        leftMenu:[
            {
                name:'我的订单',
                path:'/my/myOrder'
            },
            {
                name:'我的错题',
                path:'/my/myQuestionBank'
            },
            {
                name:'我的课程',
                path:'/my/myClass'
            },
            {
                name:'我的钱包',
                path:'/my/myWallet'
            },
            {
                name:'我的支付码',
                path:'/my/myZFM'
            },
            {
                name:'我的收藏',
                path:'/my/myCollect'
            },
            {
                name:'我的消息',
                path:'/my/myMessage'
            },
            {
                name:'考试记录',
                path:'/my/mytestRecord'
            },
            {
                name:'地址管理',
                path:'/my/myAddress'
            },
            {
                name:'个人资料',
                path:'/my/myData'
            },
            {
                name:'客服',
                path:'/my/customer'
            },
        ],
        selectMenu:null
    };
  },
  methods: {
      changeMenu(index,path){
          this.selectMenu = index;
          this.$router.push({
              path
          })
      },
  },
  created() {
      this.leftMenu.forEach((v,index)=>{
          if(v.path == this.$route.path){
              this.selectMenu = index
          }
      })
  },
//   mounted() {

//   },
  components: {},
  watch:{
      $route: {
        handler: function(val, oldVal){
            console.log(val);
            this.leftMenu.forEach((v,index)=>{
                if(v.path == this.$route.path){
                    this.selectMenu = index
                }
            })
        },
        // 深度观察监听
        deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
    .my_container{
        width:1200px;
        margin:0 auto;
        margin-bottom: 80px;
        .my_page_tit{
            padding:30px 0;
            color:#666;
            font-size:12px;
        }
        .my_infos{
            display:flex;
            
            .left_menu{
                height:594px;
                background:#fff;
                width:240px;
                box-sizing: border-box;
                margin-right:20px;
                .left_menu_items{
                    text-align: center;
                    color:#333333;
                    font-size:14px;
                    cursor: pointer;
                    line-height:54px;
                    border-left:2px solid transparent;
                }
                .active{
                    border-left:2px solid #E1410F;
                    background:#F5F5F5;
                }
            }
            .right_view{
                flex:1;
                background: #fff;
            }
        }
    }
</style>