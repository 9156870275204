import { render, staticRenderFns } from "./imitateTest.vue?vue&type=template&id=cff6ec60&scoped=true&"
import script from "./imitateTest.vue?vue&type=script&lang=js&"
export * from "./imitateTest.vue?vue&type=script&lang=js&"
import style0 from "./imitateTest.vue?vue&type=style&index=0&id=cff6ec60&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cff6ec60",
  null
  
)

export default component.exports