<template>
 <div class="random_container">
     <div class="random_tips">
         <span>说明</span>
         <span class="random_tips_content"> 
             历年真题反复考查  强化训练，反复记忆  精确把握命题趋势  针对练习，举一反三
         </span>
     </div>
     <!-- 题组内容选择 -->
     <div class="question_type">
         <span class="question_type_name">
             学科
         </span>
         <ul class="question_type_list">
            <li :class="['question_type_list_item',{'active':subjectIndex == index}]" v-for="(item,index) in typeList" :key="index" @click="change(index,'subject_id')">
                {{item.name}}
            </li>
         </ul>
     </div>
     <div class="question_type">
         <span class="question_type_name">
             题型
         </span>
         <ul class="question_type_list">
            <li :class="['question_type_list_item',{'active':type == index}]" v-for="(item,index) in quesTypeList" :key="index" @click="change(index,'type')">
                {{item.name}}
            </li>
         </ul>
     </div>
     <div class="question_type">
         <span class="question_type_name">
             数量
         </span>
         <ul class="question_type_list">
            <li :class="['question_type_list_item',{'active':number == index}]" v-for="(item,index) in numList" :key="index" @click="change(index,'number')">
                {{item.name}}题
            </li>
         </ul>
     </div>
     <div class="question_type">
         <span class="question_type_name">
             模式
         </span>
         <ul class="question_type_list">
            <li :class="['question_type_list_item',{'active':pattern == index}]" v-for="(item,index) in patternList" :key="index" @click="change(index,'pattern')">
                {{item.name}}
            </li>
         </ul>
     </div>
     <!-- 开始答题 -->
     <div class="next_btn">
         <span class="" @click="randomList">
             开始答题
         </span>
     </div>
     <div class="join_num">
         {{count}}人次参加
     </div>
 </div>
</template>

<script>
import * as http from '@/assets/api/http.js'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
 export default {
  name: '',
  data () {
   return {
       typeList:[],
       subjectIndex:0,
       quesTypeList:[
           {
               name:'单选',
               id:1,
           },
           {
               name:'多选',
               id:2,
           },
           {
               name:'主观',
               id:3,
           },
       ],
       numList:[
           {
               name:15,
               id:15
           },
           {
               name:35,
               id:35
           },
           {
               name:50,
               id:50
           },
       ],
       patternList:[
           {
               name:'练习',
               id:1
           },{
               name:'考试',
               id:2
           }
       ],
       type:0,
       number:0,
       pattern:0,
       count:''
   }
  },
  computed:{
    ...mapState(['cate_id'])
  },
  methods:{
      // 获取题库分类
    exercisesSubjectList(){
        let params = {
            cate_id:this.cate_id
        }
     return http.exercisesSubjectList(params).then(value=>{
            if(value.code == 200){
                this.typeList = value.data;
                Promise.resolve()
            }
        })
    },
    // 随机组题数量统计
    randomCount(){
     return http.randomCount().then(value=>{
            if(value.code == 200){
                console.log(value,'asd')
                this.count = value.data.count;
            }
        })
    },
    change(index,type){
        if(type == 'subject_id'){
            this.subjectIndex = index
        }else if(type == 'type'){
            this.type = index
        }else if(type == 'number'){
            this.number = index
        }else if(type == 'pattern'){
            this.pattern = index
        }
    },
    startTest(){
        this.$router.push({
            path:'/randomTest',
            query:{
                subject_id:this.typeList[this.subjectIndex].id,
                type:this.quesTypeList[this.type].id,
                number:this.numList[this.number].id,
                pattern:this.patternList[this.pattern].id
            }
        })
    },
    //   随机题组
    randomList(){
        let params = {
            subject_id:this.typeList[this.subjectIndex].id,
                type:this.quesTypeList[this.type].id,
                number:this.numList[this.number].id,
                pattern:this.patternList[this.pattern].id
        }
      http.randomList(params).then(value=>{
            if(value.code == 200){
                if(value.data.length>0){
                    let path;
                    if(this.patternList[this.pattern].id == 1){
                        path="/randomPratice"
                    }else{
                        path="/randomTest"
                    }
                        this.$router.push({
                            path,
                            query:{
                                subject_id:this.typeList[this.subjectIndex].id,
                                type:this.quesTypeList[this.type].id,
                                number:this.numList[this.number].id,
                                pattern:this.patternList[this.pattern].id
                            }
                        })
                }else{
                    this.$message.warning('当前题库无数据')
                }
            }
        })
    },
  },
  created(){
      this.exercisesSubjectList()
      this.randomCount()
  },
  components: {

  }
 }
</script>

<style scoped lang="scss">
.random_container{
    color:#333333;
    font-size:18px;
}
.random_tips{
    font-size:16px;
    margin-bottom: 40px;
}
 .random_tips_content{
     margin-left:18px;
     background:#FCECE7;
     padding:9px 14px;
     color:#E1410F;
     font-size:14px;
     opacity: 0.8;
    border-radius: 4px;
 }
 .question_type{
     display:flex;
     flex-wrap: wrap;
    // align-items: center;
    .question_type_name{
        padding-top:12px;
        margin-right:25px;
        margin-bottom: 30px;
    }
    .question_type_list{
        flex:1;
        display:flex;
        flex-wrap: wrap;
        .question_type_list_item{
            margin-right:10px;
            background:#F2F4F5;
            padding:12px 20px;
            font-size:14px;
            color:#666;
            border-radius: 8px;
            cursor: pointer;
            margin-bottom: 30px;
            &.active{
                color:#fff;
                background:#E1410F;
            }
        }
    }
 }
 .next_btn{
     display:flex;
     justify-content: center;
     margin-top:60px;
     span{
         width:244px;
         height:50px;
         line-height: 50px;
         color:#fff;
         font-size:16px;
         text-align: center;
         background:#CF0A00;
         border-radius: 14px;
         cursor: pointer;
     }
 }
 .join_num{
     margin-top:20px;
     text-align: center;
     color:#666666;
     font-size:14px;
 }
</style>
