<template>
 <div class="my_container">
        <p class="my_page_tit">
          <router-link to="/index">首页</router-link> > <router-link to="/questionBank">题库</router-link> > 开始答题
      </p>
    <QuestionLayout
     ref="QuestionLayout"
        :questionNumber="questionNumber"
        :hasSubmit="true"
        :islook="islook"
        model="pratice"
        v-if="show"
    >
        <template v-slot:title>
            <div class="titCol_tip" >
                    主观题分数会在判卷后显示（预计1-3个工作日）   
            </div>
        </template>
    </QuestionLayout>

 </div>
</template>

<script>
import * as http from '@/assets/api/http.js'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import QuestionLayout from '@/components/questionLayoutRecord/questionLayoutRecord.vue'
 export default {
  name: '',
  data () {
   return {
       questionNumber:[],
       islook:true,
       show:true
   }
  },
  created(){
      
      this.questionNumber = JSON.parse(this.$route.query.content);
                this.questionNumber.forEach(v=>{
                    if(v.type !=3){
                        // 记录当前正确答案
                        this.$set(v,'correct_answer_arr',this.answer_change(v.correct_answer))
                        // 记录当前回答记录
                    
                        // 对已经答题的题打上查看标记
                        if(v.user_answer_status != 0){
                            this.$set(v,'islook',true)
                            this.$set(v,'answerSelect',this.answer_change(v.user_answer))
                        }else{
                            this.$set(v,'islook',false)
                            this.$set(v,'answerSelect',[])
                        }
                    }else{
                        if(v.user_answer_status != 0){
                            this.$set(v,'islook',true)
                            this.$set(v,'answerSelect',v.user_answer)
                        }else{
                            this.$set(v,'islook',false)
                            this.$set(v,'answerSelect','')
                        }
                    }
                    
                })
      
  },
  computed:{
    ...mapState(['cate_id'])
  },
  methods:{

    
    // A->1,B->2....
    answer_change(value){
        let arr =   value.split(',');
        arr.forEach((v,index)=>{
            if(v == 'A'){
                arr.splice(index,1,1)
            }
            if(v == 'B'){
                arr.splice(index,1,2)
            }
            if(v == 'C'){
                arr.splice(index,1,3)
            }
            if(v == 'D'){
                arr.splice(index,1,4)
            }
        })
        arr.sort()
        return arr
    },
    // 1->A,2->B
    answer_change2(value){
        let arr =  value;
        arr.forEach((v,index)=>{
            if(v == '1'){
                arr.splice(index,1,'A')
            }
            if(v == '2'){
                arr.splice(index,1,'B')
            }
            if(v == '3'){
                arr.splice(index,1,'C')
            }
            if(v == '4'){
                arr.splice(index,1,'D')
            }
        })
        return value.join(',')
    },


  },
  destroyed(){
  },
  components: {
      QuestionLayout
  }
 }
</script>

<style scoped lang="scss">
.my_container{
        width:1200px;
        margin:0 auto;
        margin-bottom: 80px;
        min-height:500px;
        .my_page_tit{
            padding:30px 0;
            color:#666;
            font-size:12px;
            padding-bottom: 0;
        }
}
.titCol{
    display:flex;
    justify-content: space-between;
    align-items: center;
}
.subAnswer{
                width: 80px;
                height: 30px;
                background: #DC091D;
                border-radius: 8px;
                color:#fff;
                font-size:14px;
                text-align: center;
                line-height: 30px;
                margin:0 20px;
                cursor: pointer;
                // opacity: 0.1;
            }
.tips_title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img{
        width:80px;
        height: 80px;
        margin-bottom: 20px;
    }
}
.tips_content{
    margin-top:15px;
    font-size:16px;
    color:#5F6E78;
    text-align: center;
    line-height:1.5
}
.tips_btn{
    width:162px;
    height:52px;
    line-height:52px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 16px;
    margin-right:10px;
    font-size:16px;
    cursor: pointer;
}
.tips_btn_submit{
    border: 1px solid #DC091D;
    color:#fff;
    background:#DC091D;
}
.dialog-footer{
    display:flex;
    justify-content: center;
    margin-top:35px;
}
/deep/ .el-dialog__header{
    display:none;
}
.submitpaper{
    background: #F3F8FE;
    border-radius: 8px;
    width: 45px;
    height: 24px;
    line-height:24px;
    color:#2D81E4;
    font-size:14px;
    text-align: center;
    margin-right:20px;
    cursor: pointer;
}
.titCol_tip{
    font-size:14px;
    color:red
}
</style>
