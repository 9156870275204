<template>
 <div  class="">
     <!-- <p class="my_page_tit">
          <router-link to="/index">首页</router-link>  > 资讯详情
      </p> -->
      <div class="about_us">
          <p class='about_us_title'>{{content.title}}</p>
          <div v-html="content.content"></div>
          <newsPinglun
          v-if="pinglunShow"
            :id="content.id"
            :data="content"
        ></newsPinglun>
      </div>
 </div>
</template>

<script>
import * as http from '@/assets/api/http.js'
import newsPinglun from '@/components/newsPinglun/newsPinglun.vue'
 export default {
  name: '',
  data () {
   return {
       content:{},
       pinglunShow:false
   }
  },
  methods:{
      newsDetail(){
        let that = this;
        let formdata = new FormData();
        //   分类
        formdata.append('uid',that.$store.state.uid);
        formdata.append('token',that.$store.state.token);
        formdata.append('type',2);
        formdata.append('news_id',that.$route.query.news_id);
        http.newsDetail(formdata).then(value=>{
            if(value.code == 200){
                this.content = value.data.info;
                this.$set(this.content,'collect',value.data.collect);
                this.pinglunShow = true;
            }
        })
    },
  },
  mounted(){
      this.newsDetail()
  },
  components: {
      newsPinglun
  }
 }
</script>

<style scoped lang="scss">
.my_container{
        width:1200px;
        margin:0 auto;
        margin-bottom: 80px;
        .my_page_tit{
            padding:30px 0;
            color:#666;
            font-size:12px;
        }
        .about_us{
            min-height:600px;
            box-sizing: border-box;
            padding:40px 30px;
            padding-top:0;
            background:#fff;
            .about_us_title{
                font-size:24px;
                color:#666;
                margin-bottom:40px;
            }
        }
}
</style>
