<template>
  <div class="my_container">
      <p class="my_page_tit">
          <router-link to="/index">首页</router-link> > 名师团队
      </p>
      <teacherList
      :dataList="dataList"
      ></teacherList>
      <pagition 
        :count="count"
        @currentChange='currentChange'
        :pageSize="12"
      >
      </pagition>
  </div>
</template>

<script>
import * as http from '@/assets/api/http.js'
import teacherList from '@/components/teacherList/teacherList'
export default {
  data() {
    return {
      count:0,
      dataList:[],
      page:1
    };
  },
  methods: {
    // 分页教室列表
    teacherListPage(){
      let that = this;
      let formdata = new FormData();
      //   分类
      formdata.append('p',that.page);
      http.teacherListPage(formdata).then(value=>{
              if(value.code == 200){
                  this.dataList=value.data;
              }else{
              this.$message.error(value.msg)
              }
          })
    },
    currentChange(val){
          this.page=val;
          this.teacherListPage()
      },
  },
//   created() {

//   },
  mounted() {
    this.teacherListPage();
  },
  components: {teacherList},
}
</script>

<style scoped lang="scss">
.my_container{
        width:1200px;
        margin:0 auto;
        margin-bottom: 80px;
        .my_page_tit{
            padding:30px 0;
            color:#666;
            font-size:12px;
        }
}
</style>