<template>
  <div class="">
      <ul class="address_list">
          <li :class="['address_list_item',{'active':index == selectAddress}]" v-for="(item,index) in addList" :key="index" @click="slectAdd(item,index)">
              <div class='address_title'>
                  <span>{{item.uname}}</span>
                  <span class="edit_address" @click="edit(item)">编辑</span>
              </div>
              <div class="address_phone">{{item.phone}}</div>
              <div class="address">
                 {{item.province}}{{item.city}}{{item.district}}{{item.address}}
              </div>
              <div class='moren_address' v-if="item.is_mr == 1">
                  默认地址
              </div>
          </li>
          <li class="address_list_item address_list_add" @click="addAddress">
             <img src="../../assets/image/my/add.png" alt="">
             <span>添加新收货地址</span>
          </li>
          <li  class="address_list_item address_list_item2" style="background:none;height:0"></li>
          <li  class="address_list_item address_list_item2" style="background:none;height:0"></li>
      </ul>
      <!-- 新增地址 -->
        <el-dialog
            title="新增地址"
            :visible.sync="dialogVisible"
            :close-on-click-modal="false"
            @close="cancel"
            class="add_address"
            :destroy-on-close="true"
            width="550px">
            <ul>
                <li class='add_form'>
                    <el-input class="addinput"  v-model="uname" placeholder="收货人姓名"></el-input>
                </li>
                <li class='add_form'>
                    <el-input class="addinput" maxlength="11" onkeyup="value=value.replace(/[^\d]/g,'')" v-model="phone" placeholder="收货人手机号"></el-input>
                </li>
                <li class='add_form'>
                    <v-distpicker
                    v-if="dialogVisible"
                    @province="changeprovince"
                    @city="changecity"
                    @area="changedistrict"
                    :province="province"
                    :city="city"
                    :area="district"
                    ></v-distpicker>
                </li>
                <li class='add_form'>
                    <el-input class="addinput"  v-model="address" placeholder="详细地址"></el-input>
                </li>
                <li class='add_form'>
                    <el-checkbox v-model="checked">设为默认地址</el-checkbox>
                </li>
            </ul>
            <div class='btn_box'>
                <span class="save_address" @click="save">保存地址</span>
                <span @click="cancel" v-if="!editFlag">取消</span>
                <span @click="delUserAddress" v-if="editFlag">删除地址</span>
            </div>
        </el-dialog>
  </div>
</template>

<script>
import * as http from '@/assets/api/http.js'
import VDistpicker from 'v-distpicker'
export default {
  data() {
    return {
        dialogVisible:false,
        addList:[],
        checked:false,
        province:'',
        city:'',
        district:'',
        address:'',
        uname:'',
        phone:'',
        is_mr:0,
        editFlag:false,
        addressId:'',
        selectAddress:0,
        curAddressId:''
    };
  },
  methods: {
      slectAdd(item,index){
          this.selectAddress = index;
          this.curAddressId = item.id;
      },
      clearInfo(){
        this.province = ''
        this.city = ''
        this.district = ''
        this.address = ''
        this.uname = ''
        this.phone = ''
        this.is_mr = ''
        this.addressId = ''
        this.editFlag = false
      },
      save(){
        this.addMyAddress();
      },
      edit(item){
          console.log(item,'item')
        this.editFlag = true;
        this.province = item.province;
        this.city = item.city;
        this.district = item.district;
        this.address = item.address;
        this.uname = item.uname;
        this.phone = item.phone;
        this.is_mr = item.is_mr; 
        this.checked = this.is_mr==1?true:false;
        this.addressId = item.id;
        this.dialogVisible = true;
      },
      delUserAddress(){
          let params = {
              address_id:this.addressId
        }
        http.delUserAddress(params).then(value=>{
                if(value.code == 200){
                   this.addressList();
                   this.dialogVisible =false;  
                   this.clearInfo()
                }else{
                this.$message.error(value.msg)
                }
            })
      },
      cancel(){
        this.dialogVisible =false; 
        this.clearInfo()
      },
      addAddress(){
        this.dialogVisible =true;  
      },
      changeprovince(e){
          console.log(e)
         this.province = e.value;
         this.city = '';
         this.district = '';
      },
      changecity(e){
          this.city = e.value;
         this.district = '';
      },
      changedistrict(e){
         this.district = e.value;
      },
      // 收货地址列表
    addressList(){
      return  http.addressList().then(value=>{
                if(value.code == 200){
                   this.addList = value.data;
                   return
                }else{
                this.$message.error(value.msg)
                }
            })
    },
    // 添加收货地址
    addMyAddress(){
        if(!this.uname){
            this.$message.warning('请填写收货人姓名')
            return false;
        }
        var myreg = /^1[3,5,8]\d{9}$/;
        if (!myreg.test(this.phone)) {
            this.$message.warning('请输入正确的手机号')
            return false;
        }
        if(!this.province || !this.city || !this.district || !this.address){
            this.$message.warning('请完善地址信息')
            return false;
        }

        let params = {
            province:this.province,
            city:this.city,
            district:this.district,
            address:this.address,
            is_mr:this.checked?1:0,
            uname:this.uname,
            phone:this.phone,
            address_id:this.addressId

        }
        http.addMyAddress(params).then(value=>{
                if(value.code == 200){
                   this.addressList();
                   this.dialogVisible =false;  
                   this.clearInfo()
                }else{
                this.$message.error(value.msg)
                }
            })
    },
  },
  created() {
      this.addressList().then(value=>{
          if(!this.addList.length){
              return
          }
          console.log(this.addList,'this.addList[this.selectAddress].id')
          console.log(this.addList[this.selectAddress],'this.addList[this.selectAddress].id')
          console.log(this.addList[this.selectAddress].id,'this.addList[this.selectAddress].id')
          this.curAddressId = this.addList[this.selectAddress].id
      })
  },
//   mounted() {

//   },
  components: {
      'v-distpicker': VDistpicker
  },
}
</script>

<style lang="scss" scoped>

    .class_title{
        color:#666666;
        font-size:24px;
        margin-bottom: 30px;
    }
    .address_list{
        display:flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .address_list_item{
            width:260px;
            height: 160px;
            margin-bottom: 20px;
            border-radius: 5px;
            background: #F1F1F1;
            padding:25px;
            position: relative;
            box-sizing: border-box;
            font-size:14px;
            color:#666;
            cursor: pointer;
            border:1px solid transparent;
            &:hover{
                background: rgb(255,250,248);
                border:1px solid #E1410F;
            }
            &.active{
                background: rgb(255,250,248);
                border:1px solid #E1410F;
            }
            .address_title{
                display:flex;
                color:#333;
                font-size:16px;
                justify-content: space-between;
                .edit_address{
                    color:#E1410F;
                    font-size: 14px ;
                }
            }
            .address_phone{
                margin-top:10px;
            }
            .address{
                margin-top:20px;
                line-height:1.5;
                width:100%
            }
            .moren_address{
                position: absolute;
                right:0;
                bottom: 0;
                background:#E1410F;
                color:#fff;
                text-align: center;
                line-height:28px;
                width:84px;
            }
        }
        .address_list_item2{
            cursor:auto;
            &:hover{
                background: rgb(255,250,248);
                border:none;
            }
        }
        .address_list_add{
            display:flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            
            img{
                width:40px;
                height:40px;
                margin-bottom:17px;
            }
        }
    }
    /deep/.el-dialog{
        border-radius:20px;
    }
    .add_form{
        margin-bottom: 24px;
        .addinput{
            width: 470px;
        }
        
    }
    .btn_box{
        display:flex;
        justify-content: center;
        font-size:16px;
        color:#E1410F;
        span{
           border: 1px solid #E1410F;
           border-radius: 5px;
           width:148px;
           line-height: 44px;
           text-align: center;
           cursor: pointer;
        }
        .save_address{
            color:#fff;
            background-color: #E1410F;
            margin-right: 20px;
        }
    }

</style>