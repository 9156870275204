<template>
<div>
     <ul class="mall_list" v-if="goodList.length">
         <li class="mall_list_item" v-for="(item,index) in goodList" :key="index" @click="toLink(item)">
             <span class="tag" v-if="item.is_group == 1">
                 团购
             </span>
             <!-- <img  src="../../assets/image/mall/tag.png" alt=""> -->
             <img class="mall_img" :src="item.cover | fixImg" alt="">
             <div class="mall_info">
                 <div class="mall_title">
                    {{item.name}}
                 </div>
                 <div class='mall_tag'>
                     {{item.desc}}
                 </div>
                 <!-- <div class='mall_tag'>
                     月销：{{item.sale_count}}
                 </div> -->
                 <div class="mall_price_amount">
                     <div class="mall_price_left">
                         <span class="mall_price">
                            <i>¥</i>
                            <span v-if="item.is_group == 0 && item.is_sale == 0">{{item.price}}</span>
                            <span v-if="item.is_group == 0 && item.is_sale == 1">{{item.old_price}}</span>
                            <span v-if="item.is_group == 1">{{item.group_price}}</span>
                        </span>
                         <span class="mall_price_old" v-if="item.is_sale">
                            <i>¥</i>
                            <span>{{item.price}}</span>
                        </span>
                     </div>
                     
                     <span>月销：{{item.sale_count}}</span>
                 </div>
             </div>
         </li>
         <li class="mall_list_item now_show"></li>
     </ul>
     <div class="no_data"  v-if="!goodList.length">
         暂无更多数据~
     </div>
</div>

</template>

<script>
 export default {
  name: '',
  data () {
   return {

   }
  },
  props:{
      goodList:{
          default(){
              return []
          }
      }
  },
  methods:{
      toLink(item){
          this.$router.push({
              path:'/mallDetails',
              query:{
                  goods_id:item.id
              }
          })
      }
  },
  components: {

  }
 }
</script>

<style scoped lang="scss">
.mall_list{
        margin-top:20px;
        display:flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .mall_list_item{
            position: relative;
            width:284px;
            cursor:pointer;
            display:flex;
            flex-direction: column;
            margin-bottom: 30px;
            border-radius: 5px;
            overflow: hidden;
            &:hover{
                background: #222F3E;
                box-shadow: 0px 0px 23px 0px rgba(151, 151, 151, 0.49);
            }
            .tag{
                position: absolute;
                left:0;
                top:0;
                width:85px;
                height: 34px;
                line-height:34px;
                text-align: center;
                color:#fff;
                background:url('../../assets/image/mall/tag.png') no-repeat left center;
                background-size:100% 100%;
            }
            .mall_img{
                width:284px;
                height:286px;
            }
            .mall_info{
                background:#fff;
                padding:16px 16px 16px;
                .mall_title{
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                    color:#011022;
                    margin-bottom:5px;
                }
                .mall_tag{
                    display:flex;
                    color:#7B909D;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    font-size:14px;
                    margin-bottom:5px;
                    span{
                        margin-right:20px;
                        font-size:14px
                    }
                }
                .mall_price_amount{
                    // margin-top:20px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    color:#7B909D;
                    font-size:14px;
                    .mall_price{
                        color:#D90009;
                        font-size:24px;
                        display: flex;
                        align-items: center;
                        i{
                            font-style: normal;
                            font-size:16px;
                        }
                    }
                    .mall_price_left{
                        display:flex;
                        // align-items: flex-end;
                    }
                    .mall_price_old{
                            padding-top: 10px;
                        margin-left:5px;
                        i{
                            font-style: normal;
                        }
                        text-decoration:line-through
                    }
                }
            }
        }
        .now_show{
            height:0;
            margin:0;
            cursor: auto;
        }
    }
 .no_data{

     text-align: center;
     line-height:300px;
 }
</style>
