<template>
  <div class="my_order">
      <el-tabs v-model="activeName"  class="nav_tab" @tab-click="changeNav">
            <el-tab-pane label="课程" name="zero"></el-tab-pane>
            <el-tab-pane label="资讯" name="third"></el-tab-pane>
            <el-tab-pane label="商品" name="first"></el-tab-pane>
            <el-tab-pane label="题库" name="second"></el-tab-pane>
            
    </el-tabs>
    
    <!-- 题库 -->
    <div v-if="activeName == 'second'">
        <el-select size="mini" style="margin-bottom:20px;" v-model="cate_id" placeholder="请选择" @change="changeCate">
            <el-option
            v-for="item in questionList"
            @change="changeQusetion"
            :key="item.id"
            :label="item.name"
            :value="item.id">
            </el-option>
        </el-select>
    <el-table
        ref="table"
        :data="dataList"
        :row-class-name="tableRowClassName"
        @row-click="rowClick"
        :header-cell-style="{background: '#DCDCDC',color:'#333',fontSize:'16px',opacity:0.8,height:'60px'}"
        style="width: 100%">
        <el-table-column
            type="expand">
            <template slot-scope="props">
                <ul>
                    <li class="menu_list" v-for="(item,index) in props.row.menu_list" :key="index" @click="toWrong(props.row,item)">
                        {{item.menu_name}}
                    </li>
                </ul>
            </template>
        </el-table-column>
        <el-table-column
            prop="subject_name"
            label="学科">
        </el-table-column>
        <el-table-column
            prop="total"
            label="错题数"
            width="180">
        </el-table-column>
    </el-table>

    </div>
    <!-- 课程 -->
    <div v-if="activeName == 'zero'">
        <div v-if="!dataList.length" class="nodataClass">
            暂无数据
        </div>
        <div>
            <classList
                :dataList="dataList"
            ></classList>
           
        </div>
        
    </div>
    <!-- 商品 -->
    <goodList
        :goodList="dataList"
     v-if="activeName == 'first'"></goodList>
     <!-- 资讯 -->
     <ul v-if="activeName =='third'" class="news_container">
           <li class="nav_list" v-for="(item,index) in dataList" :key="index" @click="toDetails(item)">
               <div class="list_con">
                   <div class="list_title">
                       {{item.title}}
                   </div>
                   <div class="news_info">
                       <span>{{timeFront(item.created_at*1000)}}</span>
                       <span>阅读：{{item.look_count}}</span>
                   </div>
               </div>
               <img :src="item.cover_pc | fixImg" alt="">
           </li>
       </ul>
     <pagition 
     v-if="activeName=='first' || activeName=='zero' "
        :count="count"
        @currentChange='currentChange'
    >
    </pagition>
  </div>
</template>

<script>
import questionBank from '@/components/questionBank/questionBank'
import classList from '@/components/classList/classList'
import goodList from '@/components/mallList/mallList'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import * as http from '@/assets/api/http.js'
export default {
  data() {
    return {
        activeName:'zero',
        dataList:[],
        count:0,
        page:1,
        questionList:[
            {
                id:9,
                name:'法学'
            },
            {
                id:11,
                name:'非法学'
            }
        ],
        cate_id:9
    };
  },
  methods: {
      rowClick(row){
          console.log(row)
        this.dataList.forEach(v=>{
            if(v.subject_name != row.subject_name){
                this.$refs.table.toggleRowExpansion(v,false)
            }else{
                this.$refs.table.toggleRowExpansion(row)
            }
        })
      },
      tableRowClassName(){
          return 'tableRow'
      },
      currentChange(val){
          this.page = val;
          this.myCourse()
      },
      changeNav(e){
          console.log(this.activeName)
          if(this.activeName == 'zero'){
              this.page = 1;
              this.myCourse()
          }else if(this.activeName == 'first'){
              this.page = 1;
              this.goodsCollectList()
          }else if(this.activeName == 'second'){
              this.exercisesCollectsList()
          }else {
              this.newCollectsList()
          }
      },
      changeCate(e){
          this.exercisesCollectsList()
      },
      changeQusetion(e){
          this.exercisesCollectsList()
      },
       // 获取课程列表
    myCourse(){
        let that = this;
        let formdata = new FormData();
        //   分类
        formdata.append('uid',that.$store.state.uid);
        formdata.append('token',that.$store.state.token);
        formdata.append('type',2);
        formdata.append('p',that.page);
        formdata.append('state',2);
        http.myCourse(formdata).then(value=>{
            if(value.code == 200){
                this.dataList = value.data;
                this.count = value.count;
            }
        })
    },
    // 获取商品列表
    goodsCollectList(){
        let that = this;
        let formdata = new FormData();
        //   分类
        formdata.append('uid',that.$store.state.uid);
        formdata.append('token',that.$store.state.token);
        formdata.append('type',2);
        formdata.append('p',that.page);
        formdata.append('state',2);
        http.goodsCollectList(formdata).then(value=>{
            if(value.code == 200){
                this.dataList = value.data;
                this.count = value.count;
            }
        })
    },
    // 题库收藏
    exercisesCollectsList(){
        let that = this;
        let formdata = new FormData();
        //   分类
        formdata.append('uid',that.$store.state.uid);
        formdata.append('token',that.$store.state.token);
        formdata.append('cate_id',that.cate_id);
        http.exercisesCollectsList(formdata).then(value=>{
            if(value.code == 200){
                this.dataList = value.data;
            }
        })
    },
    // 资讯收藏列表
    newCollectsList(){
        let that = this;
        let formdata = new FormData();
        //   分类
        formdata.append('uid',that.$store.state.uid);
        formdata.append('token',that.$store.state.token);
        http.newCollectsList(formdata).then(value=>{
            if(value.code == 200){
                this.dataList = value.data;
            }
        })
    },
    // 查看收藏
    toWrong(item1,item2){
        this.$router.push({
              path:'/collectDetails',
              query:{
                  subject_id:item1.id,
                  menu_id:item2.menu_id
              }
          })
    },
    // 咨询详情
    toDetails(item){
        this.$router.push({
            path:'/newsDetails',
            query:{
                'news_id':item.id
            }
        })
    }
  },
  created() {
      this.myCourse()
    //   this.goodsCollectList()
  },
//   mounted() {

//   },
  components: {questionBank,goodList,classList},
}
</script>

<style lang="scss" scoped>
.my_order{
    padding:40px 30px;
    /deep/ .el-tabs__item{
        color:#666;
        font-size:18px;
    }   
    /deep/ .el-tabs__item.is-active{
                color: #333333;
            }
            /deep/ .el-tabs__active-bar{
                background-color: #CE0A00;
            }
            /deep/ .el-tabs__item:hover {
                color: #333333;
                cursor: pointer;
            }
    .good_list{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .good_list_items{
            width:278px;
            margin-bottom: 22px;
            background: #FFFFFF;
            border-radius: 5px;
            box-shadow: 0px 4px 16px 0px rgba(191, 191, 191, 0.79);
            img{
                width:278px;
                height: 278px;
            }
            .goods_details{
                padding:20px;
                .goods_name{
                    color:#011022;
                    font-size:18px;
                    margin-bottom: 18px;
                    overflow : hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
                .goods_tag{
                    color:#7B909D;
                    font-size:14px;
                    display:flex;
                    margin-bottom: 28px;
                }
                .goods_info{
                    display: flex;
                    justify-content: space-between;
                    color:#7B909D;
                    font-size:14px;
                    .goods_price{
                        color:#D90009;
                        font-size:16px;
                    }
                }
            }
        }
    }
    .nodata{
        width:100%;
        height:400px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.nodataClass{
    width:100%;
    height:370px;
    justify-content: center;
    display: flex;
    align-items: center;
}
.menu_list{
     background:#f8f8fb;
     font-size: 14px;
    color: #909399;
    line-height:50px;
    border-bottom: 1px solid #f8f8fb;
    padding-left:30px;
    cursor: pointer;
 }
 /deep/.el-table .tableRow{
        height:74px;
    }
     /deep/.el-table .el-table__expanded-cell{
        padding:0
    }
.news_container{
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #DCDCDC;
}
.nav_list{
    display:flex;
    justify-content: space-between;
    
    padding: 10px 0;
    width: 48%;
    margin-right:1%;
    &:nth-child(2n){
        margin-left:1%;
    }
    .list_con{
        flex:1;
        display:flex;
        flex-direction: column;
        height: 105px;
        justify-content: space-between;
        cursor: pointer;
    }
    .list_title{
        color:#333333;
        font-size:18px;
        display: -webkit-box;
-webkit-box-orient: vertical;
-webkit-line-clamp: 2;
overflow: hidden;
    }
    img{
        width: 150px;
        height: 105px;
        border-radius: 8px;
        margin-left:20px
    }
    .news_info{
        color:#666666;
        font-size:14px;
        span{
            margin-right:40px;
        }
    }
}
</style>