<template>
  <div>
      <div class="btn_opera">
                   <div @click="shoDialog" class="plinput">
                       <el-input readonly  placeholder="发表评论"  size="mini"></el-input>
                   </div>
                    
                    <img src="../../assets/image/share.png" @click="erweimaFlag = true" alt="">
                    <img @click="exercisesCollects" v-if="curData.user_collects != 1 && changeShow" src="../../assets/image/sc.png" alt="">
                    <img @click="exercisesCollects" v-if="curData.user_collects == 1 && changeShow" src="../../assets/image/sc_active.png" alt="">
                    <span style="display:flex;align-items:center;position:relative;cursor:pointer" @click="morepl">
                        <img src="../../assets/image/pinglun.png" alt="" >
                        <i class="pl_num" style="cursor:pointer">
                            
                            {{pinglunList.length?pinglunList.length:0}}
                        </i>
                    </span>
                </div>
        <el-dialog
            :visible.sync="dialogVisible"
            width="475px"
            >
            <div>
                <p class="dialog_title">
                    请输入您的评论
                </p>
                <div class="ask_content">
                    <textarea v-model="askContent" class=""></textarea>
                </div>
                
            </div>
            <div class="ask_footer">
                <span @click="cancle">取消</span>
                <span class="submit" @click="submit">提交</span>
            </div>
        </el-dialog>
        <!-- 二维码扫描 -->
      <el-dialog
        :visible.sync="erweimaFlag"
        width="30%">
        <div style="text-align:center">请下载APP端分享</div>
        <div style="text-align:center">
            <img class="down_erweima" :src="systemInfo.app_download | fixImg" alt="">
        </div>
        </el-dialog>
        <!-- 评论列表 -->
        <el-dialog
        :visible.sync="pinglunFlag"
        width="30%"
        >
        <div>
            <ul class="pl_list_container" v-if="pinglunList.length">
                <li class="pl_list" v-for="(item,index) in pinglunList" :key="index">
                    <div class="pl_list_person">
                        <img :src="item.user_uhead |fixImg" alt="" @click="addChiild(item)">
                    </div>
                    <div class="pl_list_content">
                        <p class="pl_list_name">
                            {{item.user_name}}
                        </p>
                        <div class="pl_list_con">
                            {{item.content}}
                        </div>
                        <div class="other_reply" v-if="item.child && item.child.length">
                            <div v-for="(childItem,childIndex) in item.child" :key="`${childIndex}o`">
                                {{childItem.user_name}}
                                回复
                                {{item.user_name}}：{{childItem.content}}
                            </div>
                        </div>
                        <div class="pl_list_info">
                            <span>提问时间：{{timestampToTime(item.created_at*1000)}}</span>
                        </div>
                    </div>
                </li>
            </ul>
            <div v-if="!pinglunList.length" style="text-align:center;margin-bottom:20px;">
                暂无更多数据~
            </div>
        </div>
        <div class="my_ask">
           <el-input size="mini" placeholder="写下你的评论" v-model="askContent"></el-input>
           <el-button class="sendInfo" type="primary" size="mini" @click="sendInfo">发送</el-button>
        </div>
        <!-- <div class="ask_footer">
            <span @click="cancle">取消</span>
            <span class="submit" @click="submit">提交</span>
        </div> -->
     </el-dialog>
  </div>
</template>

<script>
import * as http from '@/assets/api/http.js'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
        askContent:'',
        dialogVisible:false,
        systemInfo:{},
        erweimaFlag:false,
        isCollect:false,
        changeShow:true,
        pinglunFlag:false,
        pinglunList:[],
        pid:'',
        to_user_id:'',
        subType:1
    };
  },
  props:{
      id:{
          default(){
              return ''
          }
      },
      data:{
          default(){
              return {}
          }
      }
  },
  computed:{
      curData:{
          get(){
              return this.data
          },
          set(){

          }
      }
  },
  methods: {
      cancle(){
          this.dialogVisible = false;
          this.subType = 1;
      },
      shoDialog(){
          this.dialogVisible = true;
      },
      submit(){
          this.addExercisesEvaluate(this.subType)
      },
       // 系统设置信息
    getSysConfig(){
        http.getSysConfig().then(value=>{
            if(value.code == 200){
                this.systemInfo = value.data;
            }
        })
    },
      // 评论
     addExercisesEvaluate(type = 1 ){
         if(!this.askContent){
             this.$message.warning('评论内容不能为空')
         }
        let params = {
            exercises_id:this.id,
            content:this.askContent
        }
        if(type == 2){
            params.to_user_id = this.to_user_id;
            params.pid = this.pid;
        }
      http.addExercisesEvaluate(params).then(value=>{
            if(value.code == 200){
                this.dialogVisible = false;
                this.$message.success('发布成功')
                this.exercisesEvaluateList();
                this.askContent = '';
                this.subType = 1;   
            }
        })
    },
    // 题目收藏
    exercisesCollects( ){
        let params = {
            exercises_id:this.id,
        }
      http.exercisesCollects(params).then(value=>{
            if(value.code == 200){
                this.$message.success(value.msg);
                console.log(this.curData)
                this.changeShow = !this.changeShow
                if(this.curData.user_collects == 1)
                {
                    this.curData.user_collects = 0
                }else{
                    this.curData.user_collects = 1
                }
                this.changeShow = !this.changeShow
            }
        })
    },
    // 打开评论列表
    morepl(){
        this.pinglunFlag = true;
        this.exercisesEvaluateList()
    },
    // 获取题目评价
    exercisesEvaluateList(){
        let params = {
            exercises_id:this.id,
        }
      http.exercisesEvaluateList(params).then(value=>{
            if(value.code == 200){
                this.pinglunList = value.data
            }
        })
    },
    sendInfo(){
        this.addExercisesEvaluate()
    },
    // 添加子评论
    addChiild(item){
        console.log(item.user_id,this.$store.state.uid)
        if(item.user_id == this.$store.state.uid){
            this.$message.warning('不能回复自己的评论');
            return
        }
        this.to_user_id = item.to_user_id;
        this.pid = item.id;
        this.dialogVisible = true;
        this.subType = 2;
    }
  },
  created() {

  },
  mounted() {
      this.getSysConfig()
      this.exercisesEvaluateList()
  },
  components: {},
}
</script>

<style scoped lang="scss">
.btn_opera{
    display:flex;
    justify-content: center;
    align-items: center;
    margin-top:20px;
    .plinput{
        flex:0.6
    }
    img{
        width:20px;
        height:20px;
        margin-left:10px;
    }
}
.dialog_title{
    text-align: center;
    color:#333333;
    font-size: 20px;
}
.ask_content{
    width:80%;
    margin:0 auto;
    margin-top:25px;
    border: 1px solid #ccc;
    border-radius: 7px;
    box-sizing: border-box;
    textarea{
        width:99%;
            height:200px;
            border:none;
            outline:none;
            resize:none;
            // background: #f8f8fb;
    }
}
/deep/ .el-dialog__header{
    display:none
}
/deep/.el-dialog{
    border-radius:20px;
}
.ask_footer{
    display:flex;
    justify-content: space-between;
    margin:0 auto;
    margin-top:20px;
    width:80%;
    span{
        border:1px solid #ccc;
        border-radius:16px;
        flex:0.48;
        height: 52px;
        text-align: center;
        line-height: 52px;
        color:#272C2F;
        font-size:16px;
        cursor: pointer;
        &.submit{
            background:#DC091D;
            color:#fff;
        }
    }
}
.pl_list_container{
    max-height:500px;
    overflow: auto;
}
.my_ask{
    margin-top:20px;
    display:flex;
    .sendInfo{
        margin-left:20px;
    }
}
.pl_list{
    display:flex;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    padding:10px 0;
    .pl_list_person{
        display:flex;
        flex-direction: column;
        margin-right:20px;
        img{
            width:50px;
            height:50px;
            border-radius:50%;
        }
        
    }
    .pl_list_name{
            margin-bottom:10px;
            font-size:14px;
            color:#333;
        }
    .pl_list_content{
        display:flex;
        flex:1;
        flex-direction: column;
    }
    .other_reply{
        background: #f8f8fb;
        padding: 8px;
        padding-bottom: 11px;
        margin-bottom: 11px;
        color: #515f68;
        line-height: 22px;
    }
    .pl_list_info{
        display:flex;
        justify-content: space-between;
        margin-top:10px;
        .ques_info_reply{
            margin-left:20px;
            display:flex;
        }
        .zan{
            cursor: pointer;
            display:flex;
            align-items: center;
            margin-left:20px;
            line-height:20px;
            img{
                width:15px;
                height:15px;
                margin-right:5px;
            }
            span{}
        }
    }
}
.pl_list_container{
    max-height:500px;
    overflow: auto;
}
.pl_num{
    position: absolute;
    color:#fff;
    // width: 18px;
    // height: 18px;
    // text-align: center;
    // line-height: 20px;
    padding:1px;
    color: #fff;
    background-color: red;
    border-radius:50%;
    right: -10px;
    top: -10px;
    font-size: 12px;
    font-style: normal;
    width: 20px;
    height: 20px;
    padding: 0;
    text-align: center;
    line-height: 20px;
}
</style>