<template>
<!-- 回显已有数据+做一道回显一道 -->
      <div class="answer_wrong">
          <!-- 题目编号 -->
          <div class="question_order">
              <slot  class="title_question" name="title">错题</slot >
              <div class="order_list">
                  <span :class="['order_list_item',{'look_false':item.user_answer_status == 2,'look_true':item.user_answer_status == 1,'active':(index == orderSelect) && !questionBanlList[orderSelect].islook}]" v-for="(item,index) in questionBanlList" :key="index" @click="changeOrder(index)">
                      {{index+1}}
                  </span>
              </div>
          </div>
          <!-- 题目内容 -->
          
          <div class="question_moudle" v-if="questionBanlList.length && showChange">
             
              <div class="question_content">
                  {{orderSelect+1}}、{{questionBanlList[orderSelect].name}}
                  <span v-if="$route.path == '/textDetails' && questionBanlList[orderSelect].type == 1">（单选）</span>
                  <span v-if="$route.path == '/textDetails' && questionBanlList[orderSelect].type == 2">（单选）>（多选）</span>
                  <span v-if="$route.path == '/textDetails' && questionBanlList[orderSelect].type == 3">（单选）>（主观）</span>
                  <span v-if="$route.path == '/textDetails'">本题{{questionBanlList[orderSelect].point}}分</span>&nbsp;&nbsp;
                  <span v-if="$route.path == '/textDetails'">得{{questionBanlList[orderSelect].user_point}}分</span>
                  <!-- <el-button size="mini" type="primary" @click="toPath">重做</el-button> -->
                  <slot  name="operation"></slot >
              </div>
              <!-- 客观题答题 -->
              <ul class="answer_list" v-if="questionBanlList[orderSelect].type!=3">
                  <li  v-for="(item,index) in questionBanlList[orderSelect].answer" :key="index" style="margin-bottom:15px;" >
                      <span class="answer_list_item" @click="changeSelect(item)">
                          <!-- 答题模式 -->
                            <img v-show="!questionBanlList[orderSelect].islook"  class="answer_check" :src="questionBanlList[orderSelect].answerSelect.includes(item.sort)?select_current:no_select" alt="">
                        <!-- 查看模式 -->
                            <img v-show="questionBanlList[orderSelect].islook && questionBanlList[orderSelect].correct_answer_arr.includes(item.sort)"  class="answer_check" :src="select_current" alt="">
                            <img v-show="questionBanlList[orderSelect].islook && !questionBanlList[orderSelect].correct_answer_arr.includes(item.sort) && questionBanlList[orderSelect].answerSelect.includes(item.sort)" class="answer_check" :src="select_error" alt="">
                            <img v-show="questionBanlList[orderSelect].islook && !questionBanlList[orderSelect].correct_answer_arr.includes(item.sort) && !questionBanlList[orderSelect].answerSelect.includes(item.sort)"  class="answer_check" :src="no_select" alt="">
                            <span>
                                {{item.identity}}.{{item.name}}
                            </span>
                      </span>
                      
                  </li>
              </ul>
              <!-- 主观题答题 -->
              <div class="answer_text" v-if="questionBanlList[orderSelect].type ==3">
                  <textarea 
                    class="answer_area"
                    placeholder="请输入内容"
                    :readonly="questionBanlList[orderSelect].islook"
                    v-model="questionBanlList[orderSelect].answerSelect">
                </textarea >
              </div>
              <slot name="content"></slot>  
              <div v-if="questionBanlList[orderSelect].islook">
                  <div class="diffcult" >
                        难度：
                        <img v-for="(item,index) in questionBanlList[orderSelect].difficulty" :key="`${index}y`" src="../../assets/image/question/shoucang.png" alt="">
                        <img v-for="(item,index) in (5-questionBanlList[orderSelect].difficulty)" :key="`${index}x`" src="../../assets/image/question/no_shoucang.png" alt="">
                    </div>
                        <!-- <div class='statistics'>
                            统计：全部考生答17566次，对8858次，正确率50.4%，本人作答1次，对0次，正确率0%
                        </div> -->
                        <div class="explain">
                            <div class="current_answer">
                                [ 正确答案 ]&nbsp;&nbsp;{{questionBanlList[orderSelect].correct_answer}}
                            </div>
                            <div class="deacidizing">
                                [ 考点还原 ]
                            </div>
                            <div class="testing_ceter">
                                {{questionBanlList[orderSelect].site}}
                            </div>
                            <div class="deacidizing">
                                [ 考点还原 ]
                            </div>
                            <div class="testing_ceter" v-if="questionBanlList[orderSelect].analysis_type == 1" v-html="questionBanlList[orderSelect].analysis"> </div>
                            <div class="testing_ceter" v-if="questionBanlList[orderSelect].analysis_type == 2">
                                    <video-player  class="video-player vjs-custom-skin" style="width: 100%; height:100%; object-fit: fill"
                                                ref="videoPlayer"
                                                :playsinline="true"
                                                :options="videoPlayerOptions"
                                    ></video-player>
                            </div>
                        </div>
              </div>
              
                <div class="btn_list">
                        <el-button size="mini" @click="pre" :disabled="orderSelect == 0"> 上一题</el-button>
                        <span v-show="!questionBanlList[orderSelect].islook" :class="['subAnswer']" @click="submit"> 提交答案</span>
                        <span v-show=" questionBanlList[orderSelect].islook" :class="['subAnswer']" style="opacity: 0;" > &nbsp;</span>
                        <!-- <slot name="submit">&nbsp;</slot> -->
                        <el-button size="mini" @click="next" :disabled="orderSelect == questionBanlList.length-1"> 下一题</el-button>
                </div>
                <div v-if="questionBanlList[orderSelect].islook">
                    <pinglun
                        :id="questionBanlList[orderSelect].id"
                        :data="questionBanlList[orderSelect]"
                    ></pinglun>
                </div>

          </div>
      </div>
</template>

<script>
import no_select from '@/assets/image/question/no_select.png'
import select_current from '@/assets/image/question/select_current.png'
import select_error from '@/assets/image/question/select_error.png'
import pinglun from '@/components/pinglun/pinglun.vue'
 export default {
  name: '',
  data () {
   return {
       orderSelect:0,
       answerSelect:null,     //每道题回答的集合
       answerError:null,
       curQuestion:{},
       no_select,select_current,select_error,
       showChange:true,
       textarea:''
   }
  },
  props:{
      questionNumber:{
          default(){
              return []
          }
      },
  },
  computed:{
      questionBanlList:{
          get(){
              return JSON.parse(JSON.stringify(this.questionNumber))
          },
          set(){}
      },
       videoPlayerOptions () {
        const videoPlayerOptions = {
          playbackRates: [0.75, 1.0, 1.25, 1.5,2.0], //播放速度
          autoplay: true, // 是否自动播放。
          muted: false, // 是否静音播放，默认情况下将会消除任何音频。
          loop: false, // 是否循环播放。
          preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
          language: 'zh-CN',
          aspectRatio: '3:1', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
          fluid: true, // 是否流体从而按比例缩放以适应其容器。
          flash:{hls:{withCreadentials:true}},//可以播放rtmp视频
          html5:{hls:{withCreadentials:true}},//可以播放m3u8视频
          sources: [
              {
                type: 'video/mp4', // 类型https://d2zihajmogu5jn.cloudfront.net/bipbop-advanced/bipbop_16x9_variant.m3u8
                src: this.questionBanlList[this.orderSelect].analysis_video
            }
          ],
          poster: this.posterUrl, // 封面地址
          width: '100%',
          notSupportedMessage: '此视频暂无法播放...', // 当无法播放时允许覆盖Video.js，显示的默认信息。
          controlBar: {
            timeDivider: true,
            durationDisplay: true,
            remainingTimeDisplay: false,
            fullscreenToggle: true
          }
        }
        return videoPlayerOptions
      }
  },
  methods:{
      changeOrder(index){
        this.orderSelect = index;
      },
      changeSelect(item){
          if(this.questionBanlList[this.orderSelect].islook){return}
          this.showChange = !this.showChange
        //   类型 1 单选 2 多选 3 主观
          if(this.questionBanlList[this.orderSelect].type == 1){
              if(!this.questionBanlList[this.orderSelect].answerSelect.includes(item.sort)){
                    this.questionBanlList[this.orderSelect].answerSelect[0] = item.sort
                }
          }if(this.questionBanlList[this.orderSelect].type == 2){
              if(!this.questionBanlList[this.orderSelect].answerSelect.includes(item.sort)){
                    this.questionBanlList[this.orderSelect].answerSelect.push(item.sort)
                }else{
                    this.questionBanlList[this.orderSelect].answerSelect.splice(this.questionBanlList[this.orderSelect].answerSelect.indexOf(item.sort),1)
                }
          }
          this.showChange = !this.showChange;
      },
      toPath(){
          this.$router.push({
              path:'/wrongAnswer'
          })
      },
    //   下一题
      next(){
          this.orderSelect++;
      },
    //   上一题
    pre(){
        this.orderSelect--;
    },
    submit(){
        if(!this.questionBanlList[this.orderSelect].answerSelect.length){
            this.$message.warning('请至少选择一个答案');
            return
        }
        this.showChange = !this.showChange;
        this.$emit('submit',this.questionBanlList[this.orderSelect])
        this.questionBanlList[this.orderSelect].islook = true;
        this.showChange = !this.showChange;
        // 对考试提交的答案添加回答正确与否的判断
        // this.questionBanlList[this.orderSelect].answerSelect.sort();   //对选项进行排序
        // if(this.questionBanlList[this.orderSelect].correct_answer_arr.join(',') == this.questionBanlList[this.orderSelect].answerSelect.join(',')){
        //     this.$set(this.questionBanlList[this.orderSelect],'is_ans_current',true)
        // }else{
        //     this.$set(this.questionBanlList[this.orderSelect],'is_ans_current',false)
        // }
    },
  },
  components: {
    pinglun
  }
 }
</script>

<style scoped lang="scss">
.my_container{
        width:1200px;
        margin:0 auto;
        margin-bottom: 80px;
        .my_page_tit{
            // padding:30px 0;
            color:#666;
            font-size:12px;
            padding-bottom: 0;
        }
}
 .answer_wrong{
     display:flex;
     margin-top:20px;
    .question_order{
        min-height:588px;
        padding:20px;
        padding-right:0;
        width:260px;
        box-sizing: border-box;
        background: #fff;
        font-size:18px;
        color:#333;
        .order_list{
            margin-top:18px;
            display:flex;
            flex-wrap: wrap;
            .order_list_item{
                width:49px;
                height:49px;
                border-radius: 16px;
                background:#F0F2F3;
                color:#333333;
                font-size:14px;
                text-align: center;
                line-height:49px;
                margin-bottom: 10px;
                margin-right:8px;
                cursor: pointer;
                &.active{
                    background:#409EFF;
                    color:#fff;
                }
            }
            .look_true{
                background:#66ac28;
                color:#fff;
            }
            .look_false{
                background:#DC091D;
                    color:#fff;
            }
        }
    }
    .question_moudle{
        padding:30px;
        padding-bottom:50px;
        font-size:18px;
        color:#333;
        flex:1;
        background:#fff;
        margin-left:20px;
        display: flex;
        flex-direction: column;
        .question_content{
            margin-bottom:40px;
        }
        .answer_list{
            .answer_list_item{
                display:flex;
                cursor: pointer;
                .answer_check{
                    width:28px;
                    height:28px;
                    margin-right:20px;
                }
            }
        }
        .btn_list{
            margin-top:auto;
            display:flex;
            justify-content: center;
            .previous_question{
                width: 80px;
                height: 30px;
                border-radius: 8px;
                font-size:14px;
                text-align: center;
                line-height: 30px;
                border: 1px solid #D2D2D2;
                color:#333;
                cursor: pointer;
            }
            .subAnswer{
                width: 80px;
                height: 30px;
                background: #DC091D;
                border-radius: 8px;
                color:#fff;
                font-size:14px;
                text-align: center;
                line-height: 30px;
                margin:0 20px;
                cursor: pointer;
                // opacity: 0.5;
            }
            .active{
                opacity: 1;
            }
        }
    }
 }
 .diffcult{
    line-height:15px;
    margin-top:30px;
    img{
        width:15px;
        height:15px;
        margin-right:5px;
    }
}
.statistics{
    margin-top:20px;
    color:#8699A6;
    font-size:14px;
}
.explain{
    background:rgb(248,248,251);
    padding:20px;
    padding-bottom:25px;
    font-size:14px;
    margin-top:20px;
    margin-bottom: 30px;
    .current_answer{
        color:#2D81E4
    }
    .deacidizing{
        color:#EE2B0A;
        margin-top:20px;
    }
    .testing_ceter{
        color:#8699A6;
        margin-top:20px;
    }
}
.answer_text{
        background: #f8f8fb;
        padding: 16px;
        .answer_area{
            width:100%;
            height:200px;
            border:none;
            outline:none;
            resize:none;
            background: #f8f8fb;
        }
}

</style>
