<template>
  <div class="my_container">
      <p class="my_page_tit">
          <router-link to="/index">首页</router-link> ><router-link to="/teacherTeam">名师团队</router-link>  > {{details.name}}的个人主页
      </p>
      <div class="teacher_details">
        <img :src="details.uhead | fixImg" alt="">
        <div class="teacher_introuce">
          <div class="teacher_info">
            <p class='teacher_name'>{{details.name}}</p>
            <p>领域：{{details.professional}}</p>
          </div>
          <div class="teacher_value">
            <span>课程数 {{details.course_count}} </span>
            <span> 浏览量 {{details.look_count}}</span>
          </div>
          <div class="teacher_content">
            {{details.intro}}
          </div>
        </div>
      </div>

      <!-- 热门课程  -->
        <div class="module_title">
            <span></span>
            <p>热门课程</p>
            <p class="more" @click="toPath">更多精彩课程</p>
        </div>
        <classList
          :dataList="hotClassList"
        ></classList>
  </div>
</template>

<script>
import classList from '@/components/classList/classList'
import * as http from '@/assets/api/http.js'
export default {
  data() {
    return {
      details:{},
      hotClassList:[]
    };
  },
  methods: {
    // 分页教室列表
    teacherDetail(){
      let that = this;
      let formdata = new FormData();
      //   分类
      formdata.append('teacher_id',that.$route.query.teacher_id);
      http.teacherDetail(formdata).then(value=>{
              if(value.code == 200){
                  this.details=value.data;
              }else{
              this.$message.error(value.msg)
              }
          })
    },
    // 获取课程列表
    hotCourses(){
        http.hotCourses().then(value=>{
            if(value.code == 200){
                this.hotClassList = value.data;
            }
        })
    },
    toPath(){
      this.$router.push({
        path:'/curriculum'
      })
    }
  },
//   created() {

//   },
  mounted() {
    this.teacherDetail();
    this.hotCourses();
  },
  components: {classList},
}
</script>

<style scoped lang="scss">
.my_container{
        width:1200px;
        margin:0 auto;
        margin-bottom: 80px;
        .my_page_tit{
            padding:30px 0;
            color:#666;
            font-size:12px;
        }
        .teacher_details{
          background:#fff;
          border-radius: 3px;
          padding:40px 30px;
          padding-right:56px;
          display: flex;
          img{
            width:204px;
            height:204px;
            border-radius: 50%;
            margin-right:80px;
          }
          .teacher_introuce{
            flex:1;
            color:#333333;
            font-size:14px;
            .teacher_info{
              .teacher_name{
                color:#000;
                font-size: 20px;
              }
            }
            .teacher_value{
              margin-top:20px;
              color:#607082;
              span{
                margin-right:10px;
              }
            }
            .teacher_content{
              margin-top:34px;
              line-height: 1.5;
            }
          }
        }
        .module_title{
          margin-top:80px;
          margin-bottom: 15px;
          padding-left:20px;
          position: relative;
          color:#242424;
          font-size:26px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          span{
              position: absolute;
              width: 6px;
              height: 20px;
              background: #DC091D;
              left:0%;
              top:50%;
              margin-top:-10px;
          }
          .more{
              color:#666666;
              font-size:14px;
              cursor: pointer;
          }
      }
}
</style>