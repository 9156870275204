<template>
 <div class="my_container">
        <p class="my_page_tit">
          <router-link to="/index">首页</router-link> > <router-link to="/questionBank">题库</router-link> > 背题
      </p>
    <QuestionLayout
     ref="QuestionLayout"
        :questionNumber="questionNumber"
        model="pratice"
    >
        <template v-slot:title>
            <div class="titCol" >
                    <span>{{$route.query.cate_name}}</span>   
            </div>
        </template>
    </QuestionLayout>
 </div>
</template>

<script>
import * as http from '@/assets/api/http.js'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import QuestionLayout from '@/components/questionLayoutRecite.vue/questionLayoutRecite.vue.vue'
 export default {
  name: '',
  data () {
   return {
       questionNumber:[],
       islook:false,
   }
  },
  created(){
    //   this.randomList()
    console.log(this.$route.query)
    this.questionNumber = this.$route.query.data;
    this.questionNumber.forEach(v=>{
        this.$set(v,'tagIndex',this.$route.query.tagType)
    })
      
  },
  computed:{
    ...mapState(['cate_id'])
  },
  methods:{

  },
  destroyed(){
  },
  components: {
      QuestionLayout
  }
 }
</script>

<style scoped lang="scss">
.my_container{
        width:1200px;
        margin:0 auto;
        margin-bottom: 80px;
        .my_page_tit{
            padding:30px 0;
            color:#666;
            font-size:12px;
            padding-bottom: 0;
        }
}
.titCol{
    display:flex;
    justify-content: space-between;
    align-items: center;
}
.subAnswer{
                width: 80px;
                height: 30px;
                background: #DC091D;
                border-radius: 8px;
                color:#fff;
                font-size:14px;
                text-align: center;
                line-height: 30px;
                margin:0 20px;
                cursor: pointer;
                // opacity: 0.1;
            }
.tips_title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img{
        width:80px;
        height: 80px;
        margin-bottom: 20px;
    }
}
.tips_content{
    margin-top:15px;
    font-size:16px;
    color:#5F6E78;
    text-align: center;
    line-height:1.5
}
.tips_btn{
    width:162px;
    height:52px;
    line-height:52px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 16px;
    margin-right:10px;
    font-size:16px;
    cursor: pointer;
}
.tips_btn_submit{
    border: 1px solid #DC091D;
    color:#fff;
    background:#DC091D;
}
.dialog-footer{
    display:flex;
    justify-content: center;
    margin-top:35px;
}
/deep/ .el-dialog__header{
    display:none;
}
.submitpaper{
    background: #F3F8FE;
    border-radius: 8px;
    width: 45px;
    height: 24px;
    line-height:24px;
    color:#2D81E4;
    font-size:14px;
    text-align: center;
    margin-right:20px;
    cursor: pointer;
}
</style>
