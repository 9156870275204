<template>
  <div class="home">
    <div class="navtop">
      <div>
        热线电话：{{systemInfo.hotline}}
      </div>
      <div>
        <span style="cursor: pointer;" v-if="!personInfo.id" @click="toPath('/login')">登录</span>
        <span v-if="personInfo.id">欢迎你  {{personInfo.nickname}} </span>
        <span class="jiange">|</span>
        <span style="cursor: pointer;" v-if="!personInfo.id" @click="toPath('/regisiter')">注册</span>
        <span style="cursor: pointer;" @click="toPath('/my/myOrder')" v-if="personInfo.id">进入个人中心</span>
        <span class="jiange" v-if="personInfo.id">|</span>
        <span style="cursor: pointer;" @click="exitSite" v-if="personInfo.id">退出</span>
      </div>
    </div>
    <!-- logo栏 -->
    <div class="logo_search">
        <div class="logo_box">
            <img :src="systemInfo.logo | fixImg" alt="">
            <div class="fenge"></div>
            <div class="kouhao">专业品质 业内典范</div>
        </div>
        <div class="search_box">
            <div class="search_input">
                <input type="text" v-model="search" :placeholder="placeholder">
            </div>
            <div class="search_btn" @click="toCourList()">搜索</div>
        </div>
    </div>
    <!-- nav列表 -->
    <div class="navBox">
      <ul class='navList'>
        <li :class="['itemName',{'active':index == navIndex}]" v-for="(item,index) in navList" :key="index" @click="selectNav(index,item)">
          {{item.name}}
          <img src="../assets/image/向下.png" alt="" v-if="index == 1">
          <div class="nav_select" v-if="index == 1">
            <div v-for="(item,index) in questionList" :class="[{'questionActive':item.id == cate_id}]" :key="index" @click.stop="toQuestionBank(item.id)">{{item.name}}</div>
          </div>
        </li>
    </ul>
    </div>
    <!-- 内容 -->
    <router-view></router-view>
    <!-- 底部 -->
    <div class="footer_container">
      <footer class="footer">
        <ul class='footerList'>
            <li class="itemName" v-for="(item,index) in navList" :key="index"  @click="selectNav(index,item)">
              {{item.name}}
            </li>
        </ul>
        <!-- <div class="shiming_box">
            <img src="../assets/image/home/shiming.png" alt="">
            <img src="../assets/image/home/shiming2.png" alt="">
        </div> -->
        <div class="link_list">
            友情链接：
            <span class="linkName" v-for="(item,index) in linkList" :key="index" @click="toFriendLink(item.url)">{{item.name}}</span>
        </div>
        <div class="footerline"></div>
        <div class="footer_last">
          Copyright © 2021 imooc.com All Rights Reserved | {{systemInfo.icp}} 京公网安备11010802030151号
        </div>
      </footer>
    </div>
    
  </div>
  
</template>

<script>
import * as http from '@/assets/api/http.js'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
export default {
  name: 'Home',
  data(){
    return {
      search:'',
      navIndex:null,
      placeholder:'请输入课程名称',
      navList:[
        {
          name:'首页',
          path:'/index'
        },
        {
          name:'题库',
          path:'/questionBank'
        },
        {
          name:'课程',
          path:'/curriculum'
        },
        {
          name:'名师团队',
          path:'/teacherTeam'
        },
        {
          name:'商城',
          path:'/mall'
        },
        {
          name:'关于我们',
          path:'/aboutUs'
        },
        {
          name:'app下载',
          path:'/appDownLoad'
        },
      ],
      questionList:[
        {
          id:'11',
          name:'非法学'
        },
        {
          id:'9',
          name:'法学'
        },
      ],
      linkList:[],
      systemInfo:{},
      searchType:1   //1搜索课程2搜索题库
    }
  },
  computed:{
    ...mapState(['personInfo','cate_id'])
  },
  created(){
    this.friendLink();
    this.getSysConfig();
    // this.exercisesCategory()
  },
  watch:{
    '$route':{
      handler(value){
        console.log(value,'aaa');
        this.navList.forEach((v,index)=>{
          if(v.path == value.path){
            this.navIndex = index;
          }
        })
        if(value.meta.title == 'question'){
          this.searchType = 2
          this.placeholder="请输入题目名称"
        }else if(value.meta.title == 'laws'){
          this.searchType = 3;
          this.placeholder="请输入法律法规名称"
        }else{
          this.searchType = 1;
          this.placeholder="请输入课程名称"
        }
      }
    }
  },
  methods:{
    ...mapMutations(['clearInfo','searchFlag','changeQuestionIndex']),
    toPath(path){
      this.$router.push({
        path
      })
    },
    // nav跳转
    selectNav(index,item){
      this.navIndex = index;
      if(index == 1){
        if(!this.cate_id){
          this.changeQuestionIndex('9')
        }
      }
       this.$router.push({
        path:item.path
      })
    },
    toQuestionBank(cateIndex){
      this.changeQuestionIndex(cateIndex)
      this.$router.push({
        path:'/questionBank'
      })
    },
    // 友情链接
    friendLink(){
      http.friendLink().then(value=>{
            if(value.code == 200){
              console.log(value)
              this.linkList = value.data
            }else{
              this.$message.error(value.msg)
            }
        })
    },
    // 跳转友情链接
    toFriendLink(path){
      window.location.href=path
    },
    // 退出
    exitSite(){
      this.clearInfo();
      this.$router.push({
        path:'/login'
      })
    },
    // 系统设置信息
    getSysConfig(){
        http.getSysConfig().then(value=>{
            if(value.code == 200){
                this.systemInfo = value.data;
            }
        })
    },
    // 获取题库分类
    exercisesCategory(){
      http.exercisesCategory().then(value=>{
            if(value.code == 200){
                this.questionList = value.data;
            }
        })
    },
    // 跳转列表页
    toCourList(){
      console.log(this.$route.path)
      if(this.searchType == 1){
          if(this.$route.path != '/curriculum'){
            this.$router.push({
              path:'/curriculum',
              query:{
                keyword:this.search
              }
            })
          }else{
            this.searchFlag(this.search)
          }
      }else if(this.searchType == 2){
        this.$router.push({
              path:'/searchQuestionList',
              query:{
                keyword:this.search
              }
            })
      }else{
        this.$router.push({
              path:'/searchLaws',
              query:{
                keyword:this.search
              }
            })
      }
      
      
      
    }
  }
}
</script>
<style lang="scss" scoped>
.home{
  height: 100%;
  // padding-bottom: 304px;
  box-sizing: border-box;
}
  .navtop{
    background: #F6F6F6;
    height:36px;
    width:1200px;
    margin:0 auto;
    line-height:36px;
    color:#777777;
    display:flex;
    justify-content: space-between;
    font-size:12px;
    .jiange{
      margin:0 10px;
    }
  }
  .logo_search{
    height:96px;
    width:1200px;
    margin:0 auto;
    display:flex;
    justify-content:space-between;
    align-content: center;
    .logo_box{
      display:flex;
      align-items: center;
      img{
        width:180px;
        height:58px;
      }
      .fenge{
        border-right:1px solid #DCDCDC;
        height:58px;
        margin:0 20px;
      }
      .kouhao{
        color:#333;
        font-size:20px;
      }
    }
    .search_box{
      display:flex;
      align-items: center;
      
      .search_input{
        padding-left:36px;
        width:270px;
        box-sizing: border-box;
        background: url('../assets/image/home/fdj.png') 10px 10px no-repeat #F6F6F6;
        background-size:18px 18px;
        height:40px;
        border-radius: 5px;
        input{
          background:#F6F6F6;
          line-height: 40px;
          font-size: 14px;
          color:#6D6D6D;
          border:none;
        }
      }
      .search_btn{
        width: 86px;
        height: 40px;
        background: #CF0A00;
        border-radius: 0px 5px 5px 0px;
        color: #FFFFFF;
        font-size: 16px;
        line-height: 40px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
  .navBox{
    background:#E1410F;
    min-width: 1200px;
  }
  .navList{
    line-height:40px;
    color:#fff;
    width: 1200px;
    margin: 0 auto;
    display:flex;
    .itemName{
      flex:1;
      text-align: center;
      cursor: pointer;
      position: relative;
      &:hover{
        background:#CF0A00;
        .nav_select{
            display:block; 
        }
      }
      .nav_select{
        background:#fff;
        width:100%;
        position: absolute;
        left:0;
        top:40px;   
        z-index:999; 
        display:none; 
        div{
          text-align: center;
          line-height:50px;
          color:#666666;
          font-size:16px;
          border-bottom:1px solid #eee;
          &:hover{
            background:#eee;
          }
          &:last-child{
            border:none;
          }
        } 
        }
    }
    
    .active{
      background-color: #CF0A00;
    }
    img{
      width:15px;
      height:15px;
      margin-left:5px;
    }
  }
  .footer_container{
    // position: fixed;
    // bottom: 0;
    // left:0;
    width: 100%;
    background:#646768;
  }
  .footer{
    padding-top:48px;
    padding-bottom: 20px;
    box-sizing: border-box;
    width: 1200px;
    margin: 0 auto;
    color:#fff;
    .footerList{
      display:flex;
      margin-bottom: 48px;
      padding:0 60px;
      .itemName{
       flex:1;
       text-align: center;
       cursor: pointer;
      }
    }
    .shiming_box{
      display:flex;
      justify-content: center;
      margin-bottom: 36px;
      img{
        margin-right:26px;
        width:109px;
        height:39px;
      }
    }
    .link_list{
      padding-left:155px;
      font-size: 12px;
      display: flex;
      justify-content: center;
      .linkName{
        margin-right: 10px;
        cursor: pointer;
      }
    }
    .footerline{
      width:1200px;
      height:1px;
      background: #A0A0A0;
      margin-top:27px;
    }
    .footer_last{
      text-align: center;
      font-size: 12px;
      margin-top:24px;
    }
  }
  .questionActive{
    color:#CF0A00 !important;
  }
</style>
