<template>
 <div  class="my_container">
     <p class="my_page_tit">
          <router-link to="/index">首页</router-link> > 题库 >{{cate_name}}
      </p>
      <!-- 搜索栏 -->
      <div class="search_list">
        <div class="search_list_item">
            <span class="items_tag"></span>
            <span class="item_search_name">按类别</span>
            <span :class="['item_search',{'active':cate_id == item.id}]" v-for="(item,index) in typeList" :key="index" @click="changeSearch(item.id,'type')">
                {{item.name}}
            </span>
        </div>
        <div class="search_list_item">
            <span class="items_tag"></span>
            <span class="item_search_name">按学科</span>
            <span :class="['item_search',{'active':index == subjectIndex}]" v-for="(item,index) in subjectList" :key="index" @click="changeSearch(index,'subject')">
                {{item.name}}
            </span>
        </div>
        <div class="search_list_item">
            <span class="items_tag"></span>
            <span class="item_search_name">按年份</span>
            <span :class="['item_search',{'active':index == yearIndex}]" v-for="(item,index) in yearList" :key="index" @click="changeSearch(index,'year')">
                {{item.year}}
            </span>
            <!-- <span class="item_search">
                <el-date-picker
                clearable
                :disabled ='subjectIndex !=null' 
                size="mini"
                v-model="start_year"
                type="year"
                @change="changeStartTime"
                range-separator="至"
                :picker-options="startTimeDisable"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
                </el-date-picker>
                至
                <el-date-picker
                clearable
                :disabled ='subjectIndex !=null' 
                size="mini"
                @change="changeEndTime"
                v-model="end_year"
                type="year"
                range-separator="至"
                :picker-options="endTimeDisable"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
                </el-date-picker> -->
            <!-- </span> -->
        </div>
        <!-- <div>
            <el-input size="mini" v-model="" style="width:80px;"></el-input>
        </div> -->
      </div>
      <!-- 内容 -->
      <div class="question_container">
          <!-- left -->
          <div class="question_content">
              <div class="question_title" v-if="subjectInfo.exercisesTotal">
                    <div class="question_number" v-if="yearIndex == null">
                        总题数：<span style="color:#E1860F">{{subjectInfo.exercisesTotal}}</span>
                    </div>
                    <div class="question_number" v-if="yearIndex != null">
                        总题数：<span style="color:#E1860F">{{yearList[this.yearIndex].num}}</span>
                    </div>
                    <div class="question_number" v-if="yearIndex == null">
                        已答题：<span  style="color:#2D81E4">{{subjectInfo.userExercisesTotal}}</span>
                    </div>
                    <div class="question_number" v-if="yearIndex == null">
                        未答题：<span  style="color:#CF0A00">{{subjectInfo.exercisesTotal - subjectInfo.userExercisesTotal}}</span>
                    </div>
              </div>
              <el-table
                    v-if="yearIndex == null"
                    :data="tableData"
                    :header-cell-style="{background: '#DCDCDC',color:'#333',fontSize:'16px',opacity:0.8}"
                    @row-click="rowClick"
                    style="width: 100%">
                    <el-table-column
                        prop="menu_name"
                        label="章节">
                        <template slot-scope="scope">
                            <div style="cursor: pointer;">
                                {{scope.row.menu_name }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="num"
                        label="题目数"
                        width="180">
                        <template slot-scope="scope">
                            <div style="cursor: pointer;">
                                {{scope.row.num }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="userMenuExercisesTotal"
                        width="180"
                        label="已答题">
                        <template slot-scope="scope">
                            <div style="cursor: pointer;">
                                {{scope.row.userMenuExercisesTotal }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="address"
                        width="180"
                        label="未答题"> 
                        <template slot-scope="scope">
                            <div style="cursor: pointer;">
                                {{scope.row.num -scope.row.userMenuExercisesTotal }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="userMenuExercisesTotal"
                        width="180"
                        label="是否解锁">
                        <template slot-scope="scope">
                            <div  style="cursor: pointer;">
                                {{subjectInfo.unlock_num == 0?'是':'否'}}
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <el-table
                    :data="tableData2"
                    v-if="subjectIndex == null"
                    :header-cell-style="{background: '#DCDCDC',color:'#333',fontSize:'16px',opacity:0.8}"
                    @row-click="rowClick2"
                    style="width: 100%">
                    <el-table-column
                        prop="course_type_name"
                        label="学科类别">
                        <template slot-scope="scope">
                            <div style="cursor: pointer;">
                                {{scope.row.course_type_name }}
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
          </div>
          <!-- right -->
          <div class="question_menu">
              <ul class="question_menu_list">
                  <li :class="['question_menu_list_item',{'active':index == leftIndex}]" v-for="(item,index) in menuList" :key="index" @click="toPath(item.link,index)">
                      <img :src="item.img" alt="">
                      <span>{{item.name}}</span>
                  </li>
              </ul>
          </div>
      </div>
      <!-- 二维码扫描 -->
      <el-dialog
        :visible.sync="dialogVisible"
        width="30%">
        <div style="text-align:center">请下载APP端并分享{{subjectInfo.type == 1?'QQ':'微信'}}{{subjectInfo.unlock_num}}次解锁</div>
        <div style="text-align:center">
            <img class="down_erweima" :src="systemInfo.app_download | fixImg" alt="">
        </div>
        </el-dialog>

 </div>
</template>

<script>
import * as http from '@/assets/api/http.js'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
 export default {
  name: '',
  data () {
   return {
       leftIndex:null,
       is_look:false,
       tableData: [],
       tableData2:[],
         typeList:[
                    {
                    id:'9',
                    name:'法学'
                    },{
                    id:'11',
                    name:'非法学'
                    },
                ],
        subjectList:[ ],
        subjectIndex:0,
        yearList:[],
        yearIndex:null,
        systemInfo:{},
        dialogVisible:false,
        menuList:[
            {
                img:require('../../assets/image/type1.png'),
                name:'错题',
                link:'/wrongQuestion'
            },
            {
                img:require('../../assets/image/type2.png'),
                name:'背诵',
                link:'/reciteQues'
            },
            {
                img:require('../../assets/image/type3.png'),
                name:'收藏',
                link:'/collect'
            },
            {
                img:require('../../assets/image/type4.png'),
                name:'统计',
                link:'/statistics'
            },
            {
                img:require('../../assets/image/type5.png'),
                name:'答疑',
                link:'/answeruestion'
            },
            {
                img:require('../../assets/image/type6.png'),
                name:'高频考点',
                link:'/testPoint'
            },{
                img:require('../../assets/image/type7.png'),
                name:'随机组题',
                link:'/randomQusetion'
            },
            {
                img:require('../../assets/image/type8.png'),
                name:'模拟考场',
                link:'/imitateTest'
            },{
                img:require('../../assets/image/type9.png'),
                name:'法律法规',
                link:'/laws'
            },
            {
                img:require('../../assets/image/type10.png'),
                name:'考试资讯',
                link:'/newsList'
            },

        ],
        cate_name:"",
        start_year:'',   //开始时间
        end_year:'',         //结束时间
        subjectInfo:{},
        startTimeDisable:{
            disabledDate:(e)=>{
                if(this.end_year){
                    return e.getTime() > this.end_year.getTime()
                }else{
                    return false
                }
            }
        },
        endTimeDisable:{
            disabledDate:(e)=>{
                if(this.start_year){
                    return e.getTime() < this.start_year.getTime()
                }else{
                    return false
                }
            }
        },
        menu_id:'',
   }
  },
  computed:{
    ...mapState(['cate_id'])
  },
  methods:{
      ...mapMutations(['changeQuestionIndex']),
    //   学科和时间不能共存
      changeSearch(index,type){
          if(type == 'type'){
              this.changeQuestionIndex(index)
          }
          if(type=='subject'){
              
              this.subjectIndex = index;
              this.yearIndex = null;
              this.getResult()
          }
          if(type == 'year'){
              this.subjectIndex = null;
              this.yearIndex = index;
              this.start_year='';
              this.end_year = ''
              this.tableData2 = this.yearList[this.yearIndex].course_type_list
            //   this.getResult()
          }
      },
      toPath(path,index){
          this.leftIndex = index;
          console.log(index)
          this.$router.push({
              path,
              query:{
                  cate_id:this.cate_id
              }
          })
      },
    //   修改日历时间
    changeStartTime(){
        if(this.start_year&& this.end_year){
            this.yearIndex = null;
            this.getResult()
        }
    },
    changeEndTime(){
        if(this.start_year&& this.end_year){
            this.yearIndex = null;
            this.getResult()
        }
    },
    // 获取题库分类
    exercisesCategory(){
     return http.exercisesCategory().then(value=>{
            if(value.code == 200){
                // this.typeList = value.data;
                // this.typeList = [
                //     {
                //     id:'9',
                //     name:'法学'
                //     },{
                //     id:'11',
                //     name:'非法学'
                //     },
                // ]
                Promise.resolve()
            }
        })
    },
    //   获取年份列表
    exercisesYearList(){
        let params = {
            cate_id:this.cate_id
        }
        console.log(params)
     return http.exercisesYearList(params).then(value=>{
            if(value.code == 200){
                this.yearList = value.data;
            }
            Promise.resolve()
        })
    },
    // 获取学科列表 
    exercisesSubjectList(){
        let params = {
            cate_id:this.cate_id
        }
    return  http.exercisesSubjectList(params).then(value=>{
            if(value.code == 200){
                this.subjectList = value.data;
            }
            Promise.resolve()
        })
    },
    // 根据条件查询获取结果  -->学科做题列表
    getResult(){
        let start_year,end_year;
        if(this.yearIndex != null){
            start_year = this.yearList[this.yearIndex].year;
            end_year = this.yearList[this.yearIndex].year;
        }else{
            start_year = this.start_year;
            end_year = this.end_year;
        }
        let params = {
            cate_id:this.cate_id,
            subject_id:this.subjectList[this.subjectIndex].id,
            start_year,
            end_year
        }
      http.exercisesSubjectList(params).then(value=>{
            if(value.code == 200){
                console.log(value.data);
                this.subjectInfo = value.data[0];
                this.tableData = value.data[0].menu_list;
            }
        })
    },
    init(){
        this.exercisesCategory().then(value=>{
          this.exercisesYearList().then(value=>{
              this.exercisesSubjectList().then(value=>{
                this.getResult();
              });
          });
          
      })
    },
    rowClick(e){
        console.log(e)
        if(this.subjectInfo.unlock_num != 0){
            this.dialogVisible = true
            return
        }
        this.menu_id = e.menu_id;
        this.$router.push({
                            path:'/questionBankList',
                            query:{
                                subject_id:this.subjectList[this.subjectIndex].id,
                                menu_id:this.menu_id
                            }
                        })
    },
    rowClick2(e){
        this.$router.push({
                            path:'/questionBankList',
                            query:{
                                year:this.yearList[this.yearIndex].year,
                                course_type:e.course_type,
                                type:'year'
                            }
                        })
        // this.exercisesCourseTypeList(e.course_type)
    },
    // 获取年份做题列表
    exercisesCourseTypeList(course_type){
        let params = {
            year:this.yearList[this.yearIndex].year,
            course_type,
            cate_id:this.cate_id
        }
    return  http.exercisesCourseTypeList(params).then(value=>{
            if(value.code == 200){
                if(value.data.length>0){
                    this.$router.push({
                            path:'/doQuestion',
                            query:{
                                year:this.yearList[this.yearIndex].year,
                                course_type,
                                type:'year'
                            }
                        })
                }else{
                    this.$message.warning('当前题库无数据')
                }
            }
        })
    },
      // 系统设置信息
    getSysConfig(){
        http.getSysConfig().then(value=>{
            if(value.code == 200){
                this.systemInfo = value.data;
            }
        })
    },
  },
  created(){
      if(!this.$store.state.token){
            this.$message.warning('请先登录进行更多操作');
            this.$router.push({
                path:'/login'
            })
            return
        }
      this.init()
      this.getSysConfig()
      this.typeList.forEach(v=>{
                      if(v.id == this.cate_id){
                          this.cate_name = v.name
                      }
                  })
  },
  watch:{
      'cate_id':{
          handler:function (oldval,val){
                  console.log(oldval,val)
                  this.typeList.forEach(v=>{
                      if(v.id == oldval){
                          this.cate_name = v.name
                      }
                  })
                    this.init()
          }
      },
  },
  components: {

  }
 }
</script>

<style scoped lang="scss">
.my_container{
        width:1200px;
        margin:0 auto;
        margin-bottom: 80px;
        .my_page_tit{
            padding:30px 0;
            color:#666;
            font-size:12px;
        }
}
 .search_list{
     background:#fff;
     padding:30px 20px;
     .search_list_item{
         display: flex;
         align-items: center;
         font-size:14px;
         color:#333;
         margin-bottom: 15px;;
         .items_tag{
             width:3px;
             height:13px;
             background:#CF0A00;
             border-radius:2px;
             margin-right:10px;
         }
         .item_search_name{
             margin-right:20px;
         }
         .active{
             background:#E1410F;
             color:#fff;
         }
         .item_search{
             padding:6px 15px;
             border-radius:14px;
             cursor:pointer;
             margin-right:20px;
         }
     }
 }
 .question_container{
     display:flex;
     margin-top:30px;
    .question_content{
        padding:30px 20px;
        width:920px;
        box-sizing: border-box;
        background:#fff;
        .question_title{
            display:flex;
            margin-bottom: 15px;
            .question_number{
                margin-right:15px;
                color:#666666;
                font-size:18px;
            }
        }
        
    }
    .question_menu{
        margin-left:20px;
        flex:1;
        background:#fff;
        padding:35px 0;
        height: 588px;
        box-sizing: border-box;
        .question_menu_list{
            display:flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .question_menu_list_item{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                color:#2C3846;
                font-size:14px;
                // margin-right:20px;
                margin-bottom: 35px;
                cursor: pointer;
                width: 50%;
                img{
                    width:50px;
                    height:50px;
                    margin-bottom: 10px;
                }
                &:nth-child(2n){
                    // margin-left: 20px;
                    // margin-right:0;
                }
            }
            .active{
                background:#fafafa;
            }
        }
    }
 }
 /deep/ .el-dialog{
     margin-top:30vh !important;
 }
 .down_erweima{
            width:210px;
            height:210px;
        }
</style>
