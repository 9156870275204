<template>
  <div class="my_container" v-if="details.info">
      <p class="my_page_tit">
          <router-link to="/index">首页</router-link> > <router-link to="/curriculum">课程</router-link>  >    {{details.info.name}}
      </p>
      <div class="curriculum_details">
          <img class="curriculum_img" :src="details.info.cover | fixImg" alt="">
          <div class="curriculum_right">
              <div class="curriculum_tit">
                  {{details.info.name}}
              </div>
              <div class="curriculum_introuce">
                  <span style="color:#333">课程概述：</span>
                  <span v-html="details.info.content"></span>
                  
              </div>
              <div class="curriculum_price" v-if="details.info.is_free != 1">
                  ￥{{details.info.price}}
              </div>
              <div class="curriculum_price"  v-if="details.info.is_free == 1">
                  免费
              </div>
              <ul class="curriculum_icon">
                  <li>
                      <img src="../../assets/image/maozi.png" alt="">
                      {{details.info.menu_count}}节课
                  </li>
                  <li>
                      <img src="../../assets/image/eye.png" alt="">
                     <!-- {{details.info.look_count}}次学习 -->
                     观看次数{{details.info.look_count}}
                  </li>
                  <li>
                      <img src="../../assets/image/star.png" alt="">
                      {{details.collect_count}}人收藏
                  </li>
                  <!-- <li>
                     学习期限：无限制
                  </li> -->
              </ul>
              <div class="curriculum_info">
                  <div class="curriculum_btn" v-if="details.buy == 0 && details.info.is_free == 0" @click="buy">立即购买（¥{{details.info.price}}）</div>
                  <div class="curriculum_btn" v-if="details.info.is_free == 1" @click="toVideo()">免费播放</div>
                  <div class="curriculum_btn" v-if="details.buy == 1 && details.info.is_free == 0" @click="toVideo()">开始学习</div>
                  <div class="curriculum_operation">
                      <div class='share_box'>
                          收藏课程
                          <img src="../../assets/image/sc.png" alt="" v-if="details.collect == 0" @click="changeCollect">
                          <img src="../../assets/image/ysc.png" alt=""  v-if="details.collect == 1" @click="changeCollect">
                      </div>
                      <div class='share_box'>
                          分享
                          <!-- <img style="margin-left:15px" src="../../assets/image/share1.png" @click="creatQrCode" alt=""> -->
                          <el-popover
                            placement="top"
                            width="100"
                            v-model="visible">
                            <div class="qrcode" ref="qrCodeUrl"></div>
                            <img slot="reference" style="margin-left:15px" src="../../assets/image/share1.png" @click="creatQrCode" alt="">
                        </el-popover>
                          <span style="margin-left:15px">|</span>
                          <img style="margin-left:15px" src="../../assets/image/share2.png" @click="sharewb" alt="">
                          <span style="margin-left:15px">|</span>
                          <img style="margin-left:15px" src="../../assets/image/share4.png" @click="shareQQ" alt="">
                          <span style="margin-left:15px" v-if="details.info.pdf && (details.buy == 1 || details.info.is_free == 1)">|</span>
                          
                          <!-- <a :href="details.info.pdf | fixImg" download> -->
                            <img @click="downPdf" v-if="details.info.pdf && (details.buy == 1 || details.info.is_free == 1)" style="margin-left:15px" src="../../assets/image/pdf.png" alt="">
                          <!-- </a> -->
                          <!-- <div class="erweimaImg"></div> -->
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="curriculum_content">
          <div class="curriculum_left">
              <el-tabs v-model="activeName" class="class_nav">
                <el-tab-pane label="课程信息" name="first"></el-tab-pane>
                <el-tab-pane label="目录" name="second"></el-tab-pane>
                <el-tab-pane label="评价交流" name="third"></el-tab-pane>
            </el-tabs>
            <!-- 课程目录 -->
            <div v-if="activeName=='second'">
                <div class="module_title">
                    <span></span>
                    课程目录
                </div>
                <div class="class_zhangjie">
                    <span></span>
                   {{details.info.name}}
                </div>
                <div>
                    <el-timeline>
                        <el-timeline-item
                        v-for="(item, index) in menuList"
                        :key="index"
                        :timestamp="`可观看${details.info.is_free == 1?'无限':item.look_num}次`">
                        <div class="active_row">
                            {{item.name}}
                            <div class="active_btn" @click="toVideo(index,item)" v-if="details.buy==1  || details.info.is_free == 1">
                                立即播放
                            </div>
                            <div class="active_btn" @click="buy(index)" v-if="details.buy==0  && details.info.is_free == 0">
                                立即购买
                            </div>
                        </div>
                        
                        </el-timeline-item>
                    </el-timeline>
                </div>
            </div>
            <!-- 评价交流 -->
            <div v-if="activeName=='third'">
                <div class="module_title">
                    <span></span>
                    评价交流
                </div>
                <ul class="evaluate_list">
                    <li v-for="(item,index) in commentList" :key="index">
                        <div class="evaluate_list_item"> 
                            <img class="evaluate_header" :src="item.user_uhead | fixImg" alt="">
                            <div class="evaluate_details">
                                <div class="evaluate_name">
                                    {{item.user_name}}
                                </div>
                                <div class="evaluate_ontent">
                                    {{item.content}} 
                                </div>
                                <div class="evaluate_info">
                                    <div>
                                        {{timestampToTime(item.created_at*1000)}}
                                    </div>
                                    <div class="evaluate_icon">
                                        <!-- <img src="../../assets/image/dianzan.png" v-if="item.is_goods == 0" @click="commentGoods(item.id)" alt="">
                                        <img src="../../assets/image/dianzanActive.png" v-if="item.is_goods == 1" @click="commentGoods(item.id)" alt="">
                                        <span>&nbsp;</span> -->
                                        <img src="../../assets/image/jiaoliu.png" alt="" @click="pinglun(item)">
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <ul class="evaluate_list_children">
                            <li class="evaluate_list_children_item" v-for="(items,index) in item.child" :key="items.id">
                                <img class="evaluate_header" :src="items.user_uhead | fixImg" alt="">
                                <div class="evaluate_details">
                                    <div class="evaluate_name">
                                        {{items.user_name}}
                                    </div>
                                    <div class="evaluate_ontent">
                                        {{items.content}} 
                                    </div>
                                    <div class="evaluate_info">
                                        <div>
                                            {{timestampToTime(items.created_at*1000)}}
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>
                <div class="evaluate_input">
                    <textarea name="" id="" v-model="commentContent" ></textarea>
                </div>
                <div class="evaluate_operation">
                    <div></div>
                    <div class="evaluate_submit" @click="addCourseComment">
                        发表评论
                    </div>
                </div>
            </div>
            <!-- 课程信息 -->
            <div v-if="activeName=='first'">
                <div class="module_title">
                    <span></span>
                    课程概述
                </div>
                <div class="evaluate_gaishu" v-html="details.info.content">
                </div>
                <div class="module_title">
                    <span></span>
                        课程标签
                </div>
                <div class="evaluate_tags">
                        <span>{{details.info.cate_name}}</span>
                        
                </div>
                <div class="module_title">
                    <span></span>
                        老师信息
                </div>
                <div class="evaluate_teacher_info">
                        <img :src="details.teacher.uhead | fixImg" alt="">
                        <div class='evaluate_teacher_data'>
                            <span>主讲老师：{{details.teacher.name}}</span>
                            <span>领域：{{details.teacher.professional}}</span>
                        </div>
                        <div class="evaluate_teacher_page" @click="toteacherDetails('/teacherDetails')">
                            老师主页
                        </div>
                </div>
            </div>
          </div>
          <!-- 右侧列表 -->
          <div class="curriculum_right_column">
              <p class="curriculum_right_title"></p>
                <ul class='class_list'>
                    <li class="class_list_items" v-for="(item,index) in hotClassList" :key="index" @click="toDetails(item)">
                        <img class="class_img" :src="item.cover | fixImg" alt="">
                        <div class="class_details">
                            <p class='class_name'>
                                {{item.name}}
                            </p>
                            <p class="class_teacher">
                                {{item.teacher_name}}
                            </p>
                            <div class="class_info">
                                <div class="class_number">
                                    <img src="../../assets/image/my/classIcon.png" alt="">
                                    <span>{{item.look_count}}</span>
                                </div>
                                <div class="class_price">
                                    ￥{{item.price}}
                                </div>
                            </div>
                        </div>
                    </li>
                    <li  class="class_list_items" v-for="(item,index) in 3" :key="`${index}y`" style="background:transparent;height:0;margin-bottom:0"></li>
                </ul>
          </div>
      </div>
      <!-- 评论弹窗 -->
      <el-dialog
        title="请输入评论"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose">
        <el-input
        type="textarea"
        :rows="8"
        placeholder="请输入内容"
        resize="none"
        v-model="textarea">
        </el-input>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false" size="mini">取 消</el-button>
            <el-button type="primary" @click="submitComment"  size="mini">确 定</el-button>
        </span>
    </el-dialog>
    <!-- pdf -->
    <!-- <pdf src="https://wenyun.1234ok.cn/upload/files/20210710/e4b737912a2e62776553cd661b36f5b7.pdf" :page="1" @progress="loadedRatio = $event" @num-pages="pageCount=$event" @page-loaded="pdfcurrentPage=$event" @loaded="loadPdfHandler" ref="wrapper" class="pdf"></pdf> -->
  </div>
</template>

<script>
import * as http from '@/assets/api/http.js'
import QRCode from 'qrcodejs2'
import pdf from 'vue-pdf'
export default {
  data() {
    return {
        activeName:'first',
        activities: [{
          content: '活动按期开始',
          timestamp: '2018-04-15'
        }, {
          content: '通过审核',
          timestamp: '2018-04-13'
        }, {
          content: '创建成功',
          timestamp: '2018-04-11'
        }],
        details:{},
        hotClassList:[],
        menuList:[],
        commentList:[],
        commentContent:'',
        dialogVisible:false,
        textarea:'',
        commentObj:{},
        visible: false,
    };
  },
  methods: {
      pdfcurrentPage(){

      },
      loadPdfHandler(){

      },
      creatQrCode() {
          this.$refs.qrCodeUrl.innerHTML = ""
        var qrcode = new QRCode(this.$refs.qrCodeUrl, {
            text: 'xxxx',
            width: 100,
            height: 100,
            colorDark: '#000000',
            colorLight: '#ffffff',
            correctLevel: QRCode.CorrectLevel.H
        })
        },
    //   二级评论回复
        submitComment(item){
            this.dialogVisible = false;
            this.addCourseComment(2) 
        },  
        handleClose(){
this.dialogVisible = false;
        },
      pinglun(item){
          this.dialogVisible = true;
          this.commentObj = item;
      },
      downPdf(){
          window.open(`${this.$url}${this.details.info.pdf}`)
      },
      toteacherDetails(){
          this.$router.push({
              path:'/teacherDetails',
              query:{
                  teacher_id:this.details.teacher.id
              }
          })
      },
    //   分享
      shareQQ(){
          let url = `${this.$shareUrl}#/curriculumDetails?course_id=${this.$route.query.course_id}`
          let picurl = `${this.$url}${this.details.info.cover}`
          var shareqqzonestring = 'http://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?summary=' + this.details.info.name + '&url=' + url + '&pics=' + picurl;
          window.open(shareqqzonestring);
      },
    //   微博
      sharewb(){
          let url = `${this.$shareUrl}#/curriculumDetails?course_id=${this.$route.query.course_id}`
          let picurl = `${this.$url}${this.details.info.cover}`
          var sharesinastring = 'http://v.t.sina.com.cn/share/share.php?title=' + this.details.info.name + '&url=' + url + '&content=utf-8&sourceUrl=' + url + '&pic=' + picurl;
          window.open(sharesinastring);
      },
      buy(){
          this.$router.push({
              path:"/shopMall/confirmOrder",
              query:{
                  goods_id:this.details.info.id,
                  price:this.details.info.price,
                  goods_type:2,
                  single_price:this.details.info.price,
                  goodsInfo:decodeURIComponent(JSON.stringify(this.details.info))
              }
          })
      },
      toVideo(index=0,item){
          if(!this.$store.state.token){
                this.$router.push({
                    path:'/login'
                })
                return
          }
          if(item && item.look_num == 0){
              this.$message.warning('当前视频已无播放次数，请选择其他视频')
              return
          }
          this.$router.push({
              path:"/curriculumVideo",
              query:{
                  goodsInfo:decodeURIComponent(JSON.stringify(this.details.info)),
                  menuList:decodeURIComponent(JSON.stringify(this.menuList)),
                  course_id:this.$route.query.course_id,
                  isBuy:this.details.buy,
                  videoIndex:index
              }
          })
      },
      toPath(path){
          this.$router.push({
              path
          })
      },
      toDetails(item){
          this.$router.push({
              path:'/curriculumDetails',
              query:{
                  course_id:item.id
              }
          })
          this.$router.go(0)
      },
    //   修改收藏
    changeCollect(){
        let that = this;
        let formdata = new FormData();
        //   分类
        formdata.append('uid',that.$store.state.uid);
        formdata.append('token',that.$store.state.token);
        formdata.append('target_id',that.$route.query.course_id);
        // 目标类型 1 课程 2 咨询 3 商品 4 题库
        formdata.append('type',1);
        // 端 1 APP端 2 PC端
        formdata.append('state',2);
        http.collectTarget(formdata).then(value=>{
                if(value.code == 200){
                   this.$message.success('操作成功')
                   this.courseDeatil();
                }else{
                this.$message.error(value.msg)
                }
            })
    },
    //   获取课程详情
    courseDeatil(){
        let that = this;
        let formdata = new FormData();
        //   分类
        formdata.append('uid',that.$store.state.uid);
        formdata.append('token',that.$store.state.token);
        formdata.append('course_id',that.$route.query.course_id);
        formdata.append('type',2);
        http.courseDeatil(formdata).then(value=>{
                if(value.code == 200){
                    this.details=value.data;
                }else{
                this.$message.error(value.msg)
                }
            })
    },
    // 获取课程目录
    courseMenu(){
        let that = this;
        let formdata = new FormData();
        //   分类
        formdata.append('uid',that.$store.state.uid);
        formdata.append('token',that.$store.state.token);
        formdata.append('course_id',that.$route.query.course_id);
        formdata.append('type',2);
        http.courseMenu(formdata).then(value=>{
                if(value.code == 200){
                    this.menuList=value.data;
                }else{
                this.$message.error(value.msg)
                }
            })
    },
    // 获取课程列表
    hotCourses(){
        http.hotCourses().then(value=>{
            if(value.code == 200){
                this.hotClassList = value.data;
            }
        })
    },
    // 获取评论列表
    courseComment(){
        let that = this;
        let formdata = new FormData();
        //   分类
        formdata.append('uid',that.$store.state.uid);
        formdata.append('token',that.$store.state.token);
        formdata.append('course_id',that.$route.query.course_id);
        formdata.append('type',2);
        http.courseComment(formdata).then(value=>{
                if(value.code == 200){
                    this.commentList=value.data;
                }else{
                this.$message.error(value.msg)
                }
            })
    },
    // 点赞
    commentGoods(id){
        let that = this;
        let formdata = new FormData();
        //   分类
        formdata.append('uid',that.$store.state.uid);
        formdata.append('token',that.$store.state.token);
        formdata.append('comment_id',id);
        http.commentGoods(formdata).then(value=>{
                if(value.code == 200){
                    this.$message.success(value.msg)
                    this.courseComment()
                }else{
                this.$message.error(value.msg)
                }
            })
    },
    // 发表评论
    addCourseComment(type = 1){
        let that = this;
        let formdata = new FormData();
        //   分类
        formdata.append('uid',that.$store.state.uid);
        formdata.append('token',that.$store.state.token);
        formdata.append('course_id',that.$route.query.course_id);
        formdata.append('to_user_id',that.commentObj.user_id);
        formdata.append('pid',that.commentObj.id);
        if(type == 2){
            formdata.append('content',that.textarea);
        }else{
            formdata.append('content',that.commentContent);
        }
        
        formdata.append('type',2);
        http.addCourseComment(formdata).then(value=>{
                if(value.code == 200){
                    this.$message.success(value.msg)
                    this.commentObj = {}
                    this.textarea=''
                    this.courseComment()
                }else{
                this.$message.error(value.msg)
                }
            })
    },
  },
  created() {
      this.hotCourses()
  },
  mounted() {
      this.courseDeatil();
      this.courseMenu();
      this.courseComment();
      
  },
  components: {pdf},
}
</script>

<style scoped lang="scss">

.my_container{
        width:1200px;
        margin:0 auto;
        margin-bottom: 80px;
        .my_page_tit{
            padding:30px 0;
            color:#666;
            font-size:12px;
        }
        .curriculum_details{
            padding:30px;
            background:#fff;
            display:flex;
            .curriculum_img{
                width:429px;
                height:240px;
                border-radius: 6px;
                margin-right:34px;
            }
            .curriculum_right{
                font-size:12px;
                flex:1;
                .curriculum_tit{
                    color:#222222;
                    font-size:24px;
                    margin-bottom: 4px;
                }
                .curriculum_introuce{
                    color:rgb(204,204,204);
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                    margin-bottom: 10px;
                }
                .curriculum_price{
                    color:#CF0A00;
                    font-size:30px;
                    margin-bottom: 10px;
                }
                .curriculum_icon{
                    display: flex;
                    margin-bottom: 28px;
                    li{
                        img{
                            width:15px;
                            height:11px;
                            margin-right:5px;
                        }
                        margin-right:64px;
                        color:#333;
                    }
                }
                .curriculum_info{
                    display: flex;
                    justify-content: space-between;
                    .curriculum_btn{
                        background-color: #CF0A00;
                        color:#fff;
                        width:182px;
                        height:50px;
                        text-align: center;
                        border-radius: 5px;
                        line-height:50px;
                        cursor: pointer;
                    }
                    .curriculum_operation{
                        display:flex;
                        div{
                            display:flex;
                            align-items: center;
                            margin-left:28px;
                            cursor: pointer;
                        }
                        img{
                            width:20px;
                            height:20px;
                            margin-left:10px;
                        }
                        .share_box{
                            position: relative;
                            .erweimaImg{
                                position: absolute;
                                left:-27px;
                                top:-90px;
                                width:100px;
                                height:100px;
                                background:red
                            }
                        }
                    }
                }
            }
        }
        .curriculum_content{
            display:flex;
            margin-top:20px;
            .curriculum_left{
                flex:1;
                background-color: #fff;
                padding:0 20px;
                .class_nav{
                    line-height:66px;
                }
                /deep/ .el-tabs__item{
                    color:#666;
                    font-size:18px;
                }   
                /deep/ .el-tabs__item.is-active{
                            color: #333333;
                        }
                        /deep/ .el-tabs__active-bar{
                            background-color: #CE0A00;
                        }
                        /deep/ .el-tabs__item:hover {
                            color: #333333;
                            cursor: pointer;
                        }
            }
            .curriculum_right_column{
                width:260px;
                background-color: #fff;
                padding:20px;
                box-sizing: border-box;
                margin-left:20px;
                height:650px;
                overflow: auto;
                .curriculum_right_title{
                    color:#333333;
                    font-size:18px;
                }
            }
        }
        .class_list{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .class_list_items{
                width:220px;
                border-radius:8px;
                background: #fff;    
                &:hover{
                    box-shadow: 0px 4px 16px 0px rgba(191, 191, 191, 0.79);
                }
                margin-bottom: 20px;
                cursor: pointer;
                display: flex;
                flex-direction: column;
                .class_img{
                    width:220px;
                    height:160px;
                }
                .class_details{
                    padding:20px;
                    padding-bottom: 25px;
                    .class_name{
                        color:#333333;
                        font-size:16px;
                        margin-bottom:10px;
                        word-break:break-all;
                        display:-webkit-box;/**对象作为伸缩盒子模型展示**/
                        -webkit-box-orient:vertical;/**设置或检索伸缩盒子对象的子元素的排列方式**/
                        -webkit-line-clamp:1;/**显示的行数**/
                        overflow:hidden;
                    }
                    .class_teacher{
                        color:#D1A366;
                        font-size:14px;
                        margin-bottom: 14px;
                    }
                    .class_info{
                        display:flex;
                        justify-content: space-between;
                        .class_price{
                            font-family: 'PingFang-SC-Bold';
                            color:#DC091D;
                            font-size:20px;
                            font-weight: bold;
                        }
                        .class_number{
                            display:flex;
                            align-items: center;
                            color:#666666;
                            font-size:14px;
                            img{
                                width:15px;
                                height:17px;
                                margin-right:4px;
                            }
                        }
                    }
                }
            }
        }
        .module_title{
            margin-top:30px;
            padding-left:20px;
            position: relative;
            color:#242424;
            font-size:18px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            span{
                position: absolute;
                width: 6px;
                height: 20px;
                background: #DC091D;
                left:0%;
                top:50%;
                margin-top:-10px;
            }
            .more{
                color:#666666;
                font-size:14px;
            }
        }
        .class_zhangjie{
            display:flex;
            font-size:16px;
            color:#333333;
            align-items: center;
            margin-bottom: 30px;
            span{
                width: 18px;
                height: 18px;
                background: #E1410F;
                border-radius: 50%;
                margin-right:9px;
            }
        }
        .active_row{
            display:flex;
            justify-content: space-between;
            align-items: center;
            .active_btn{
                width: 115px;
                height: 32px;
                background: #E1410F;
                border-radius: 16px;
                text-align: center;
                line-height: 32px;
                font-size: 16px;
                color:#fff;
                cursor: pointer;
            }
            .noactive_btn{
                width: 115px;
                height: 32px;
                background: #CCCCCC;
                border-radius: 16px;
                text-align: center;
                line-height: 32px;
                font-size: 16px;
                color:#fff;
                cursor: pointer;
            }
        }
        .evaluate_list{
            // max-height: 600px;
            overflow: auto;
            .evaluate_list_item{
                padding-top: 15px;
                padding-bottom: 20px;
                display: flex;
                border-bottom: 1px solid #E2E2E2;
                color:#666;
                .evaluate_header{
                    margin-right:16px;
                    width:55px;
                    height:55px;
                    border-radius: 50%;
                }
                .evaluate_details{
                    flex:1;
                    .evaluate_name{
                        margin-bottom: 14px;
                    }
                    .evaluate_ontent{
                        margin-bottom: 16px;
                    }
                    .evaluate_info{
                        display:flex;
                        justify-content: space-between;
                        .evaluate_icon{
                            display: flex;
                            align-items: center;
                            img{
                                width:14px;
                                height:14px;
                                cursor: pointer;
                            }
                            span{
                                margin:0 7px
                            }
                        }
                    }
                }
            }
            .evaluate_list_children{
                padding-left:70px;
                .evaluate_list_children_item{
                    padding-top: 15px;
                    padding-bottom: 20px;
                    display: flex;
                    border-bottom: 1px solid #E2E2E2;
                    color:#666;
                    .evaluate_header{
                        margin-right:16px;
                        width:55px;
                        height:55px;
                        border-radius: 50%;
                    }
                    .evaluate_details{
                    flex:1;
                    .evaluate_name{
                        margin-bottom: 14px;
                    }
                    .evaluate_ontent{
                        margin-bottom: 16px;
                    }
                    .evaluate_info{
                        display:flex;
                        justify-content: space-between;
                    }
                }
                }
            }
        }
        .evaluate_input{
            height: 119px;
            border: 1px solid #CCCCCC;
            textarea{
                height: 100%;
                width: 100%;
                border:none;
                background-color: transparent;
                resize:none;
            }
        }
        .evaluate_operation{
            display:flex;
            justify-content: space-between;
            margin-top:15px;
            .evaluate_submit{
                width: 80px;
                height: 30px;
                background: #2D81E4;
                border-radius: 8px;
                text-align: center;
                line-height: 30px;
                color:#fff;
                font-size: 14px;
            }
        }
        .evaluate_gaishu{
            font-size:14px;
            color:#666666;
            margin-top:19px;
            margin-bottom: 30px;
        }
        .evaluate_tags{
            display: flex;
            margin-top: 20px;
            span{
                background: #F2F4F5;
                padding:10px 20px;
                color:#666666;
                font-size:14px;
                margin-right:10px;
                border-radius: 8px;
            }
        }
        .evaluate_teacher_info{
            display:flex;
            align-items: center;
            margin-top: 30px;
            img{
                width:80px;
                height: 80px;
                border-radius: 50%;
                margin-right:24px;
            }
            .evaluate_teacher_data{
                display: flex;
                justify-content: center;
                flex-direction: column;
                span{
                    margin-bottom: 5px;
                }
            }
            .evaluate_teacher_page{
                margin-left:auto;
                background:#EE4433;
                width:126px;
                line-height:40px;
                height: 40px;
                background: #EE4433;
                border-radius: 9px;
                color:#fff;
                text-align: center;
                font-size:12px;
                cursor: pointer;
            }
        }
}
</style>