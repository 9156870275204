<template>
 <div class="appDown">
     <div class="appDown_container">
         <img class="phone" src="../../assets/image/下载.png" alt="">
        <div class="tips">
            <p>专业品质 业内典范知识服务平台</p>
            <p class="kouhao">一心一意法硕学生服务</p>
        </div>
        <div class="down_con">
            <div>
                <img src="../../assets/image/ios下载.png" alt="">
                <img src="../../assets/image/安卓下载.png" alt="">
            </div>
            <img class="down_erweima" :src="systemInfo.app_download | fixImg" alt="">
        </div>
     </div>
     
 </div>
</template>

<script>
import * as http from '@/assets/api/http.js'
 export default {
  name: '',
  data () {
   return {
       erweima:'',
       systemInfo:{}
   }
  },
  created(){
      this.getSysConfig()
  },
  methods:{
      // 系统设置信息
    getSysConfig(){
        http.getSysConfig().then(value=>{
            if(value.code == 200){
                this.systemInfo = value.data;
            }
        })
    },
  },
  components: {

  }
 }
</script>

<style scoped lang="scss">
.appDown{
    background:url('../../assets/image/个人中心.png') no-repeat left top;
    height:980px;
    position: relative;
    .appDown_container{
        width:1200px;
        margin:0 auto;
        position: relative;
    }
    .phone{
        position: absolute;
        right:62px;
        top:120px;
        width:345px;
        height:600px;
    }
    .tips{
        color:#fff;
        position: absolute;
        top:224px;
        left:0;
        font-weight: bold;
        font-size:44px;
        .kouhao{
            font-size:30px;
            margin-top:20px;
        }
    }
    .down_con{
        position: absolute;
        top:450px;
        left:0;
        display:flex;
        div{
            height: 210px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            // align-items: space;
            img{
                width:311px;
                height:92px;
            }
        }
        .down_erweima{
            width:210px;
            height:210px;
            margin-left:58px;
        }
    }
}
 
</style>
