import http from '../../utils/http.js'
import store from '../../store'
// 推荐资讯
export const recoNews = ()=>{
    store.commit('changeLoading',true)
    return http.request('recoNews').then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 课程列表
export const hotCourses= ()=>{
    store.commit('changeLoading',true)
    return http.request('hotCourses').then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 教室列表
export const recoTeachers= ()=>{
    store.commit('changeLoading',true)
    return http.request('recoTeachers').then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 轮播图列表
export const bannerList= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('bannerList',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 验证码
export const sendVerify= (params={})=>{
    return http.request('sendVerify',params).then(value=>{
        return value.data
    })
}
// 注册
export const register= (params={})=>{
    return http.request('register',params).then(value=>{
        return value.data
    })
}
// 登录
export const login= (params={})=>{
    return http.request('login',params).then(value=>{
        return value.data
    })
}
// 忘记密码
export const forgetPwd= (params={})=>{
    return http.request('forgetPwd',params).then(value=>{
        return value.data
    })
}
// 友情链接
export const friendLink= ()=>{
    store.commit('changeLoading',true)
    return http.request('friendLink').then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 获取系统设置信息
export const getSysConfig= ()=>{
    store.commit('changeLoading',true)
    return http.request('getSysConfig').then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 获取个人信息
export const getUdetail= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('getUdetail',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 系统消息列表
export const msgList= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('catemsgListPc',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 文件上传
export const upload= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('upload',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 修改个人资料
export const editUdetail= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('editUdetail',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 获取余额明细
export const getBalanceDetail= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('getBalanceDetail',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 获取订单列表
export const orderList= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('orderList',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 获取课程年份
export const courseYear= ()=>{
    store.commit('changeLoading',true)
    return http.request('courseYear').then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 获取课程班次
export const courseClasses= ()=>{
    store.commit('changeLoading',true)
    return http.request('courseClassesTree').then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 获取课程分类
export const courseCase= ()=>{
    store.commit('changeLoading',true)
    return http.request('courseCase').then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 获取教师列表
export const teacherList= ()=>{
    store.commit('changeLoading',true)
    return http.request('teacherList').then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 课程列表
export const courseList= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('courseList',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 课程详情
export const courseDeatil= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('courseDeatil',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 修改收藏
export const collectTarget= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('collectTarget',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 课程目录
export const courseMenu= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('courseMenu',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 课程评论列表
export const courseComment= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('courseComment',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 商城评论列表
export const goodsComments= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('goodsComments',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 发表评论
export const addCourseComment= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('addCourseComment',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 发表商品评论
export const addGoodsComment= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('addGoodsComment',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 教师分页列表
export const teacherListPage= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('teacherListPage',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 获取教师详情
export const teacherDetail= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('teacherDetail',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 获取我的课程
export const myCourse= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('myCourse',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 获取我的支付码
export const paycodeList= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('paycodeList',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 生成订单   
export const addOrder= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('addOrder',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
} 
// 支付码支付   
export const codePay= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('codePay',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
} 
// 余额支付
export const balancePay= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('balancePay',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 微信支付
export const wechatpayPc= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('wechatpayPc',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 获取单篇文章信息
export const getSingleArticle= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('getSingleArticle',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 点赞
export const commentGoods= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('commentGoods',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 取消订单  
export const cancelOrder= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('cancelOrder',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 资讯详情页   
export const newsDetail= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('newsDetail',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 添加学习记录 Copy
export const studyRecord= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('studyRecord',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 弹幕列表
export const screen= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('screen',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
} 
// 获取订单状态   
export const getWechatpayResult= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('getWechatpayResult',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
} 
// 支付宝支付  
export const alipayPc= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('alipayPc',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 题库首页分类
export const exercisesCategory= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('exercisesCategory',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 获取年份列表
export const exercisesYearList= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('exercisesYearList',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 获取学科列表
export const exercisesSubjectList= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('exercisesSubjectList',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 随机组题数量统计
export const randomCount= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('randomCount',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 随机组题
export const randomList= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('randomList',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 学科做题列表
export const exercisesChapterList= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('exercisesChapterList',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 学科做题数量
export const exercisesChapterNum= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('exercisesChapterNum',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 添加用户答题
export const userAnswer= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('userAnswer',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 我的错题列表
export const errorExercisesList= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('errorExercisesList',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 错题习题表
export const errorSubjectExercisesList= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('errorSubjectExercisesList',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 背题列表
export const reciteListPc= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('reciteListPc',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 我的背题列表
export const myRecitePc= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('myRecitePc',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 添加背题
export const addRecite= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('addRecite',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 统计
export const exercisesStatistics= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('exercisesStatistics',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 高频考题列表
export const highTestExercises= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('highTestExercises',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 高频错题列表
export const highWrongExercises= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('highWrongExercises',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 高频错题学科习题列表
export const highWrongExercisesList= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('highWrongExercisesList',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 高频考点学科习题列表
export const highTestExercisesList= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('highTestExercisesList',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 高频统计
export const highExercisesWrongExercises= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('highExercisesWrongExercises',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 模拟考场列表
export const simulationList= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('simulationList',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 模拟考场提交
export const simulationSubmit= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('simulationSubmit',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 法律法规
export const statuteList= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('statuteList',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 资讯列表
export const newsList= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('newsList',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 题目收藏 列表
export const exercisesCollectsList= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('exercisesCollectsList',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 收藏章节数据列表
export const exercisesCollectsChapterList= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('exercisesCollectsChapterList',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 提问列表
export const questionList= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('questionList',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 发布提问
export const addQuestion= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('addQuestion',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 我的提问  
export const myQuestion= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('myQuestion',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 重做  
export const userExercisesRedo= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('userExercisesRedo',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 评论回复列表
export const replyList= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('replyList',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 点赞/取消
export const questionGoods= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('questionGoods',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 添加题目评价  
export const addExercisesEvaluate= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('addExercisesEvaluate',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 收藏题目 
export const exercisesCollects= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('exercisesCollects',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 题目评价列表 
export const exercisesEvaluateList= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('exercisesEvaluateList',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 年份做题列表
export const exercisesCourseTypeList= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('exercisesCourseTypeList',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 年份做题列表
export const exercisesCourseTypeNum= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('exercisesCourseTypeNum',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 商品分类
export const goodsCateList= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('goodsCateList',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 商品列表
export const goodsList= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('goodsList',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 商品详情
export const goodsInfo= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('goodsInfo',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 商品收藏/取消收藏
export const goodsCollect= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('goodsCollect',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 生成商城订单
export const addGoodsOrder= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('addGoodsOrder',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 收货地址列表
export const addressList= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('addressList',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 添加收货地址
export const addMyAddress= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('addMyAddress',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 删除收货地址
export const delUserAddress= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('delUserAddress',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 物流信息查询
export const expressQuery= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('expressQuery',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 商品分类列表
export const goodsCollectList= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('goodsCollectList',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 我的考试
// export const expressQuery= (params={})=>{
//     store.commit('changeLoading',true)
//     return http.request('expressQuery',params).then(value=>{
//         store.commit('changeLoading',false)
//         return value.data
//     })
// }
// 资讯收藏列表
export const newCollectsList= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('newCollectsList',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 咨询收藏
export const newCollects= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('newCollects',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 咨询评价添加
export const addNewEvaluate= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('addNewEvaluate',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 资讯评论列表
export const newEvaluateList= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('newEvaluateList',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 我的考试记录
export const simulationUserList= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('simulationUserList',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 删除考试纪律 
export const delExamLog= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('delExamLog',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 题库搜索
export const indexSearch= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('indexSearch',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}
// 确认收货
export const confirmReceipt= (params={})=>{
    store.commit('changeLoading',true)
    return http.request('confirmReceipt',params).then(value=>{
        store.commit('changeLoading',false)
        return value.data
    })
}