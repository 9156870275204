<template>
  <div class="my_order">
      <h3 class="class_title">我的课程</h3>
      <div v-if="dataList.length">
            <classList
                :dataList="dataList"
            ></classList>
            <pagition 
                :count="count"
                @currentChange='currentChange'
            >
            </pagition>
      </div>
      <div v-if="!dataList.length" class="nodata">
          暂无数据
      </div>
  </div>
</template>

<script>
import classList from '@/components/classList/classList'
import * as http from '@/assets/api/http.js'
export default {
  data() {
    return {
        dataList:[],
        count:0,
        page:1
    };
  },
  methods: {
      currentChange(val){
          this.page=val;
          this.myCourse()
      },
      // 获取课程列表
    myCourse(){
        let that = this;
        let formdata = new FormData();
        //   分类
        formdata.append('uid',that.$store.state.uid);
        formdata.append('token',that.$store.state.token);
        formdata.append('type',1);
        formdata.append('p',that.page);
        formdata.append('state',2);
        http.myCourse(formdata).then(value=>{
            if(value.code == 200){
                this.dataList = value.data;
            }
        })
    },
  },
//   created() {

//   },
  mounted() {
      this.myCourse()
  },
  components: {classList},
}
</script>

<style lang="scss" scoped>
.my_order{
    padding:40px 30px;
    .class_title{
        color:#666666;
        font-size:24px;
        margin-bottom: 20px;
    }
    .class_list{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .class_list_items{
            width:280px;
            border-radius:8px;
            background: #fff;    
            box-shadow: 0px 4px 16px 0px rgba(191, 191, 191, 0.79);
            margin-bottom: 20px;
            .class_img{
                width:280px;
                height:160px;
            }
            .class_details{
                padding:20px;
                padding-bottom: 25px;
                .class_name{
                    color:#333333;
                    font-size:18px;
                    margin-bottom:10px;
                }
                .class_teacher{
                    color:#D1A366;
                    font-size:14px;
                    margin-bottom: 14px;
                }
                .class_info{
                    display:flex;
                    justify-content: space-between;
                    .class_price{
                        font-family: 'PingFang-SC-Bold';
                        color:#DC091D;
                        font-size:20px;
                        font-weight: bold;
                    }
                    .class_number{
                        display:flex;
                        align-items: center;
                        color:#666666;
                        font-size:14px;
                        img{
                            width:15px;
                            height:17px;
                            margin-right:4px;
                        }
                    }
                }
            }
        }
    }
}
.nodata{
    width:100%;
    height:370px;
    justify-content: center;
    display: flex;
    align-items: center;
}
</style>