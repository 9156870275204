<template>
  <div class="my_order">
      <h3 class="class_title">我的钱包</h3>
      <div class="wallet_yue">
            <p class="my_yue">我的余额（元）</p>
            <div class="wallet_market">
                <span class="wallet_money">{{personInfo.balance}}</span>
                <span class="wallet_btn" @click="chongzhi">充值</span>
            </div>
            <div class="income_details">
                收支明细
            </div>
            <el-table
            :data="tableData"
            :header-cell-style="{background: '#DCDCDC',color:'#333',fontSize:'16px',opacity:0.8}"
            style="width: 100%">
                <el-table-column
                    prop="type"
                    label="类型"
                    >
                    <template slot-scope="scope">
                        <span style="line-height:50px;" v-if="scope.row.type == 1">支出</span>
                        <span style="line-height:50px;" v-if="scope.row.type == 2">收入</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="type"
                    label="名称"
                    >
                     <template slot-scope="scope">
                        <span style="line-height:50px;">{{scope.row.content}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="type"
                    label="金额"
                    >
                     <template slot-scope="scope">
                        <span style="line-height:50px;color:#E1410F" v-if="scope.row.type == 1">-{{scope.row.balance}}</span>
                        <span style="line-height:50px;color:#2E8C2E" v-if="scope.row.type == 2">+{{scope.row.balance}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="type"
                    label="日期"
                    >
                     <template slot-scope="scope">
                        <span style="line-height:50px;">{{timestampToTime(scope.row.created_at*1000)}}</span>
                    </template>
                </el-table-column>
            </el-table>
      </div>
      <!-- 充值弹窗 -->
      <el-dialog
        title="请输入充值金额"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose">
        <el-input type="number" v-model="money" placeholder="请输入充值金额"></el-input>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="confirm">确 定</el-button>
        </span>
        </el-dialog>
  </div>
</template>

<script>
import * as http from '@/assets/api/http.js'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
        tableData: [],
        dialogVisible:false,
        money:''
    };
  },
  computed:{
    ...mapState(['personInfo'])
  },
  methods: {
      ...mapMutations(['savePersonInfo']),
      handleClose(){
          this.dialogVisible = false;
      },
      confirm(){
        this.dialogVisible = false;
        this.addOrder()
      },
      // 获取余额明细
    getBalanceDetail(){
      let that = this;
      let formdata = new FormData();
      formdata.append('uid',that.$store.state.uid);
      formdata.append('token',that.$store.state.token);
      formdata.append('type',2);
      http.getBalanceDetail(formdata).then(value=>{
            if(value.code == 200){
              console.log(value)
              this.tableData=value.data
            }else{
              this.$message.error(value.msg)
            }
        })
    },
    // 获取个人信息
    getUdetail(){
      let that = this;
      let formdata = new FormData();
      formdata.append('uid',that.$store.state.uid);
      formdata.append('token',that.$store.state.token);
      // 端 1 APP 2 PC
      formdata.append('type',2);
      http.getUdetail(formdata).then(value=>{
            if(value.code == 200){
              this.savePersonInfo(value.data)
            }else{
              this.$message.error(value.msg)
            }
        })
    },
    // 生成订单
    addOrder(){
        this.$router.push(
                  {
                      path:'/shopMall/confrimwalletOrder',
                      query:{
                          num:this.money
                      }
                  }
              )
              return
      let that = this;
      let formdata = new FormData();
      formdata.append('uid',that.$store.state.uid);
      formdata.append('token',that.$store.state.token);
      formdata.append('price',that.money);
      formdata.append('goods_type',3);
      formdata.append('type',2);
      formdata.append('single_price',that.money);
      http.addOrder(formdata).then(value=>{
            if(value.code == 200){
              console.log(value)
              this.$router.push(
                  {
                      path:'/confirmOrder',
                      query:{
                          pageType:3,
                          num:that.money
                      }
                  }
              )
            }else{
              this.$message.error(value.msg)
            }
        })
    },
    // 充值
    chongzhi(){
        this.dialogVisible = true;
    }
  },
  created() {
      this.getBalanceDetail();
      this.getUdetail()
  },
//   mounted() {

//   },
  components: {},
}
</script>

<style lang="scss" scoped>
.my_order{
    padding:40px 30px;
    .class_title{
        color:#666666;
        font-size:24px;
        padding-bottom: 20px;
        border-bottom:1px solid #ECECEC
    }
    .wallet_yue{
        margin-top:20px;
        .my_yue{
            color:#666666;
            font-size:14px;
        }
        .wallet_market{
            margin-top: 8px;
            line-height: 36px;
            display: flex;
            .wallet_money{
                font-size: 28px;
                font-weight: 400;
                color: #CF0A00;
                margin-right:75px;
            }
            .wallet_btn{
                width: 78px;
                height: 36px;
                background: #E1410F;
                border-radius: 11px;
                font-size:16px;
                color: #fff;
                text-align: center;
            }
        }
        .income_details{
            margin-top:65px;
            color:#666666;
            font-size:16px;
            padding-bottom: 20px;
            border-bottom:1px solid #ECECEC
        }
    }
}
</style>