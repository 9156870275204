<template>
  <div class="my_order">
    <div class="order_serach">
                <el-select v-model="type" placeholder="请选择" size="mini"
                @change="change">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
    </div>
      <el-table
            :data="tableData"
            :header-cell-style="{background: '#DCDCDC',color:'#333',fontSize:'16px',opacity:0.8}"
            style="width: 100%;margin-bottom:20px">
                <el-table-column
                    prop="type_name"
                    label="分类"
                    >
                    <template slot-scope="scope">
                        <span style="line-height:40px;" v-if="scope.row.type == 1">系统通知</span>
                        <span style="line-height:40px;" v-if="scope.row.type == 2">订单信息</span>
                        <span style="line-height:40px;" v-if="scope.row.type == 3">互动消息</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="content"
                    label="内容"
                    width="400"
                    show-overflow-tooltip
                    >
                     <template slot-scope="scope">
                        <span class="message_content" style="line-height:40px;">{{scope.row.content}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="type"
                    label="时间"
                    >
                     <template slot-scope="scope">
                        <span style="line-height:40px;">{{timestampToTime(scope.row.created_at*1000)}}</span>
                    </template>
                </el-table-column>
            </el-table>
            <pagition 
                :count="count"
                @currentChange='currentChange'
            >
            </pagition>
  </div>
</template>

<script>
import * as http from '@/assets/api/http.js'
export default {
  data() {
    return {
        tableData: [],
        count:0,
        page:1,
        type:'1',
        options:[
          {
            value: '1',
            label: '系统通知'
          },
          {
            value: '2',
            label: '订单信息'
          },
          {
            value: '3',
            label: '互动消息'
          },
        ] 
    };
  },
  methods: {
    change(){
      this.msgList()
    },
    currentChange(val){
          this.page = val;
          this.msgList();
      },
    //   获取系统消息
    msgList(){
      let that = this;
      let formdata = new FormData();
      formdata.append('uid',that.$store.state.uid);
      formdata.append('token',that.$store.state.token);
      formdata.append('p',that.page);
      formdata.append('type',that.type);
      http.msgList(formdata).then(value=>{
            if(value.code == 200){
              this.tableData = value.data;
            }else{
              this.$message.error(value.msg)
            }
        })
    },
  },
  created() {
      this.msgList()
  },
//   mounted() {

//   },
  components: {},
}
</script>

<style lang="scss" scoped>
.my_order{
    padding:40px 30px;
    /deep/ .el-table td, .el-table th{
        padding:0
    }
    .message_content{
        color:#333333;
        line-height:1;
        font-size:14px;
        // overflow : hidden;
        // text-overflow: ellipsis;
        // display: -webkit-box;
        // -webkit-line-clamp: 2;
        // -webkit-box-orient: vertical;
    }
    .order_serach{
                color:#999999;
                display: flex;
                margin-bottom: 20px;
                input{
                    flex:1;
                    line-height: 36px;
                    background: transparent;
                    color:#999999;
                    border:none;
                }
            }
}
</style>