import { render, staticRenderFns } from "./myAddress.vue?vue&type=template&id=28ee93f6&scoped=true&"
import script from "./myAddress.vue?vue&type=script&lang=js&"
export * from "./myAddress.vue?vue&type=script&lang=js&"
import style0 from "./myAddress.vue?vue&type=style&index=0&id=28ee93f6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28ee93f6",
  null
  
)

export default component.exports