<template>
 <div class="my_container">
        <p class="my_page_tit">
          <router-link to="/index">首页</router-link> > <router-link to="/questionBank">题库</router-link> > 查看错题
      </p>
    <QuestionLayout
    v-if="show"
    v-loading="loading"
     ref="QuestionLayout"
        :questionNumber="questionNumber"
        :hasSubmit="true"
        :islook="islook"
        @submit="userAnswer"
        model="pratice"
    >
        <template v-slot:title>
            <div class="titCol" >
                    <span>答题</span>   
                    <span class="submitpaper" @click="chongzuo">重做</span>    
            </div>
        </template>
    </QuestionLayout>
 </div>
</template>

<script>
import * as http from '@/assets/api/http.js'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import QuestionLayout from '@/components/questionLayoutRecord/questionLayoutRecord.vue'
 export default {
  name: '',
  data () {
   return {
       questionNumber:[],
       islook:false,
       show:true,
       loading:false
   }
  },
  created(){
      this.errorSubjectExercisesList()
      
  },
  computed:{
    ...mapState(['cate_id'])
  },
  methods:{
    //   随机题组(当前保存做题记)
    errorSubjectExercisesList(){
        this.loading = true
        let params = {
            subject_id:this.$route.query.subject_id,
            menu_id:this.$route.query.menu_id,
            cate_id:this.cate_id
        }
      http.errorSubjectExercisesList(params).then(value=>{
            if(value.code == 200){
                this.loading = false
                this.questionNumber = value.data;
                this.questionNumber.forEach(v=>{
                    if(v.type !=3){
                        // 记录当前正确答案
                        this.$set(v,'correct_answer_arr',this.answer_change(v.correct_answer))
                        // 记录当前回答记录
                    
                        // 对已经答题的题打上查看标记
                        if(v.user_answer_status != 0){
                            this.$set(v,'islook',true)
                            this.$set(v,'answerSelect',this.answer_change(v.user_answer))
                        }else{
                            this.$set(v,'islook',false)
                            this.$set(v,'answerSelect',[])
                        }
                    }else{
                        if(v.user_answer_status != 0){
                            this.$set(v,'islook',true)
                            this.$set(v,'answerSelect',v.user_answer)
                        }else{
                            this.$set(v,'islook',false)
                            this.$set(v,'answerSelect','')
                        }
                    }
                    
                })
            }
        })
    },
    chongzuo(){
          this.$confirm('确定重做本章所有章节吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
            console.log(this.questionNumber)
            this.show = !this.show 
            setTimeout(()=>{
                this.show = !this.show 
            },300)
            let arr = [];
            this.questionNumber.forEach(v=>{
                arr.push(v.id)
            });
            this.userExercisesRedo(arr.join(','))
        }).catch((err) => {
            console.log(err)
          this.$message({
            type: 'info',
            message: '已取消'
          });          
        });
      },
    // A->1,B->2....
    answer_change(value){
        let arr =   value.split(',');
        arr.forEach((v,index)=>{
            if(v == 'A'){
                arr.splice(index,1,1)
            }
            if(v == 'B'){
                arr.splice(index,1,2)
            }
            if(v == 'C'){
                arr.splice(index,1,3)
            }
            if(v == 'D'){
                arr.splice(index,1,4)
            }
        })
        arr.sort()
        return arr
    },
    // 1->A,2->B
    answer_change2(value){
        let arr =  value;
        arr.forEach((v,index)=>{
            if(v == '1'){
                arr.splice(index,1,'A')
            }
            if(v == '2'){
                arr.splice(index,1,'B')
            }
            if(v == '3'){
                arr.splice(index,1,'C')
            }
            if(v == '4'){
                arr.splice(index,1,'D')
            }
        })
        return value.join(',')
    },
    // 添加用户答题
    userAnswer(e){
        let params = {
            exercises_id:e.id,
            answer:e.type == 3?e.answerSelect:this.answer_change2(e.answerSelect)
        }
      http.userAnswer(params).then(value=>{
            if(value.code == 200){
                this.errorSubjectExercisesList()
            }
        })
    },
    // 重做
     userExercisesRedo(exercises_ids ){
        let params = {
            exercises_ids
        }
      http.userExercisesRedo(params).then(value=>{
            if(value.code == 200){
                this.errorSubjectExercisesList()
            }
        })
    },
  },
  destroyed(){
  },
  components: {
      QuestionLayout
  }
 }
</script>

<style scoped lang="scss">
.my_container{
        width:1200px;
        margin:0 auto;
        margin-bottom: 80px;
        .my_page_tit{
            padding:30px 0;
            color:#666;
            font-size:12px;
            padding-bottom: 0;
        }
}
.titCol{
    display:flex;
    justify-content: space-between;
    align-items: center;
}
.subAnswer{
                width: 80px;
                height: 30px;
                background: #DC091D;
                border-radius: 8px;
                color:#fff;
                font-size:14px;
                text-align: center;
                line-height: 30px;
                margin:0 20px;
                cursor: pointer;
                // opacity: 0.1;
            }
.tips_title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img{
        width:80px;
        height: 80px;
        margin-bottom: 20px;
    }
}
.tips_content{
    margin-top:15px;
    font-size:16px;
    color:#5F6E78;
    text-align: center;
    line-height:1.5
}
.tips_btn{
    width:162px;
    height:52px;
    line-height:52px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 16px;
    margin-right:10px;
    font-size:16px;
    cursor: pointer;
}
.tips_btn_submit{
    border: 1px solid #DC091D;
    color:#fff;
    background:#DC091D;
}
.dialog-footer{
    display:flex;
    justify-content: center;
    margin-top:35px;
}
/deep/ .el-dialog__header{
    display:none;
}
.submitpaper{
    background: #F3F8FE;
    border-radius: 8px;
    width: 45px;
    height: 24px;
    line-height:24px;
    color:#2D81E4;
    font-size:14px;
    text-align: center;
    margin-right:20px;
    cursor: pointer;
}
</style>
