<template>
  <ul class="teacher_list">
            <li class="teacher_list_item" v-for="(item,index) in dataList" :key="index" @click="teacherDetails(item)">
                <img :src="item.uhead | fixImg" alt="">
                <div class="teacher_ame">{{item.name}}</div>
                <div class="teacher_position">{{item.label}}</div>
            </li>
            <li  class="teacher_list_item" v-for="(item,index) in 3" :key="`${index}2`" style="background:transparent;height:0;margin-bottom:0"></li>
        </ul>
</template>

<script>
export default {
  data() {
    return {

    };
  },
  props:{
      dataList:{
          default(){
              return []
          }
      }
  },
  methods: {
      teacherDetails(item){
          this.$router.push({
              path:'/teacherDetails',
              query:{
                  teacher_id:item.id
              }
          })
      }
  },
//   created() {

//   },
//   mounted() {

//   },
  components: {},
}
</script>

<style scoped lang="scss">
.teacher_list{
        display:flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .teacher_list_item{
            width:278px;
            margin-bottom: 30px;
            cursor: pointer;
            position: relative;
            background:#fff;
            &:hover{
                border-radius:20px 20px 8px 8px;
                box-shadow: 0px 4px 16px 0px rgba(191, 191, 191, 0.79);
            }   
            img{
                width:100%;
                height:278px;
            }
            .teacher_ame{
                padding-left:20px;
                margin-top:20px;
                font-size:24px;
                color:#242424;
            }
            .teacher_position{
                padding-left:20px;
                padding-bottom: 10px;
                margin-top:20px;
                font-size:16px;
                color:#787878;
            }
        }
    }
</style>