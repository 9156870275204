<template >
 <div v-loading="loading">
     <ul >
         <li class="ques_list" v-for="(item,index) in quesList" :key="index">
             <el-card v-if="item.type !=3" style="cursor: pointer;" >
                 <div @click="toDetails(item)">
                     <p>{{item.name}}</p>
                    <p style="font-size:14px;color:#999" v-for="(ans_item,ans_index) in item.answer" :key="`${ans_index}g`">
                        {{ans_item.identity}}.{{ans_item.name}}
                    </p>
                 </div>
                 
             </el-card>
             <el-card v-if="item.type ==3" style="cursor: pointer;" >
                 <p @click="toDetails(item)">{{item.name}}</p>
             </el-card>
         </li>
     </ul>
 </div>
</template>

<script>
import * as http from '@/assets/api/http.js'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import QuestionLayout from '@/components/questionLayoutRecord/questionLayoutRecord.vue'
 export default {
  name: '',
  data () {
   return {
       quesList:[],
       loading:true
   }
  },
  methods:{
      indexSearch(){
        let params = {
            exercises_name:this.$route.query.keyword
        }
      http.indexSearch(params).then(value=>{
            if(value.code == 200){
                this.quesList = value.data;
                this.loading = false;
            }
        })
    },
    toDetails(item){
        console.log(item,'ii')
        this.$router.push({
            path:'/quessearch',
            query:{
                data:JSON.stringify(item)
            }
        })
    }
  },
  created(){
      this.indexSearch()
  },
  components: {

  },
  watch:{
    "$route":{
        handler(value){
        console.log(value,'aaa');
        this.indexSearch()
      }
    }
  }
 }
</script>

<style scoped lang="scss">

 .ques_list{
     background:#fafafa;
     margin-bottom: 10px;
 }
</style>
