<template>
 <div  class="my_container">
     <p class="my_page_tit">
          <router-link to="/index">首页</router-link> >  <router-link to="/questionBank">题库 </router-link>> 
         {{cate_id== 9?'法学':'非法学'}} >{{$route.meta.searchBread?$route.meta.searchBread:menuList[leftIndex]?menuList[leftIndex].name:''}}
      </p>
      <!-- 内容 -->
      <div class="question_container">
          <!-- left -->
          <div class="question_content">
              <router-view></router-view>
          </div>
          <!-- right -->
          <div class="question_menu">
              <ul class="question_menu_list">
                  <li :class="['question_menu_list_item',{'active':index == leftIndex}]" v-for="(item,index) in menuList" :key="index" @click="toPath(item.link,index)">
                      <img :src="item.img" alt="">
                      <span>{{item.name}}</span>
                  </li>
              </ul>
          </div>
      </div>
 </div>
</template>

<script>
import * as http from '@/assets/api/http.js'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
 export default {
  name: '',
  data () {
   return {
       leftIndex:null,
        menuList:[
            {
                img:require('../../assets/image/type1.png'),
                name:'错题',
                link:'/wrongQuestion'
            },
            {
                img:require('../../assets/image/type2.png'),
                name:'背诵',
                link:'/reciteQues'
            },
            {
                img:require('../../assets/image/type3.png'),
                name:'收藏',
                link:'/collect'
            },
            {
                img:require('../../assets/image/type4.png'),
                name:'统计',
                link:'/statistics'
            },
            {
                img:require('../../assets/image/type5.png'),
                name:'答疑',
                link:'/answeruestion'
            },
            {
                img:require('../../assets/image/type6.png'),
                name:'高频考点',
                link:'/testPoint'
            },{
                img:require('../../assets/image/type7.png'),
                name:'随机组题',
                link:'/randomQusetion'
            },
            {
                img:require('../../assets/image/type8.png'),
                name:'模拟考场',
                link:'/imitateTest'
            },{
                img:require('../../assets/image/type9.png'),
                name:'法律法规',
                 link:'/laws'
            },
            {
                img:require('../../assets/image/type10.png'),
                name:'考试资讯',
                link:'/newsList'
            },

        ],

   }
  },
  created(){
      this.menuList.forEach((v,index)=>{
          if(v.link == this.$route.path){
              this.leftIndex = index;
          }
      })
  },
  computed:{
    ...mapState(['cate_id'])
  },
  methods:{
      toPath(path,index){
          this.leftIndex = index;
          this.$router.push({
              path,
              query:{
                  cate_id:this.cate_id
              }
          })
      },
      // 获取题库分类
    exercisesCategory(){
     return http.exercisesCategory().then(value=>{
            if(value.code == 200){
                this.typeList = value.data;
                Promise.resolve()
            }
        })
    },
  },
  components: {

  }
 }
</script>

<style scoped lang="scss">
.my_container{
        width:1200px;
        margin:0 auto;
        margin-bottom: 80px;
        .my_page_tit{
            padding:30px 0;
            color:#666;
            font-size:12px;
            padding-bottom: 0;
        }
}
 .question_container{
     display:flex;
     margin-top:30px;
    .question_content{
        padding:30px 20px;
        width:920px;
        box-sizing: border-box;
        background:#fff;
        
    }
    .question_menu{
        margin-left:20px;
        flex:1;
        background:#fff;
        padding:35px 20px;
        height: 588px;
        box-sizing: border-box;
        .question_menu_list{
            display:flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .question_menu_list_item{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                color:#2C3846;
                font-size:14px;
                // margin-right:20px;
                margin-bottom: 35px;
                cursor: pointer;
                width: 50%;
                img{
                    width:50px;
                    height:50px;
                    margin-bottom: 10px;
                    border-radius: 50%;
                }
                &:nth-child(2n){
                    // margin-left: 20px;
                    // margin-right:0;
                }
            }
            .active{
                background:#dddee0f0;
            }
        }
    }
 }
</style>
