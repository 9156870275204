<template>
  <div class="my_order">
      <div class="order_tit">我的订单</div>
      <div class="order_nav">
              <el-tabs v-model="status" @tab-click="handleClick">
                <el-tab-pane label="全部" name="0"></el-tab-pane>
                <el-tab-pane label="待付款" name="1"></el-tab-pane>
                <el-tab-pane label="待发货" name="4"></el-tab-pane>
                <el-tab-pane label="待收货" name="2"></el-tab-pane>
                <el-tab-pane label="已完成" name="3"></el-tab-pane>
            </el-tabs>
            <!-- <div class="order_serach">
                <input type="text" placeholder="输入商品名称、订单号">
            </div> -->
            <!-- 订单列表 -->
            <ul class="order_list" v-loading="loading">
                <li class="order_list_item" v-for="(item,index) in tableData" :key="index" >
                    <div class="order_title">
                        <span style="margin-right:15px">{{timestampToTime(item.created_at*1000)}}</span>
                        <span> 订单号：
                            <span class='order_number'>{{item.order_sn}}</span>
                        </span>
                    </div>
                    <div class="order_details" @click="toLook(item)">
                        <img :src="item.cover | fixImg" alt="" @click.stop="toDetails(item)">
                        <div class="order_goods">
                            <span>{{item.name}}</span>
                            <!-- <span>{{item.remark}}</span> -->
                            <span class="goods_guige" v-if="item.add_group == 1 && item.group_state == 1">拼团成功</span>
                            <span class="goods_guige" v-if="item.add_group == 1 && item.group_state == -1">拼团失败</span>
                            <span class="goods_guige" v-if="item.add_group == 1 && item.group_state == 0">拼团中</span>
                        </div>
                        <div class='order_num'>x{{item.goods_num}}</div>
                        <div class='order_price'>¥{{item.single_price}}</div>
                        <div class='order_status' v-if=" item.state == 1">待付款</div>
                        <div class='order_status' v-if=" item.state == 2">待收货</div>
                        <div class='order_status' v-if=" item.state == 3">已完成</div>
                        <div class='order_status' v-if=" item.state == 4">待发货</div>
                        <!-- <div class='order_status' v-if="item.add_group == 1 && item.group_state == -1">拼团失败</div>
                        <div class='order_status' v-if="item.add_group == 1 && item.group_state == 1">拼团成功</div>
                        <div class='order_status' v-if="item.add_group == 1 && item.group_state == 0">拼团中</div> -->
                        <div class='order_btn'>
                            <span v-if="item.add_group !=1 && item.state == 1 " @click.stop="cancelOrder(item)">取消订单</span>
                            <span v-if="item.add_group !=1 && item.state == 1" @click.stop="buy(item)">去支付</span>
                            <span v-if="item.state == 3" @click.stop="cancelOrder(item)">删除订单</span>
                            <span v-if="item.pj_state == 0 && item.type == 2 && item.add_group !=1 && item.state == 3 || item.pj_state == 0 && item.type == 2 && item.add_group == 1 && item.group_state == 1 &&  item.state == 3" @click.stop="submitPL(item)">发表评价</span>
                            <span v-if="item.add_group !=1 && item.state == 2 || item.add_group == 1 && item.group_state == 1 && item.state == 2" @click.stop="lookWl(item)">查看物流</span>
                            <span v-if="item.add_group !=1 && item.state == 2 || item.add_group == 1 && item.group_state == 1 && item.state == 2" @click.stop="comfirmOrder(item)">确认收货</span>
                        </div>
                    </div>
                </li>
            </ul>
            <div v-if="!tableData.length" style="text-align:center;margin-top:30px;">
                暂无数据~
            </div>
            <pagition 
                v-if="tableData.length"
                :count="count"
                @currentChange='currentChange'
            >
            </pagition>
      </div>
      <!-- 评论 -->
      <el-dialog
        title="发表评价"
        :visible.sync="dialogVisible"
        width="30%">
        <div class="pj_content">
            <img class="pj_img" :src="curOrder.cover | fixImg" alt="">
            <div class="pj_info">
                <div>{{curOrder.name}}</div>
                <div class="pj_num_price">
                    <span>x{{curOrder.goods_num}}</span>
                    <span style="color:#CE0A00">￥{{curOrder.price}}</span>
                </div>
            </div>
        </div>
        <el-input
            type="textarea"
            :rows="5"
            placeholder="请输入评价"
            v-model="content">
        </el-input>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false" size="mini">取 消</el-button>
            <el-button type="primary" @click="addGoodsComment" size="mini">确 定</el-button>
        </span>
    </el-dialog>
    <!-- 物流 -->
      <el-dialog
        title="查看物流"
        :visible.sync="wlShow"
        class="wuliu_dialog"
        :lock-scroll="true"
        width="30%">
              <p style="padding-left:10px;color:#333;font-size:14px;margin-top:10px;">物流公司：{{curOrder.express_company}}</p>
              <p style="padding-left:10px;color:#333;font-size:14px;margin-top:10px;margin-bottom:10px;">快递单号：{{curOrder.express_sn}}</p>
              <div style="" class="order_wuliu">
                <el-timeline>
                    <el-timeline-item
                        v-for="(activity, index) in expressList"
                        :key="index"
                        :timestamp="activity.time">
                        {{activity.context}}
                    </el-timeline-item>
                </el-timeline>
              </div>
        <span slot="footer" class="dialog-footer" style='text-align:center'>
            <el-button @click="wlShow = false" size="mini">关闭</el-button>
        </span>
    </el-dialog>
  </div>
</template>

<script>
import * as http from '@/assets/api/http.js'
export default {
  data() {
    return {
        status:0,
        tableData:[],
        count:0,
        page:1,
        loading:true,
        dialogVisible:false,
        curOrder:{},
        content:'',
        expressList:[],//物流信息
        wlShow:false
    };
  },
  methods: {
      submitPL(item){
          this.dialogVisible = true;
          this.curOrder = item;
      },
      lookWl(item){
          this.curOrder = item;
          this.expressQuery()
      },
      //   发表商品评论
    addGoodsComment(){
        if(!this.content){
            this.$message.warning('评价不能为空')
            return
        }
        this.$confirm('确认是否提交?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
            let that = this;
            let formdata = new FormData();
            formdata.append('uid',that.$store.state.uid);
            formdata.append('token',that.$store.state.token);
            formdata.append('content',that.content);
            //   状态 0 全部 1 待付款 2 待收货 3 已完成
            formdata.append('order_id',that.curOrder.id);
            formdata.append('goods_id',that.curOrder.goods_id);
            http.addGoodsComment(formdata).then(value=>{
                    if(value.code == 200){
                    this.$message.success(value.msg)
                    this.dialogVisible = false;
                    ths.orderList()
                    this.content = ''
                    }else{
                    this.$message.error(value.msg)
                    }
                })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });          
        });
      
    },
    // 物流信息查询
    expressQuery(){
        let that = this;
         let formdata = new FormData();
        //   分类
        formdata.append('uid',that.$store.state.uid);
        formdata.append('token',that.$store.state.token);
        formdata.append('order_id',that.curOrder.id);
        http.expressQuery(formdata).then(value=>{
                if(value.code == 200){
                    this.wlShow = true;
                    this.expressList = value.data
                }else{
                this.$message.error(value.msg)
                }
            })
    },
      currentChange(val){
          this.page=val;
          this.orderList()
      },
      handleClick(tab, event){
          this.page = 1
          this.orderList()
      },
    //  取消订单
      cancelOrder(item){
          let that = this;
          this.$confirm('该操作将删除订单数据, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                    let formdata = new FormData();
                    formdata.append('uid',that.$store.state.uid);
                    formdata.append('token',that.$store.state.token);
                    formdata.append('type',2);
                    //   状态 0 全部 1 待付款 2 待收货 3 已完成
                    formdata.append('order_id',item.id);
                    formdata.append('state',1);
                    http.cancelOrder(formdata).then(value=>{
                            if(value.code == 200){
                                this.page = 1
                            this.orderList()
                            this.$message.success('删除成功')
                            }else{
                            this.$message.error(value.msg)
                            }
                        })
            }).catch(() => {
            this.$message({
                type: 'info',
                message: '已取消删除'
            });          
            });
         
        
      },
    //   确认收货
    comfirmOrder(item){
        this.$confirm('是否确认收货', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
            this.loading = true;
            let that = this;
            let formdata = new FormData();
            formdata.append('uid',that.$store.state.uid);
            formdata.append('token',that.$store.state.token);
            formdata.append('order_id',item.id);
            http.confirmReceipt(formdata).then(value=>{
                this.loading = false;
                    if(value.code == 200){
                    console.log(value)
                    this.$message.success(value.msg)
                    this.status = '0';
                    }else{
                    this.$message.error(value.msg)
                    }
                })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });          
        });
    },
    //   订单列表
    orderList(){
        this.loading = true;
      let that = this;
      let formdata = new FormData();
      formdata.append('uid',that.$store.state.uid);
      formdata.append('token',that.$store.state.token);
      formdata.append('type',2);
    //   状态 0 全部 1 待付款 2 待收货 3 已完成
      formdata.append('status',that.status);
      formdata.append('p',that.page);
      http.orderList(formdata).then(value=>{
            if(value.code == 200){
              console.log(value)
              this.tableData=value.data.list
              this.count = value.data.count
              this.loading = false;
            }else{
              this.$message.error(value.msg)
            }
        })
    },
    // 去支付
    buy(item){
        if(item.type == 2){

             this.$router.push({
              path:"/shopMall/goodsConfirmOrder",
              query:{
                //   goods_id:item.id,
                //   price:item.price,
                //   single_price:item.price,
                //   goodsInfo:encodeURIComponent(JSON.stringify(item)),
                  goodDetails:JSON.stringify(item),
                  from:'orderList'
              }
          })
          return
        }
       this.$router.push({
              path:"/shopMall/confirmOrder",
              query:{
                  goods_id:item.id,
                  price:item.price,
                  single_price:item.price,
                  goodsInfo:encodeURIComponent(JSON.stringify(item)),
                  from:'orderList'
              }
          })
    },
    toDetails(item){
        if(item.type == 2){
            this.$router.push({
              path:'/mallDetails',
              query:{
                  goods_id:item.goods_id,
                  
              }
          })
        }
         
    },
    // 查看详情
    toLook(item){
        if(item.type == 2){

             this.$router.push({
              path:"/shopMall/goodsConfirmOrder",
              query:{
                //   goods_id:item.id,
                //   price:item.price,
                //   single_price:item.price,
                //   goodsInfo:encodeURIComponent(JSON.stringify(item)),
                  goodDetails:JSON.stringify(item),
                  from:'orderList',
                  look:1
              }
          })
          return
        }
    }
  },
  created() {
      this.orderList()
  },
//   mounted() {

//   },
  components: {},
}
</script>

<style lang="scss" scoped>
    .my_order{
        padding:40px 30px;
        .order_tit{
            color:#666666;
            font-size:24px;
            margin-bottom: 5px;
        }
        .order_nav{
            position: relative;
            line-height: 50px;
           /deep/ .el-tabs__item.is-active{
                color: #CE0A00;
            }
            /deep/ .el-tabs__active-bar{
                background-color: #CE0A00;
            }
            /deep/ .el-tabs__item:hover {
                color: #CE0A00;
                cursor: pointer;
            }
            .order_serach{
                position: absolute;
                right:0;
                top:0;
                width:275px;
                border: 1px solid #DCDCDC;
                border-radius:5px;
                line-height:36px;
                background:url('../../assets/image/home/fdj.png') 250px center no-repeat;
                background-size:15px 15px;
                color:#999999;
                display: flex;
                input{
                    flex:1;
                    line-height: 36px;
                    background: transparent;
                    color:#999999;
                    border:none;
                }
            }
            .order_list{
                margin-bottom: 20px;
                .order_list_item{
                    margin-top:20px;
                    border:1px solid #ECECEC;
                    .order_title{
                        background:#ECECEC;
                        line-height:40px;
                        display:flex;
                        font-size:12px;
                        color:#999;
                        padding-left:12px;
                        .order_number{
                            color:#333;
                        }
                    }
                    .order_details{
                        font-size:14px;
                        color:#333;
                        display:flex;
                        padding-left:14px;
                        align-items: center;
                        height:128px;
                        padding-top:16px;
                        padding-bottom: 25px;
                        box-sizing: border-box;
                        img{
                            width:86px;
                            height: 86px;
                            margin-right:17px;
                        }
                        .order_goods{
                            width:250px;
                            height: 100%;
                            display: flex;
                            justify-content: space-between;
                            flex-direction: column;
                            .goods_guige{
                                color:#999999;
                            }
                        }
                        .order_num{
                            width:105px;
                        }
                        .order_price{
                            width:130px;
                        }
                        .order_status{
                            width:136px;
                        }
                        .order_btn{
                            display: flex;
                            height: 100%;
                            flex-direction: column;
                            justify-content: center;
                            span{
                                border:1px solid #B5B5B5;
                                text-align: center;
                                line-height:30px;
                                width: 100px;
                                border-radius:3px;
                                margin-bottom: 10px;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
    }
    .pj_content{
        display:flex;
        padding:0 20px;
        margin-bottom: 20px;
        .pj_img{
            width:100px;
            height:100px;
            margin-right:30px;
        }
        .pj_info{
            height:100px;
            display:flex;
            flex:1;
            justify-content: space-between;
            flex-direction: column;
            .pj_num_price{
                display: flex;
                padding-right:50px;
                color:#ccc;
                font-size:14px;
                justify-content: space-between;
            }
        }
    }
    .pay_title{
            color:#333;
            font-size:16px;
        }
    .order_wuliu{
        height:500px;
        overflow: auto;
    }
    .wuliu_dialog{
        /deep/ .el-dialog__header{
            display:none !important;
        }
    }
</style>