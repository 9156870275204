<template>
 <div class="wrong_question">
     <div style="display:flex;justify-content:space-between">
         <div class="recite_tag">
            <span :class="['recite_item',{'active':tagIndex == 0}]" @click="changeTag(0)"  style="border-right:none;">高频错题</span>
            <span :class="['recite_item',{'active':tagIndex == 1}]" @click="changeTag(1)" style="border-left:none;">高频考点</span>
        </div>
        <span style="color:#CF0A00;font-size:14px;">
            {{tagIndex == 0?highwrong.wrong:highwrong.questions}}
            人参加
        </span>
     </div>
     
     <div class="point_tips">
         历年真题反复考查、强化训练、反复记忆、精确把握命题趋势、针对练习，举一反三
     </div>
     <el-table
        :data="tableData"
        :row-class-name="tableRowClassName"
        @row-click="rowClick"
        :header-cell-style="{background: '#DCDCDC',color:'#333',fontSize:'16px',opacity:0.8,height:'60px'}"
        style="width: 100%">
        <!-- <el-table-column
            type="expand">
            <template slot-scope="props">
                <ul>
                    <li class="menu_list" v-for="(item,index) in props.row.data" :key="index" @click="todetails(props.row,item)">
                        {{item.content}}
                    </li>
                </ul>
            </template>
        </el-table-column> -->
        <el-table-column
            prop="cate_name"
            label="学科">
            <template slot-scope="scope">
                {{scope.row.cate_name}}-{{scope.row.name}}
            </template>
        </el-table-column>
        <el-table-column
            prop="total"
            label="更新时间"
            width="180">
            <template slot-scope="scope">
                {{timestampToTime(scope.row.updated_at*1000)}}
            </template>
        </el-table-column>
    </el-table>
 </div>
</template>

<script>
import * as http from '@/assets/api/http.js'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
 export default {
  name: '',
  data () {
   return {
       tableData: [],
       tagIndex:0,
       highwrong:{}
   }
  },
  created(){
      this.highWrongExercises();
      this.highExercisesWrongExercises()
  },
  methods:{
      tableRowClassName(){
          return 'tableRow'
      },
      rowClick(row, column){
          this.$router.push({
              path:'/testPointDetails',
              query:{
                  subject_id:row.id,
                  testType:this.tagIndex
              }
          })
      },
    //  高频错题 
     highWrongExercises(){
        let params = {
            cate_id:this.cate_id
        }
      http.highWrongExercises(params).then(value=>{
            if(value.code == 200){
                this.tableData = value.data;
            }
        })
    },
    // 高频考题
    highTestExercises(){
        let params = {
            cate_id:this.cate_id
        }
      http.highTestExercises(params).then(value=>{
            if(value.code == 200){
                this.tableData = value.data;
            }
        })
    },
    // 高频统计
    highExercisesWrongExercises(){
        let params = {
            cate_id:this.cate_id
        }
      http.highExercisesWrongExercises(params).then(value=>{
            if(value.code == 200){
                this.highwrong = value.data;
            }
        })
    },
    // 查看错题
    todetails(item1,item2){
        this.$router.push({
              path:'/reciteDetails',
              query:{
                  subject_id:item1.id,
                  menu_id:item2.menu_id
              }
          })
    },
    changeTag(index){
        this.tagIndex = index;
        if(index == 0){this.highWrongExercises()}
        if(index == 1){this.highTestExercises()}
    }
  },
  components: {

  },
  computed:{
    ...mapState(['cate_id'])
  },
 }
</script>

<style scoped lang="scss">
.wrong_question{
    .wrong_number{
        color:#666666;
        font-size:18px;
    }
    /deep/.el-table .tableRow{
        height:74px;
    }
    /deep/.el-table .el-table__expanded-cell{
        padding:0
    }
}
 .menu_list{
     background:#f8f8fb;
     font-size: 14px;
    color: #909399;
    line-height:50px;
    border-bottom: 1px solid #f8f8fb;
    padding-left:30px;
    cursor: pointer;
 }
 .recite_tag{
     display:flex;
     margin-bottom: 15px;
     .recite_item{
        width: 91px;
        height: 28px;
        border-radius: 7px;
        line-height:28px;
        text-align: center;
        color:#666666;
        cursor: pointer;
        border:1px solid #DCDCDC;
        
        &.active{
            background: #CF0A00;
            color:#fff;
            border-color:#CF0A00;
        }
     }
 }
 .point_tips{
     color:#E1410F;
     padding:9px 14px;
     background:#FCECE7;
     opacity: 0.5;
     margin-bottom: 20px;;
 }
</style>
