<template>
 <div class="wrong_question">
     <div class="wrong_number">
         <!-- 总错题数：<span style="color:#E1410F">340</span> -->
     </div>
     <el-table
     v-loading="loading"
        :data="tableData"
        ref="table"
        :row-class-name="tableRowClassName"
        @row-click="rowClick"
        :header-cell-style="{background: '#DCDCDC',color:'#333',fontSize:'16px',opacity:0.8,height:'60px'}"
        style="width: 100%">
        <el-table-column
            prop="name"
            label="题目列表">
        </el-table-column>
    </el-table>
 </div>
</template>

<script>
import * as http from '@/assets/api/http.js'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
 export default {
  name: '',
  data () {
   return {
       tableData: [],
       loading:true
   }
  },
  created(){
      if(this.$route.query.type == 'year'){
          this.exercisesCourseTypeNum()
      }else{
          this.exercisesChapterNum()
      }
      
  },
  methods:{
      tableRowClassName(){
          return 'tableRow'
      },
    //   rowClick(row, column){
    //       console.log(row, column)
    //       this.$router.push({
    //           path:'/wrongAnswer'
    //       })
    //   },
      rowClick(row){
          console.log(row)
        
        if(this.$route.query.type == 'year'){
          this.exercisesCourseTypeList(row)
      }else{
          this.exercisesChapterList(row)
      }
        
    },
    // 获取学科列表 -当前页
    exercisesChapterList(row){
        let params = {
            subject_id:this.$route.query.subject_id,
            menu_id:this.$route.query.menu_id,
            page:row.page
        }
    return  http.exercisesChapterList(params).then(value=>{
            if(value.code == 200){
                if(value.data.length>0){
                    this.$router.push({
                            path:'/doQuestion',
                            query:{
                                subject_id:this.$route.query.subject_id,
                                menu_id:this.$route.query.menu_id,
                                page:row.page
                            }
                        })
                }else{
                    this.$message.warning('当前题库无数据')
                }
            }
        })
    }, 
    // 获取学科列表 
    exercisesChapterNum(){
        let params = {
            subject_id:this.$route.query.subject_id,
            menu_id:this.$route.query.menu_id
        }
    return  http.exercisesChapterNum(params).then(value=>{
        this.loading = false;
            if(value.code == 200){
                if(value.data){
                    let allPage = Math.ceil(value.data.allNum/value.data.pageNum)
                    console.log(allPage)
                    for(let i =0;i<allPage;i++){
                        let obj = {
                            name:`专业基础课（${(i*value.data.pageNum)+1}~${i<allPage?value.data.pageNum:parseInt(value.data.pageNum/value.data.allNum)}）题`,
                            page:i+1
                        }
                        this.tableData.push(obj)
                    }
                }else{
                    this.$message.warning('当前题库无数据')
                }
            }
        })
    },
    // 获取年份做题列表 -当前页
    exercisesCourseTypeList(row){
        let params = {
            year:this.$route.query.year,
            course_type:this.$route.query.course_type,
            cate_id:this.cate_id,
            page:row.page
        }
    return  http.exercisesCourseTypeList(params).then(value=>{
            if(value.code == 200){
                if(value.data.length>0){
                    this.$router.push({
                            path:'/doQuestion',
                            query:{
                                year:this.$route.query.year,
                                course_type:this.$route.query.course_type,
                                cate_id:this.cate_id,
                                page:row.page,
                                type:'year'
                            }
                        })
                }else{
                    this.$message.warning('当前题库无数据')
                }
            }
        })
    },
    // 获取学科列表 
    exercisesCourseTypeNum(){
        let params = {
            subject_id:this.$route.query.subject_id,
            menu_id:this.$route.query.menu_id,
            year:this.$route.query.year,
            cate_id:this.cate_id,
            course_type:this.$route.query.course_type,
        }
    return  http.exercisesCourseTypeNum(params).then(value=>{
        this.loading = false;
            if(value.code == 200){
                if(value.data){
                    let allPage = Math.ceil(value.data.allNum/value.data.pageNum)
                    console.log(allPage)
                    for(let i =0;i<allPage;i++){
                        let obj = {
                            name:`专业基础课（${(i*value.data.pageNum)+1}~${i<allPage?value.data.pageNum:parseInt(value.data.pageNum/value.data.allNum)}）题`,
                            page:i+1
                        }
                        this.tableData.push(obj)
                    }
                }else{
                    this.$message.warning('当前题库无数据')
                }
            }
        })
    },

  },
  components: {

  },
  computed:{
    ...mapState(['cate_id'])
  },
 }
</script>

<style scoped lang="scss">
.wrong_question{
    .wrong_number{
        color:#666666;
        font-size:18px;
    }
    /deep/.el-table .tableRow{
        height:74px;
    }
     /deep/.el-table .el-table__expanded-cell{
        padding:0
    }
}
 .menu_list{
     background:#f8f8fb;
     font-size: 14px;
    color: #909399;
    line-height:50px;
    border-bottom: 1px solid #f8f8fb;
    padding-left:30px;
    cursor: pointer;
    display:flex;
    justify-content: space-between;
    padding-right:160px;
 }
</style>
