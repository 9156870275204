<template>
  <div id="app" >
    <router-view v-if="show"></router-view>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
export default {
  data(){
    return {
      show:true
    }
  },
  created(){
    this.setToken();
    this.setcatId()
    document.oncontextmenu = function (event){
        if(window.event){
        event = window.event;
        }try{
        var the = event.srcElement;
        if (!((the.tagName == "INPUT" && the.type.toLowerCase() == "text") || the.tagName == "TEXTAREA")){
        return false;
        }
        return true;
        }catch (e){
        return false;
        }
    }
    
        document.onkeydown = document.onkeyup = document.onkeypress = function (event) {
            var e = event || window.event || arguments.callee.caller.arguments[0];
        
            if (e && e.keyCode == 123) {
                e.returnValue = false;
                return (false);
            }
        }
  },
  methods:{
    ...mapMutations(['saveUid','saveToken','savePersonInfo','changeQuestionIndex']),
    // 如果刷新先设置token
      setToken(){
        if(sessionStorage.getItem('token')){
          this.show= false;
          this.saveUid(JSON.parse(sessionStorage.getItem('uid')))
          this.saveToken(JSON.parse(sessionStorage.getItem('token')))
          this.savePersonInfo(JSON.parse(sessionStorage.getItem('personInfo')))
          this.show= true;
        }
      },
    // 如果有cateid设置cate_id
    setcatId(){
      if(sessionStorage.getItem('cate_id')){
          this.show= false;
          this.changeQuestionIndex(JSON.parse(sessionStorage.getItem('cate_id')))
          this.show= true;
        }
    }
  }
}
</script>
<style lang="scss">
html,body,#app {
  color: #2c3e50;
  // height: 100%;
  font-family: 'MicrosoftYaHei';
  background: rgb(250,250,250);
}
div::-webkit-scrollbar{
  width:8px;
  height:10px;
  /**/
}
div::-webkit-scrollbar-track{
  background: rgb(239, 239, 239);
  border-radius:2px;
}
div::-webkit-scrollbar-thumb{
  background: #bfbfbf;
  border-radius:10px;
}
div::-webkit-scrollbar-thumb:hover{
  background: #ccc;
}
div::-webkit-scrollbar-corner{
  background: #179a16;
}
 html{
    overflow-y: hidden;
    height:100%;

  }
  body{
    overflow-y: auto;
    height:100%;
  }

//   :root {
//     overflow-x: auto;

//     overflow-y: hidden

//   }

//   :root body {
//     position: absolute;

//   }

//   body {
//     width: 100vw;

//     overflow: hidden;

//   }
</style>
