import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import login from '../views/login/login.vue'
import regisiter from '../views/login/regisiter.vue'
// 个人信息
import my from '../views/my/my.vue'
import myOrder from '../views/my/myOrder.vue'
import myQuestionBank from '../views/my/myQuestionBank.vue'
import myClass from '../views/my/myClass.vue'
import myWallet from '../views/my/myWallet.vue'
import myCollect from '../views/my/myCollect.vue'
import myMessage from '../views/my/myMessage.vue'
import myAddress from '../views/my/myAddress.vue'
import mytestRecord from '../views/my/mytestRecord.vue'
import myData from '../views/my/myData.vue'
import customer from '../views/my/customer.vue'
import myZFM from '../views/my/myZFM.vue'
// 首页
import index from '../views/home/index.vue'
import teacherTeam from '../views/home/teacherTeam.vue'
import teacherDetails from '../views/home/teacherDetails.vue'
import curriculum from '../views/home/curriculum.vue'
import curriculumDetails from '../views/home/curriculumDetails.vue'
import curriculumVideo from '../views/home/curriculumVideo.vue'
import confirmOrder from '../views/shopMall/confirmOrder.vue'
import confrimwalletOrder from '../views/shopMall/confrimwalletOrder.vue'
import goodsConfirmOrder from '../views/shopMall/goodsConfirmOrder.vue'
import appDownLoad from '../views/home/appDownLoad.vue'
import aboutUs from '../views/home/aboutUs.vue'
import newsDetails from '../views/home/newsDetails.vue'
// 商城
import mall from '../views/home/mall.vue'
import mallDetails from '../views/mall/mallDetails.vue'
// 题库
import questionBank from "../views/home/questionBank.vue"
// 题库父页面
import questionHome from "../views/questionBank/questionHome.vue"
import wrongQuestion from "../views/questionBank/wrongQuestion.vue"
import randomQusetion from "../views/questionBank/randomQusetion.vue"
import randomTest from "../views/questionBank/randomTest.vue"
import reciteQues from "../views/questionBank/reciteQues.vue"
import randomPratice from "../views/questionBank/randomPratice.vue"
import doQuestion from "../views/questionBank/doQuestion.vue"
import reciteDetails from "../views/questionBank/reciteDetails.vue"
import statistics from "../views/questionBank/statistics.vue"
import testPoint from "../views/questionBank/testPoint.vue"
import testPointDetails from "../views/questionBank/testPointDetails.vue"
import wrongAnswer from "../views/questionBank/wrongAnswer.vue"
import lookWrong from "../views/questionBank/lookWrong.vue"
import imitateTest from "../views/questionBank/imitateTest.vue"
import imitateTestDetails from "../views/questionBank/imitateTestDetails.vue"
import laws from "../views/questionBank/laws.vue"
import lawsDetails from "../views/questionBank/lawsDetails.vue"
import newsList from "../views/questionBank/newsList.vue"
import collect from "../views/questionBank/collect.vue"
import collectDetails from "../views/questionBank/collectDetails.vue"
import answeruestion from "../views/questionBank/answeruestion.vue"
import searchQuestionList from "../views/questionBank/searchQuestionList.vue"
import searchLaws from "../views/questionBank/searchLaws.vue"
import quessearch from "../views/questionBank/quessearch.vue"
import textDetails from "../views/questionBank/textDetails.vue"
import questionBankList from "../views/questionBank/questionBankList.vue"
Vue.use(VueRouter)

//解决vue路由重复导航错误
//获取原型对象上的push函数

const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
   return (originalPush.call(this, location)as any).catch(err => err)
}
const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect:'/index',
    children:[
      {
        path: '/login',
        name: 'login',
        component: login,
      },
      {
        path: '/regisiter',
        name: 'regisiter',
        component: regisiter,
      },
      {
        path: '/questionHome',
        name: 'questionHome',
        component: questionHome,
        children:[{
            path: '/wrongQuestion',
            name: 'wrongQuestion',
            component: wrongQuestion,
            meta:{
              title:'question'
            }
          },
          {
            path: '/questionBankList',
            name: 'questionBankList',
            component: questionBankList,
            meta:{
                  title:'question'
                }
          },{
            path: '/randomQusetion',
            name: 'randomQusetion',
            component: randomQusetion,
            meta:{
              title:'question'
            }
          },{
            path: '/quessearch',
            name: 'quessearch',
            component: quessearch,
            meta:{
              title:'question'
            }
          },{
            path: '/searchQuestionList',
            name: 'searchQuestionList',
            component: searchQuestionList,
            meta:{
              title:'question'
            }
          },{
            path: '/searchLaws',
            name: 'searchLaws',
            component: searchLaws,
            meta:{
              title:'laws',
              searchBread:'法律法规搜索'
            }
          },{
            path: '/reciteQues',
            name: 'reciteQues',
            component: reciteQues,
            meta:{
              title:'question'
            }
          },
          {
            path: '/statistics',
            name: 'statistics',
            component: statistics,
            meta:{
              title:'question'
            }
          },
          {
            path: '/testPoint',
            name: 'testPoint',
            component: testPoint,
            meta:{
              title:'question'
            }
          },{
            path: '/imitateTest',
            name: 'imitateTest',
            component: imitateTest,
            meta:{
              title:'question'
            }
          },{
            path: '/laws',
            name: 'laws',
            component: laws,
            meta:{
              title:'laws'
            }
          },{
            path: '/newsList',
            name: 'newsList',
            component: newsList,
            meta:{
              title:'question'
            }
          },
          {
            path: '/newsDetails',
            name: 'newsDetails',
            component: newsDetails,
            meta:{
              title:'question'
            }
          },
          {
            path: '/collect',
            name: 'collect',
            component: collect,
            meta:{
              title:'question'
            }
          },{
            path: '/answeruestion',
            name: 'answeruestion',
            component: answeruestion,
            meta:{
              title:'question'
            }
          },
        ]
      },
      {
        path: '/lawsDetails',
        name: 'lawsDetails',
        component: lawsDetails,
        meta:{
              title:'question'
            }
      },
      {
        path: '/textDetails',
        name: 'textDetails',
        component: textDetails,
        meta:{
              title:'question'
            }
      },
      {
        path: '/collectDetails',
        name: 'collectDetails',
        component: collectDetails,
        meta:{
              title:'question'
            }
      },
      {
        path: '/randomTest',
        name: 'randomTest',
        component: randomTest,
        meta:{
              title:'question'
            }
      },
      {
        path: '/imitateTestDetails',
        name: 'imitateTestDetails',
        component: imitateTestDetails,
        meta:{
              title:'question'
            }
      },
      {
        path: '/randomPratice',
        name: 'randomPratice',
        component: randomPratice,
        meta:{
              title:'question'
            }
      },
      {
        path: '/testPointDetails',
        name: 'testPointDetails',
        component: testPointDetails,
        meta:{
              title:'question'
            }
      },
      {
        path: '/doQuestion',
        name: 'doQuestion',
        component: doQuestion,
        meta:{
              title:'question'
            }
      },
      {
        path: '/reciteDetails',
        name: 'reciteDetails',
        component: reciteDetails,
        meta:{
              title:'question'
            }
      },
      
      {
        path: '/wrongAnswer',
        name: 'wrongAnswer',
        component: wrongAnswer,
        meta:{
              title:'question'
            }
      },
      
      {
        path: '/lookWrong',
        name: 'lookWrong',
        component: lookWrong,
        meta:{
              title:'question'
            }
      },
      {
        path: '/index',
        name: 'index',
        component: index,
      },
      {
        path: '/teacherTeam',
        name: 'teacherTeam',
        component: teacherTeam,
      },
      {
        path: '/teacherDetails',
        name: 'teacherDetails',
        component: teacherDetails,
      },
      {
        path: '/curriculum',
        name: 'curriculum',
        component: curriculum,
      },
      {
        path: '/curriculumDetails',
        name: 'curriculumDetails',
        component: curriculumDetails,
      },
      {
        path: '/curriculumVideo',
        name: 'curriculumVideo',
        component: curriculumVideo,
      },
      {
        path: '/appDownLoad',
        name: 'appDownLoad',
        component: appDownLoad,
      },
      {
        path: '/aboutUs',
        name: 'aboutUs',
        component: aboutUs,
      },
      {
        path: '/shopMall/confirmOrder',
        name: 'shopMall',
        component: confirmOrder,
      },
      {
        path: '/shopMall/confrimwalletOrder',
        name: 'confrimwalletOrder',
        component: confrimwalletOrder,
      },
      {
        path: '/shopMall/goodsConfirmOrder',
        name: 'goodsConfirmOrder',
        component: goodsConfirmOrder,
      },
      {
        path:'/my',
        name: 'my',
        component: my,
        children:[
          // 我的订单
          {
            path: '/my/myOrder',
            name: 'myOrder',
            component: myOrder,
          },
          // 我的题库
          {
            path: '/my/myQuestionBank',
            name: 'myQuestionBank',
            component: myQuestionBank,
          },
          // 我的课程
          {
            path: '/my/myClass',
            name: 'myClass',
            component: myClass,
          },
          // 我的钱包
          {
            path: '/my/myWallet',
            name: 'myWallet',
            component: myWallet,
          },
          // 我的支付码
          {
            path: '/my/myZFM',
            name: 'myZFM',
            component: myZFM,
          },
          // 我的收藏
          {
            path: '/my/myCollect',
            name: 'myCollect',
            component: myCollect,
          },
          // 我的消息
          {
            path: '/my/myMessage',
            name: 'myMessage',
            component: myMessage,
          },
          // 地址管理
          {
            path: '/my/myAddress',
            name: 'myAddress',
            component: myAddress,
          },
          {
            path: '/my/mytestRecord',
            name: 'mytestRecord',
            component: mytestRecord,
          },
          // 个人资料
          {
            path: '/my/myData',
            name: 'myData',
            component: myData,
          },
          // 客服
          {
            path: '/my/customer',
            name: 'customer',
            component: customer,
          },
        ]
      },
      {
        path: '/mall',
        name: 'mall',
        component: mall,
      },
      {
        path: '/mallDetails',
        name: 'mallDetails',
        component: mallDetails,
      },
      {
        path: '/questionBank',
        name: 'questionBank',
        component: questionBank,
        meta:{
          title:'question'
        }
      },
    ]
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
]

const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  // 让页面回到顶部
  document.documentElement.scrollTop = 0
  // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁
 next()
})
export default router
