<template>
 <div class="my_container" >
      <p class="my_page_tit">
          <router-link to="/index">首页</router-link> > <router-link to="/curriculum">课程</router-link>  >    {{goodsDetails.name}}
      </p>
      <!-- 商品详情 -->
      <div class="mall_details">
          <!-- <img class="mall_img" :src="goodsDetails.cover | fixImg" alt=""> -->
            <el-carousel class="mall_img" >
                <el-carousel-item style="height:100%" v-for="(item,index) in goodsDetails.banner" :key="index" class="index_swiper">
                    <img class="mall_img"  :src="item | fixImg" alt="" @click="swiperLink(item)">
                </el-carousel-item>
            </el-carousel>
          <div class="mall_info">
              <div class="mall_info_title">
                  {{goodsDetails.name}}
              </div>
              <div class="mall_price">
                  <span class='mall_now_price' v-if="goodsDetails.is_sale != 1">¥{{goodsDetails.price}}</span>
                  <span class='mall_now_price' v-if="goodsDetails.is_sale == 1">¥{{goodsDetails.old_price}}</span>
                  <span class="mall_line_price" v-if="goodsDetails.is_sale == 1">￥{{goodsDetails.price}}</span>
              </div>
              <div class="mall_author_info">
                  {{goodsDetails.desc}}
                     <!-- <span class="mall_author">作者</span>
                     <span>  刘凤科、杨帆、钟秀勇、徐金桂、杨雄、李晗、宋光明、韩心怡</span> -->
                 </div>
              <div class="mall_author_info">
                     <span class="mall_author">销量</span>
                     <span>  {{goodsDetails.sale_count}}</span>
                     <span class="mall_author" style="margin-left:20px;">快递</span>
                     <span>  {{goodsDetails.express_price}}</span>
            </div>
            <div class="mall_opera_list">
                <div class="collect_opera">
                    收藏商品
                     <img @click="goodsCollect" v-if="goodsDetails.isCollect != 1" src="../../assets/image/sc.png" alt="">
                    <img  @click="goodsCollect" v-if="goodsDetails.isCollect == 1" src="../../assets/image/ysc.png" alt=""  >
                </div>
                <div class="share_opera">
                    分享
                           <el-popover
                            placement="top"
                            width="100"
                            v-model="visible">
                            <div class="qrcode" ref="qrCodeUrl"></div>
                            <img slot="reference" style="margin-left:15px" src="../../assets/image/share1.png" @click="creatQrCode" alt="">
                        </el-popover>
                          <span style="margin-left:15px">|</span>
                          <img style="margin-left:15px" src="../../assets/image/share2.png" @click="sharewb" alt="">
                          <span style="margin-left:15px">|</span>
                          <img style="margin-left:15px" src="../../assets/image/share4.png" @click="shareQQ" alt="">
                          <!-- <span style="margin-left:15px" v-if="details.info.pdf && (details.buy == 1 || details.info.is_free == 1)">|</span> -->
                </div>
            </div>
            <div class="mall_btn_list">
                <div class="mall_price_btn" v-if="goodsDetails.is_group == 1" @click="buy(1)">¥{{goodsDetails.group_price}} <div>发起拼团</div></div>
                <div v-if="goodsDetails.is_group != 1 && goodsDetails.is_sale != 1" class="now_buy mall_price_btn" @click="buy(0)">¥{{goodsDetails.price}}<div>立即购买</div></div>
                <div v-if="goodsDetails.is_group != 1 && goodsDetails.is_sale == 1" class="now_buy mall_price_btn" @click="buy(0)">¥{{goodsDetails.old_price}} <div>立即购买</div></div>
                <div v-if="goodsDetails.is_group == 1 && goodsDetails.is_sale != 1" class="now_buy mall_price_btn" @click="buy(0)">¥{{goodsDetails.price}}<div>单独购买</div></div>
                <div v-if="goodsDetails.is_group == 1 && goodsDetails.is_sale == 1" class="now_buy mall_price_btn" @click="buy(0)">¥{{goodsDetails.old_price}} <div>单独购买</div></div>
            </div>
          </div>
      </div>
      <!-- 商品详情和评价以及右侧列表 -->
      <div class="mall_content">
          <div class="mall_goods_info">
              <el-tabs v-model="activeName" class="class_nav">
                <el-tab-pane label="商品详情" name="first"></el-tab-pane>
                <el-tab-pane label="商品评价" name="second"></el-tab-pane>
            </el-tabs>
            <!-- 商品详情 -->
            <div class="goods_content">
                <!-- 详情 -->
                <div v-if="activeName == 'first'">
                    <div class="module_title">
                        <span></span>
                            支持服务
                    </div>
                    <div class="evaluate_tags">
                            <span>{{goodsDetails.keywords}}</span>
                            
                    </div>
                    <div class="module_title" >
                        <span></span>
                            商品详情
                    </div>
                    <div class="evaluate_gaishu" v-html="goodsDetails.content"></div>
                    
                </div>
               
                
            </div>
            <!-- 评价交流 -->
            <div v-if="activeName=='second'">
                <ul class="evaluate_list">
                    <li v-for="(item,index) in commentList" :key="index" class="p_evaluate_list_item">
                        <div class="evaluate_list_item"> 
                            <img class="evaluate_header" :src="item.user_uhead | fixImg" alt="">
                            <div class="evaluate_details">
                                <div class="evaluate_name">
                                    {{item.user_name}}
                                </div>
                                <div class="evaluate_ontent">
                                    {{item.content}} 
                                </div>
                                <div class="evaluate_info">
                                    <div>
                                        {{timestampToTime(item.created_at*1000)}}
                                    </div>
                                    <div class="evaluate_icon">
                                        <!-- <img src="../../assets/image/dianzan.png" v-if="item.is_goods == 0" @click="commentGoods(item.id)" alt="">
                                        <img src="../../assets/image/dianzanActive.png" v-if="item.is_goods == 1" @click="commentGoods(item.id)" alt="">
                                        <span>&nbsp;</span> -->
                                        <img src="../../assets/image/jiaoliu.png" alt="" @click="pinglun(item)">
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <ul class="evaluate_list_children">
                            <li class="evaluate_list_children_item" v-for="(items,index) in item.child" :key="items.id">
                                <img class="evaluate_header" :src="items.user_uhead | fixImg" alt="">
                                <div class="evaluate_details">
                                    <div class="evaluate_name">
                                        {{items.user_name}}
                                    </div>
                                    <div class="evaluate_ontent">
                                        {{items.content}} 
                                    </div>
                                    <div class="evaluate_info">
                                        <div>
                                            {{timestampToTime(items.created_at*1000)}}
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>

                            </li>
                        </ul>
                    </li>
                    <li v-if="!commentList.length" style="margin-bottom:20px;text-align:center">
                        该商品暂无评论~
                    </li>
                </ul>
                <!-- <div class="evaluate_input">
                    <textarea name="" id="" v-model="commentContent" ></textarea>
                </div>
                <div class="evaluate_operation">
                    <div></div>
                    <div class="evaluate_submit" @click="addGoodsComment">
                        发表评论
                    </div>
                </div> -->
            </div>
          </div>
          <div class="mall_buy_near" v-if="goodsDetails.buy_group_info.length">
              <div class="title">
                  最近购买
              </div>
              <div class="near_buy_tips">
                  刚刚这些人下单成功，可参与拼团
              </div>
              <ul>
                  <li class="near_list_item" v-for="(item,index) in goodsDetails.buy_group_info" :key="index">
                      <div class="near_list_item_top">
                            <img :src="item.user_uhead | fixImg" alt="">
                            <div>
                                <span class="near_name">还差{{item.surplus_user}}人成团</span>
                                <div class="last_time" v-if="item.time">剩余：{{item.time}}</div>
                                <div class="last_time" v-if="!item.time">已结束</div>
                            </div>
                            
                            <div class="join_team_btn" @click="joinTeam(item)">
                                去拼团
                             </div>
                      </div>
                  </li>
              </ul>
              <div style="text-align:center;font-size:14px;" v-if="!goodsDetails.buy_group_info.length">
                  暂无更多数据~
              </div>
          </div>
      </div>
      <!-- 评论弹窗 -->
      <el-dialog
        title="请输入评论"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose">
        <el-input
        type="textarea"
        :rows="8"
        placeholder="请输入内容"
        resize="none"
        v-model="textarea">
        </el-input>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false" size="mini">取 消</el-button>
            <el-button type="primary" @click="submitComment"  size="mini">确 定</el-button>
        </span>
    </el-dialog>
 </div>
</template>

<script>
import * as http from '@/assets/api/http.js'
import QRCode from 'qrcodejs2'
 export default {
  name: '',
  data () {
   return {
       activeName:'first',
        goodsDetails:{},
        visible: false,
        commentList:[],
        commentContent:'',
        textarea:'',
        commentObj:{},
        dialogVisible:false
   }
  },
  methods:{
      pinglun(item){
          console.log(item,'item')
          if(item.user_id == JSON.parse(sessionStorage.getItem('personInfo')).id){
              this.$message.warning('不能回复自己的评价')
              return
          }
          this.dialogVisible = true;
          this.commentObj = item;
      },
      //   二级评论回复
        submitComment(item){
            this.dialogVisible = false;
            this.addGoodsComment(2) 
        },  
      handleClose(){
this.dialogVisible = false;
        },
      // 发表评论
    addGoodsComment(type = 1){
        let that = this;
        let formdata = new FormData();
        //   分类
        formdata.append('uid',that.$store.state.uid);
        formdata.append('token',that.$store.state.token);
        formdata.append('goods_id',that.$route.query.course_id);
        formdata.append('to_user_id',that.commentObj.user_id);
        formdata.append('pid',that.commentObj.id);
        if(type == 2){
            formdata.append('content',that.textarea);
        }else{
            formdata.append('content',that.commentContent);
        }
        
        formdata.append('type',2);
        http.addGoodsComment(formdata).then(value=>{
                if(value.code == 200){
                    this.$message.success(value.msg)
                    this.commentObj = {}
                    this.textarea=''
                    this.goodsComments()
                }else{
                this.$message.error(value.msg)
                }
            })
    },
      // 获取评论列表
    goodsComments(){
        let that = this;
        let formdata = new FormData();
        //   分类
        formdata.append('uid',that.$store.state.uid);
        formdata.append('token',that.$store.state.token);
        formdata.append('goods_id',that.$route.query.goods_id);
        formdata.append('type',2);
        http.goodsComments(formdata).then(value=>{
                if(value.code == 200){
                    this.commentList=value.data;
                }else{
                this.$message.error(value.msg)
                }
            })
    },
      creatQrCode() {
          this.$refs.qrCodeUrl.innerHTML = ""
        var qrcode = new QRCode(this.$refs.qrCodeUrl, {
            text: 'xxxx',
            width: 100,
            height: 100,
            colorDark: '#000000',
            colorLight: '#ffffff',
            correctLevel: QRCode.CorrectLevel.H
        })
        },
    // 商品详情
    goodsInfo(){
       let params = {
           goods_id:this.$route.query.goods_id
       }
        http.goodsInfo(params).then(value=>{
            if(value.code == 200){
                this.goodsDetails = value.data;
                this.goodsDetails.buy_group_info.forEach(v=>{
                    
                    let time = setInterval(()=>{
                        this.$set(v,'time',this.countTime(v.effected_at*1000))
                        if(!this.countTime(v.effected_at*1000)){
                            clearInterval(time)
                        }
                    },1000)
                    
                })
            }
        })
    },
    // 收藏/取消收藏
    goodsCollect(){
       let params = {
           goods_id:this.$route.query.goods_id
       }
        http.goodsCollect(params).then(value=>{
            if(value.code == 200){
                this.goodsInfo()
            }
        })
    },
    buy(buyType,group_id){
        // 创建拼团 1创建 2参与 0不创建
        this.$router.push({
            path:'/shopMall/goodsConfirmOrder',
            query:{
                goodDetails:JSON.stringify(this.goodsDetails),
                buyType,
                group_id
            }
        })
    },
    // 倒计时
    countTime(end){
        //获取当前时间  
        var date = new Date();  
        var now = date.getTime();  
            //设置截止时间  
            
             //时间差  
        var leftTime = end - now;
    //定义变量 d,h,m,s保存倒计时的时间  
        var h,m,s;  
        if (leftTime>=0) {   
            h = Math.floor(leftTime/1000/60/60%24);  
                m = Math.floor(leftTime/1000/60%60);  
            s = Math.floor(leftTime/1000%60);  

            return    `${h}:${m}:${s}`             
        }  else{
            return 
        }
    },
    // 参团
    joinTeam(item){
        if(item.user_id == this.$store.state.uid){
            this.$message.warning('不可参与自己创建的拼团')
            return
        }
        this.buy(2,item.id)
    },
    //   分享
      shareQQ(){
          let url = window.location.href
          let picurl = `${this.$url}${this.goodsDetails.cover}`
          var shareqqzonestring = 'http://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?summary=' + this.goodsDetails.name + '&url=' + url + '&pics=' + picurl;
          window.open(shareqqzonestring);
      },
    //   微博
      sharewb(){
          let url = window.location.href
          let picurl = `${this.$url}${this.goodsDetails.cover}`
          console.log(picurl,url,'sadsa',this.goodsDetails.name)
          var sharesinastring = 'http://v.t.sina.com.cn/share/share.php?title=' + this.goodsDetails.name + '&url=' + url + '&content=utf-8&sourceUrl=' + url + '&pic=' + picurl;
          window.open(sharesinastring);
      },
  },
  created(){
      this.goodsInfo();
      this.goodsComments()
  },
  components: {

  }
 }
</script>

<style scoped lang="scss">
.my_container{
        width:1200px;
        margin:0 auto;
        margin-bottom: 80px;
        .my_page_tit{
            padding:30px 0;
            color:#666;
            font-size:12px;
        }
        .mall_details{
            background:#fff;
            padding:25px;
            display:flex;
            .mall_img{
                width:396px;
                height:372px;
                border-radius:6px;
                margin-right:36px;
            }
            .mall_info{
                .mall_info_title{
                    color:#222;
                    font-size:24px;
                    margin-bottom: 20px;
                }
                .mall_price{
                    margin-bottom:40px;
                    .mall_now_price{
                        color:#CF0A00;
                        font-weight: bold;
                        font-size:26px;
                        margin-right:10px;
                    }
                    .mall_line_price{
                        color:#999999;
                        font-size:16px;
                        text-decoration:line-through
                    }
                }
                .mall_author_info{
                        margin-bottom: 20px;
                        font-size:13px;
                        .mall_author{
                            color:#999999;
                            margin-right:10px;
                        }
                    }
                .mall_opera_list{
                    margin-top:30px;
                    display:flex;
                    .collect_opera{
                        color:#333333;
                        font-size:14px;
                        margin-right:28px;
                        display:flex;
                        img{
                            margin-left:10px;
                            width:20px;
                            height:20px;
                        }
                    }
                    .share_opera{
                        margin-left:28px;
                        display:flex;
                        align-items: center;
                        color:#333333;
                        font-size:14px;
                        img{
                            width:20px;
                            height:20px;
                        }
                    }
                }
                .mall_btn_list{
                    margin-top:60px;
                    display:flex;
                    .mall_price_btn{
                        height:60px;
                        width:182px;
                        color:#fff;
                        text-align: center;
                        // line-height:50px;
                        display:flex;
                        justify-content: center;
                        flex-direction: column;
                        border-radius: 8px;
                        font-size:18px;
                        background:#E1410F;
                        cursor: pointer;
                    }
                    .now_buy{
                        background: #CF0A00;
                        margin-left:20px;
                        }
                }
            }
        }
        .mall_content{
            display:flex;
            justify-content: space-between;
            margin-top: 20px;
            .mall_buy_near{
                width:260px;
                padding:20px;
                padding-top:10px;
                box-sizing: border-box;
                margin-left:20px;
                background: #fff;
                color:#333;
                .title{
                    border-bottom:1px solid #DCDCDC;
                    padding-bottom: 10px;
                    color:#333333;
                    font-size:18px;
                }
                .near_buy_tips{
                    margin-top:10px;
                    font-size:14px;
                    margin-bottom: 15px;
                }
                .near_list_item{
                    
                    border-bottom: #DCDCDC 1px solid;
                    .near_list_item_top{
                        display:flex;
                        align-items: center;
                        // padding-bottom: 10px;
                        // padding-top: 10px;;
                    }
                    .last_time{
                        font-size: 14px;
                        color: #dc091d;
                        margin-left:10px;
                    }
                    &:last-child{
                        border:none
                    }
                    img{
                        width:36px;
                        height:36px;
                        border-radius:50%;
                    }
                    .near_name{
                        font-size:14px;
                        margin-left:10px;
                    }
                    .join_team_btn{
                        line-height: 25px;
                        width:60px;
                        height:25px;
                        background: #CF0A00;
                        border-radius: 6px;
                        color:#fff;
                        text-align: center;
                        margin-left:auto;
                        font-size: 14px;
                        cursor: pointer;
                    }
                }
            }
            .mall_goods_info{
                flex:1;
                background:#fff;
                padding:0 30px;
                .class_nav{
                    line-height:66px;
                }
                /deep/ .el-tabs__item{
                    color:#666;
                    font-size:18px;
                    padding-left:45px;
                }   
                /deep/ .el-tabs__item.is-active{
                            color: #333333;
                        }
                        /deep/ .el-tabs__active-bar{
                            background-color: #CE0A00;
                        }
                        /deep/ .el-tabs__item:hover {
                            color: #333333;
                            cursor: pointer;
                        }
                .goods_content{
                    margin-top:30px;
                    .module_title{
                        margin-top:30px;
                        padding-left:20px;
                        position: relative;
                        color:#242424;
                        font-size:18px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        span{
                            position: absolute;
                            width: 6px;
                            height: 20px;
                            background: #DC091D;
                            left:0%;
                            top:50%;
                            margin-top:-10px;
                        }
                        .more{
                            color:#666666;
                            font-size:14px;
                        }
                    }
                    .goods_appraise{
                        .goods_appraise_item{
                            padding:20px
                        }
                    }
                    .evaluate_gaishu{
                        font-size:14px;
                        color:#666666;
                        margin-top:19px;
                        margin-bottom: 30px;
                    }
                    .evaluate_tags{
                        display: flex;
                        margin-top: 20px;
                        span{
                            background: #F2F4F5;
                            padding:10px 20px;
                            color:#666666;
                            font-size:14px;
                            margin-right:10px;
                            border-radius: 8px;
                        }
                    }
                }
            }
            .evaluate_list{
            // max-height: 600px;
            // padding-right:15px;
            // max-height: 1000px;
            // overflow: auto;
            // &::-webkit-scrollbar{
            // width:10px;
            // height:10px;
            // /**/
            // }
            // &::-webkit-scrollbar-track{
            // background: rgb(239, 239, 239);
            // border-radius:2px;
            // }
            // &::-webkit-scrollbar-thumb{
            // background: #bfbfbf;
            // border-radius:10px;
            // }
            // &::-webkit-scrollbar-thumb:hover{
            // background: #ccc;
            // }
            // &::-webkit-scrollbar-corner{
            // background: #179a16;
            // }
            .evaluate_list_item{
                padding-top: 15px;
                padding-bottom: 20px;
                display: flex;
                border-bottom: 1px solid #E2E2E2;
                color:#666;
                .evaluate_header{
                    margin-right:16px;
                    width:55px;
                    height:55px;
                    border-radius: 50%;
                }
                .evaluate_details{
                    flex:1;
                    .evaluate_name{
                        margin-bottom: 14px;
                    }
                    .evaluate_ontent{
                        margin-bottom: 16px;
                    }
                    .evaluate_info{
                        display:flex;
                        justify-content: space-between;
                        .evaluate_icon{
                            display: flex;
                            align-items: center;
                            img{
                                width:14px;
                                height:14px;
                                cursor: pointer;
                            }
                            span{
                                margin:0 7px
                            }
                        }
                    }
                }
            }
            .p_evaluate_list_item:last-child{
                .evaluate_list_item{
                    border-bottom: none;
                }
            }
            .evaluate_list_children{
                    padding-left:70px;
                    .evaluate_list_children_item{
                        padding-top: 15px;
                        padding-bottom: 20px;
                        display: flex;
                        border-bottom: 1px solid #E2E2E2;
                        color:#666;
                        .evaluate_header{
                            margin-right:16px;
                            width:55px;
                            height:55px;
                            border-radius: 50%;
                        }
                        .evaluate_details{
                        flex:1;
                        .evaluate_name{
                            margin-bottom: 14px;
                        }
                        .evaluate_ontent{
                            margin-bottom: 16px;
                        }
                        .evaluate_info{
                            display:flex;
                            justify-content: space-between;
                        }
                    }
                    }
                }
            }
        }
}
 /deep/ .el-popover__reference-wrapper{
     display:flex;
 }
 /deep/ .el-carousel__container{
     height:100%
 }
 .evaluate_list{
            // max-height: 600px;
            overflow: auto;
            .evaluate_list_item{
                padding-top: 15px;
                padding-bottom: 20px;
                display: flex;
                border-bottom: 1px solid #E2E2E2;
                color:#666;
                .evaluate_header{
                    margin-right:16px;
                    width:55px;
                    height:55px;
                    border-radius: 50%;
                }
                .evaluate_details{
                    flex:1;
                    .evaluate_name{
                        margin-bottom: 14px;
                    }
                    .evaluate_ontent{
                        margin-bottom: 16px;
                    }
                    .evaluate_info{
                        display:flex;
                        justify-content: space-between;
                        .evaluate_icon{
                            display: flex;
                            align-items: center;
                            img{
                                width:14px;
                                height:14px;
                                cursor: pointer;
                            }
                            span{
                                margin:0 7px
                            }
                        }
                    }
                }
            }
            .evaluate_list_children{
                padding-left:70px;
                .evaluate_list_children_item{
                    padding-top: 15px;
                    padding-bottom: 20px;
                    display: flex;
                    border-bottom: 1px solid #E2E2E2;
                    color:#666;
                    .evaluate_header{
                        margin-right:16px;
                        width:55px;
                        height:55px;
                        border-radius: 50%;
                    }
                    .evaluate_details{
                    flex:1;
                    .evaluate_name{
                        margin-bottom: 14px;
                    }
                    .evaluate_ontent{
                        margin-bottom: 16px;
                    }
                    .evaluate_info{
                        display:flex;
                        justify-content: space-between;
                    }
                }
                }
            }
        }
        .evaluate_input{
            height: 119px;
            border: 1px solid #CCCCCC;
            textarea{
                height: 100%;
                width: 100%;
                border:none;
                background-color: transparent;
                resize:none;
            }
        }
        .evaluate_operation{
            display:flex;
            justify-content: space-between;
            margin-top:15px;
            .evaluate_submit{
                width: 80px;
                height: 30px;
                background: #2D81E4;
                border-radius: 8px;
                text-align: center;
                line-height: 30px;
                color:#fff;
                font-size: 14px;
            }
        }
        .evaluate_gaishu{
            font-size:14px;
            color:#666666;
            margin-top:19px;
            margin-bottom: 30px;
        }
        .evaluate_tags{
            display: flex;
            margin-top: 20px;
            span{
                background: #F2F4F5;
                padding:10px 20px;
                color:#666666;
                font-size:14px;
                margin-right:10px;
                border-radius: 8px;
            }
        }
        .evaluate_teacher_info{
            display:flex;
            align-items: center;
            margin-top: 30px;
            img{
                width:80px;
                height: 80px;
                border-radius: 50%;
                margin-right:24px;
            }
            .evaluate_teacher_data{
                display: flex;
                justify-content: center;
                flex-direction: column;
                span{
                    margin-bottom: 5px;
                }
            }
            .evaluate_teacher_page{
                margin-left:auto;
                background:#EE4433;
                width:126px;
                line-height:40px;
                height: 40px;
                background: #EE4433;
                border-radius: 9px;
                color:#fff;
                text-align: center;
                font-size:12px;
                cursor: pointer;
            }
        }
</style>
