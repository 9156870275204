<template>
  <div class="my_container">
      <p class="my_page_tit">
          <router-link to="/index">首页</router-link> > 课程
      </p>
      <div class="search_condition">
          <ul>
              <li class="search_row">
                  <div class="search_type">
                      类型
                  </div>
                  <div :class="['currency','search_all',{'active':cate == 0}]" @click="selectType(0,1)">
                      <span>全部</span>
                  </div>
                  <div  class="search_row_list">
                      <div :class="['currency','search_target',{'active':item.id == cate}]" v-for="(item,index) in courseCaseList" :key="index" @click="selectType(item.id,1)">
                      
                        <span>{{item.name}}</span>
                    </div>
                  </div>
                  
              </li>
              <li class="search_row">
                  <div class="search_type">
                      排序
                  </div>
                  <div :class="['currency','search_all',{'active':sort == 0}]" @click="selectType(0,2)">
                      <span>全部</span>
                  </div>
                  <div  class="search_row_list">
                      <div :class="['currency','search_target',{'active':item.id == sort}]" v-for="(item,index) in sortList" :key="index" @click="selectType(item.id,2)">
                        <span>{{item.name}}</span>
                    </div>
                  </div>
                  
              </li>
              <li class="search_row">
                  <div class="search_type">
                      班次
                  </div>
                  <div :class="['currency','search_all',{'active':teacher_id == 0}]" @click="selectType(0,3)">
                      <span>全部</span>
                  </div>
                  <div class="search_row_list">
                      <div :class="['currency',{'hover_bg':item.child.length},'search_target',{'active':class_id.includes(item.id)}]" v-for="(item,index) in courseClassList" :key="index" @click="selectType(item.id,3)">
                            <span >{{item.name}}</span>
                            <i class="el-icon-caret-bottom" v-if="item.child.length"></i>
                                <div class="class_child_list" v-if="item.child.length">
                                    <div :class="['class_item_child',{'actives': class_id.includes(itemChild.id)}]" v-for="(itemChild,childIndex) in item.child" :key="`${childIndex}--`" @click.stop="selectType(item.id,3,itemChild.id)">
                                            <span>{{itemChild.name}}</span>
                                    </div>
                                </div>
                        </div>
                  </div>
                  
              </li>
              <li class="search_row">
                  <div class="search_type">
                      老师
                  </div>
                   <div :class="['currency','search_all',{'active':teacher_id == 0}]" @click="selectType(0,4)">
                      <span>全部</span>
                  </div>
                  <div class="search_row_list">
                      <div :class="['currency','search_target',{'active':item.id == teacher_id}]" v-for="(item,index) in teachersList" :key="index" @click="selectType(item.id,4)">
                        <span>{{item.name}}</span>
                    </div>
                  </div>
                  
              </li>
              <li class="search_row">
                  <div class="search_type">
                      年份
                  </div>
                 <div :class="['currency','search_all',{'active':year_id == 0}]" @click="selectType(0,5)">
                      <span>全部</span>
                  </div>
                  <div  class="search_row_list">
                      <div :class="['currency','search_target',{'active':item.id == year_id}]" v-for="(item,index) in yearList" :key="index" @click="selectType(item.id,5)">
                        <span>{{item.name}}</span>
                    </div>
                  </div>
                  
              </li>
              <li class="search_row">
                  <div class="search_type">
                      价格
                  </div>
                  <div  class="search_row_list">
                      <div :class="['currency',{'search_all':index == 0},{'search_target':index != 0},{'active':item.id == price}]" v-for="(item,index) in priceList" :key="index" @click="selectType(item.id,6)">
                        <span>{{item.name}}</span>
                    </div>
                  </div>
                  
              </li>
          </ul>
          
      </div>
      <classList
      :dataList="courseList"
      ></classList>
      <div class="noData" v-if="!courseList.length">
          暂无数据
      </div>
      <pagition 
        :count="count"
        pageSize="12"
        @currentChange='currentChange'
      >
      </pagition>
       
  </div>
</template>

<script>
import classList from '@/components/classList/classList'
import * as http from '@/assets/api/http.js'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
        yearList:[],
        courseClassList:[],
        teachersList:[],
        courseCaseList:[],
        cate:0,
        sort:0,
        class_id:[0],
        teacher_id:0,
        year_id:0,
        price:0,
        page:1,
        priceList:[
            {
                name:'全部',
                id:0
            },
            {
                name:'免费',
                id:1
            },
            {
                name:'50以下',
                id:2
            },
            {
                name:'50-100',
                id:3
            },
            {
                name:'100-500',
                id:4
            },
            {
                name:'500-1000',
                id:5
            },
            {
                name:'1000以上',
                id:6
            },
        ],
        sortList:[
            // {
            //     name:'默认',
            //     id:0
            // },
            {
                name:'最新发布',
                id:1
            },
            {
                name:'人气最高',
                id:2
            },
            {
                name:'价格最低',
                id:3
            },
            {
                name:'价格最高',
                id:4
            }
        ],
        // 课程列表
        courseList:[],
        page:1,
        count:0,
        keyword:'',
        classId:0           //判断CLASS是否在一级菜单已选
    };
  },
  methods: {
      selectType(index,type,childid=''){
        type==1&& (this.cate = index);
        type==2&& (this.sort = index);
        // type==3&& (this.class_id[0] = index);
        type==4&& (this.teacher_id = index);
        type==5&& (this.year_id = index);
        type==6&& (this.price = index);
        if(type == 3){
            if(childid){
                if(this.classId != null){
                    this.class_id.length=0;
                    this.classId = null;
                }
                if(this.class_id.indexOf(childid)>-1){
                    this.class_id.splice(this.class_id.indexOf(childid),1)
                    if(!this.class_id.length){this.class_id = [0]}
                    this.classId = 0;
                }else{
                    this.class_id.push(childid)
                }
            }else{
                this.class_id.length=0;
                this.courseClassList.forEach(v=>{
                    if(v.id == index){
                        if(v.child.length>0){
                            v.child.forEach(el=>{
                                this.class_id.push(el.id)
                            })
                        }else{
                            this.class_id.push(index)
                        }
                    }
                })
                
                
                this.classId = index;
            }
        }
        
        this.getCourseList()
      },
    //   selectAll(id,type){
    //     type==1&& (this.cate = 0);
    //     type==2&& (this.sort = 0);
    //     type==3&& (this.class_id = 0);
    //     type==4&& (this.teacher_id = 0);
    //     type==5&& (this.year_id = 0);
    //     type==6&& (this.price = 0);
    //     this.getCourseList()
    //   },
      currentChange(val){
          console.log(val)
          this.page=val;
          this.getCourseList()
      },
    //   获取课程年份
    courseYear(){
      http.courseYear().then(value=>{
            if(value.code == 200){
              
              this.yearList=value.data;
            //   this.yearList.unshift({
            //       name:'全部',
            //       id:0
            //   })
            }else{
              this.$message.error(value.msg)
            }
        })
    },
    // 获取课程班次
    courseClasses(){
      http.courseClasses().then(value=>{
            if(value.code == 200){
              
              this.courseClassList=value.data;
            //   this.courseClassList.unshift({
            //       name:'全部',
            //       id:0,
            //       child:[]
            //   })
            }else{
              this.$message.error(value.msg)
            }
        })
    },
    // 获取课程分类
    courseCase(){
      http.courseCase().then(value=>{
            if(value.code == 200){
              
              this.courseCaseList=value.data;
            //   this.courseCaseList.unshift({
            //       name:'全部',
            //       id:0
            //   })
            }else{
              this.$message.error(value.msg)
            }
        })
    },
    // 获取教室列表
     teacherList(){
      http.teacherList().then(value=>{
            if(value.code == 200){
              
              this.teachersList=value.data;
            //   this.teachersList.unshift({
            //       name:'全部',
            //       id:0
            //   })
            }else{
              this.$message.error(value.msg)
            }
        })
    },
    // 根据条件查询课程列表
    getCourseList(keyword = this.keyword){
      let that = this;
      let formdata = new FormData();
    //   分类
      formdata.append('cate',that.cate);
      formdata.append('sort',that.sort);
      formdata.append('class_id',that.class_id.join(','));
      formdata.append('teacher_id',that.teacher_id);
      formdata.append('year_id',that.year_id);
      formdata.append('price',that.price);
      formdata.append('keyword',keyword);
      formdata.append('p',that.page);
      http.courseList(formdata).then(value=>{
            if(value.code == 200){
                this.count = value.count;
                this.courseList = value.data;
            }else{
              this.$message.error(value.msg)
            }
        })
    },
  },
  created() {
      this.keyword = this.$route.query.keyword || '';
      this.courseYear();
      this.courseClasses();
      this.courseCase();
      this.teacherList();
      this.getCourseList();
      
  },
computed:{
    ...mapState(['searchFlag'])
  },
watch:{
    searchFlag:{
        handler:function(val){
            this.getCourseList(val)
        }
    }
},
  components: {classList},
}
</script>

<style scoped lang="scss" scoped>
.my_container{
        width:1200px;
        margin:0 auto;
        margin-bottom: 80px;
        .my_page_tit{
            padding:30px 0;
            color:#666;
            font-size:12px;
        }
        .search_condition{
            background:#fff;
            padding:20px;
            color:#333333;
            font-size:14px;
            margin-bottom: 20px;
            .search_row{
                margin-bottom: 10px;
                display: flex;
                flex-wrap: wrap;
                .search_type{
                    margin-right:20px;
                }
                .search_row_list{
                    flex:1;
                    display: flex;
                    flex-wrap: wrap;
                }
                .currency{
                    cursor: pointer;
                    span{
                        padding:5px;
                    }
                }
                .hover_bg{
                    &:hover{
                        background:rgb(242,242,242);
                        border:1px solid rgb(242,242,242);
                    }
                }
                .search_target{
                    width:135px;
                    position: relative;
                    border:1px solid transparent;
                    .class_child_list{
                        position: absolute;
                        width:100%;
                        left:-1px;
                        top:22px;
                        background:#fff;
                        z-index:500;
                        display:none;
                        padding-top:10px;
                        border:1px solid rgb(242,242,242);
                        color:#333333;
                        border-top: 0;
                        .class_item_child{
                            line-height:2;
                            cursor: pointer;
                            border-bottom:1px solid rgb(242,242,242);
                            &:hover{
                                // background:#ececec
                                // color:#E1410F;   
                            }
                            &.actives{
                                // background:#fff;
                                color:#E1410F;              
                            }
                        }
                    }
                    &:hover{
                        // background:rgb(242,242,242);
                        
                        color:#E1410F;
                        
                        .class_child_list{
                            display:block;
                        }
                    }
                }
                .active{
                    color:#E1410F;
                    // span{
                    //     background:#E1410F;
                    //     color:#fff;
                    //     border-radius:3px;
                    // }
                    
                }
                .search_target{

                }
                .search_all{
                    height:22px;
                    line-height:22px;
                    border-radius: 3px;
                    background:#DEDEDE;
                    text-align: center;
                    margin-right:20px;
                    &.active{
                        background:#E1410F;
                        color:#fff;
                    }
                }
            }
            
        }
}
.noData{
    width:100%;
    height:370px;
    display:flex;
    justify-content: center;
    align-items: center;
}

</style>