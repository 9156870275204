<template>
  <div class="my_container">
      <p class="my_page_tit">
          <router-link to="/index">首页</router-link> > <router-link to="/curriculum">课程</router-link> >     {{details.name}}
      </p>
      <div class="class_name">
            {{details.name}}
      </div>
      <div class="video_content">
          <div class="video_player" v-loading="loading">
              <video-player  class="video-player vjs-custom-skin" style="width: 100%; height:100%; object-fit: fill"
                        ref="videoPlayer"
                        :playsinline="true"
                        :options="videoPlayerOptions"
                        @timeupdate="onPlayerTimeupdate"
            ></video-player>
            <div class="bg_Pause" v-if="pauseFlag"></div>
            <div class="danmu">
                <PaoMaDeng :delay="0.5" :speed="100" :content="screenList">
                    <span v-for="(item, index) in screenList" :key="index" :style="{color:item.color,'marginRight':'100px','position':'relative','top':random(20,1)+'px'}">{{item.text}}</span>
                </PaoMaDeng>
                <div class="userDanmu">
                    当前用户为{{personInfo.nickname}}
                </div>
            </div>
          </div>
          <div class="video_right">
              <h3 class="video_name">{{details.name}}({{videoIndex+1}}/{{menuList.length}})</h3>
              <ul class="video_list">
                  <li :class="['video_list_item',{'active':index == videoIndex}]" v-for="(item,index) in menuList" :key="index" @click="selectVideo(item,index)">
                      <p>{{index+1}}.{{item.name}}</p>
                      <div class="video_info">
                          <span v-if="details.is_free != 1">可观看{{item.look_num}}次</span>
                          <span v-if="details.is_free == 1">可免费观看无限次</span>
                          <span v-if="details.is_study == 1">已学完</span>
                          <span v-if="details.is_study != 1">未学完</span>
                      </div>    
                  </li>
              </ul>
          </div>
          
      </div>
      <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="20%"
        append-to-body
        center
        top="20%"
        >
        <div style="text-align:center">试看已结束，是否购买</div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="cancelVideo" size="mini" style="margin-right:20px;">取 消</el-button>
            <el-button type="primary" @click="toBuy" size="mini">去购买</el-button>
        </span>
      </el-dialog>
  </div>
</template>

<script>
import PaoMaDeng from 'heyushuo-marquee'
import * as http from '@/assets/api/http.js'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
        menuList:[],
        details:{},
        currentMenu:{},
        videoIndex:0,
        fileType:'mp4',
        posterUrl:'',
        look_second:'',
        loading:false,
        dialogVisible:false,
        pauseFlag:false,   //停止播放后的遮罩
        isBuy:'',
        screenList:[],
        type:'video/mp4',
    };
  },
  computed:{
      ...mapState(['personInfo']),
      videoPlayerOptions () {
        const videoPlayerOptions = {
          playbackRates: [0.75, 1.0, 1.25, 1.5,2.0], //播放速度
          autoplay: true, // 是否自动播放。
          muted: false, // 是否静音播放，默认情况下将会消除任何音频。
          loop: false, // 是否循环播放。
          preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
          language: 'zh-CN',
          aspectRatio: '3:1', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
          fluid: true, // 是否流体从而按比例缩放以适应其容器。
          flash:{hls:{withCreadentials:true}},//可以播放rtmp视频
          html5:{hls:{withCreadentials:true}},//可以播放m3u8视频
          sources: [
              {
                type: this.type, // 类型https://d2zihajmogu5jn.cloudfront.net/bipbop-advanced/bipbop_16x9_variant.m3u8
                src: this.currentMenu.video
            },
            // {
            //     type: "video/mp4", // 类型https://d2zihajmogu5jn.cloudfront.net/bipbop-advanced/bipbop_16x9_variant.m3u8
            //     src: this.currentMenu.video
            // },
          ],
          poster: this.posterUrl, // 封面地址
          width: '100%',
          notSupportedMessage: '此视频暂无法播放...', // 当无法播放时允许覆盖Video.js，显示的默认信息。
          controlBar: {
            timeDivider: true,
            durationDisplay: true,
            remainingTimeDisplay: false,
            fullscreenToggle: true
          }
        }
        return videoPlayerOptions
      }
  },
  methods: {
      onPlayerTimeupdate(player) {
        this.look_second = player.cache_.currentTime;
        if(this.look_second >= 180 && this.isBuy != 1 && this.details.is_free != 1){
            this.$refs.videoPlayer.player.pause();
            this.dialogVisible = true;
            return
        }
        // 记录观看时间
        this.look_second = player.cache_.currentTime;
      },
      cancelVideo(){
          this.dialogVisible = false;
          this.pauseFlag = true;
      },
    //   获取随机数
    random(min, max) {
 
        return Math.floor(Math.random() * (max - min)) + min;
    
    },
      selectVideo(item,index){
        //   未登录禁止切换
        if(!this.$store.state.token){
            this.$message.warning('请先登录进行更多操作')
            this.pauseFlag = true;
        }
          if(this.pauseFlag){return}
          if(item.look_num == 0){
              this.$message.warning('当前视频已无播放次数，请选择其他视频')
              return
          }
          this.currentMenu = item;
          this.videoIndex = index;
        //   上传学习进度
        this.studyRecord()
      },
    //   分钟转换为小时
    ChangeHourMinutestr(str) {
        if (str !== "0" && str !== "" && str !== null) {
         return ((Math.floor(str / 60)).toString().length < 2 ? "0" + (Math.floor(str / 60)).toString() : 
            (Math.floor(str / 60)).toString()) + ":" + ((str % 60).toString().length < 2 ? "0" + (str % 60).toString() : (str % 60).toString());
        }else{
            return "";
        }
    },
    //将侦听器绑定到组件的就绪状态。与事件监听器的不同之处在于，如果ready事件已经发生，它将立即触发该函数。。
      playerReadied(player) {
        //console.log('example player 1 readied', player);
      },
      // 获取课程目录
    courseMenu(){
        this.loading = true;
        let that = this;
        let formdata = new FormData();
        //   分类
        formdata.append('uid',that.$store.state.uid);
        formdata.append('token',that.$store.state.token);
        formdata.append('course_id',that.$route.query.course_id);
        formdata.append('type',2);
       return http.courseMenu(formdata).then(value=>{
                if(value.code == 200){
                    this.menuList=value.data;
                    this.loading = false;
                    return this.menuList
                }else{
                this.$message.error(value.msg)
                }
            })
    },
    // 上传学习进度
    studyRecord(){
        let that = this;
        let formdata = new FormData();
        //   分类
        formdata.append('uid',that.$store.state.uid);
        formdata.append('token',that.$store.state.token);
        formdata.append('course_id',that.$route.query.course_id);
        formdata.append('menu_id',that.currentMenu.id);
        formdata.append('look_second',that.look_second);
        // 端 1 APP端 2 PC端
        formdata.append('type',2);
        http.studyRecord(formdata).then(value=>{
                if(value.code == 200){
                   this.courseMenu()
                }else{
                this.$message.error(value.msg)
                }
            })
    },
    // 去购买
    toBuy(){
        if(!this.$store.state.token){
            this.$router.push({
                path:'/login'
            })
            return
        }
        this.$router.push({
              path:"/shopMall/confirmOrder",
              query:{
                  goods_id:this.details.info.id,
                  price:this.details.info.price,
                  goods_type:2,
                  single_price:this.details.info.price,
                  goodsInfo:decodeURIComponent(JSON.stringify(this.details.info))
              }
          })
    },
    // 弹幕列表
    screen(){
        let that = this;
        let formdata = new FormData();
        //   分类
        formdata.append('course_id',that.$route.query.course_id);

        http.screen(formdata).then(value=>{
                if(value.code == 200){
                    this.screenList = value.data;
                }else{
                this.$message.error(value.msg)
                }
            })
    }
  },
  created() {
      this.details = JSON.parse(decodeURIComponent(this.$route.query.goodsInfo));
      this.isBuy = this.$route.query.isBuy
      this.screen()
    //   this.menuList = JSON.parse(decodeURIComponent(this.$route.query.menuList));
      this.courseMenu().then(value=>{
          this.videoIndex = this.$route.query.videoIndex;
          this.currentMenu = this.menuList[this.$route.query.videoIndex];
      })
      
  },
  watch:{
      currentMenu:{
          deep:true,
          handler:function(val){
              if(val){
                  if(val.look_num == 0){
                      this.$message.warning('当前视频已无播放次数，请选择其他视频')
                      val.video="";
                      return
                  }
                  let arr = val.video.split('.')
                if(arr[arr.length-1] == 'mp4'){
                    this.type = 'video/mp4'
                }else{
                    this.type = 'application/x-mpegURL'
                }
              }
              
          }
      }
  },    
  components: {PaoMaDeng},
}
</script>
<style>
     @keyframes example {
  0%   {left:100%; top:0px;}
  50% {left:-0%; top:0px;}
  100% {left:-100%; top:0px;}
  /* 100% {left:-100%; top:0px;} */
}
                .userDanmu{
                        width:100%;
                        height:100%;
                        background:transparent;
                        color:#DC091D;
                        position:absolute;
                        left:-100%;
                        top:0;
                        /* animation-name: example;
                        animation-duration: 5s; */
                        animation: example 60s linear 30s infinite ;
                    }
</style>
<style scoped lang="scss">
                   
.my_container{
        width:1200px;
        margin:0 auto;
        margin-bottom: 80px;
        .my_page_tit{
            padding:30px 0;
            color:#666;
            font-size:12px;
        }
        .class_name{
            color:#333333;
            font-size:18px;
        }
        .video_content{
            display: flex;
            .video_player{
                width:920px;
                height:575px;
                margin-right:20px;
                position: relative;
                .bg_Pause{
                    position: absolute;
                    width:100%;
                    height:100%;
                    top:0;
                    left:0;
                    z-index:999
                }
                .danmu{
                    position: absolute;
                    width:100%;
                    height:10%;
                    top:0;
                    left:0;
                    z-index:555;
                    background:transparent;
                    color:#fff;
                    overflow: hidden;
                    .wrap{
                        height:100%;
                        background:transparent;
                    }

                    
                }
            }
            .video_right{
                flex:1;
                overflow: auto;
                padding:20px;
                padding-left:0;
                padding-right:0;
                height:575px;
                overflow: auto;
                background-color: #fff;;
                .video_name{
                    color: #333333;
                    font-size: 12px;
                    margin-bottom: 18px;
                }
                .video_list{
                    .video_list_item{
                        padding:20px;
                        color:#333333;
                        font-size:12px;
                        cursor: pointer;
                        .video_info{
                            display: flex;
                            justify-content: space-between;
                        }
                    }
                    .active{
                        background:#F0F5FA;
                        color:rgb(38,159,242)
                    }
                }
            }
        }
}
.video-player{
    .vjs-custom-skin > .video-js{
        height:100% !important;
    }
}
.video-js{
    height:100% !important;
}
.dialog_model{
    background:#000
}
</style>