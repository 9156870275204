<template>
  <div>
       <ul>
           <li class="nav_list" v-for="(item,index) in dataList" :key="index" @click="toDetails(item)">
               <div class="list_con">
                   <div class="list_title">
                       {{item.title}}
                   </div>
                   <div class=" news_jieshao">
                        {{item.intro}}
                    </div>
                   <div class="news_info">
                       <span>{{timestampToTime(item.created_at*1000)}}</span>
                       <span>阅读：{{item.look_count}}</span>
                       <!-- <span @click.stop="newCollectsList(item.id)" style="padding-top:5px;">
                           <img  src="../../assets/image/sc.png" alt="">
                           <img src="../../assets/image/sc_active.png" alt="">
                        </span> -->
                   </div>
               </div>
               <img :src="item.cover_pc | fixImg" alt="">
           </li>
       </ul>
  </div>
</template>

<script>
import * as http from '@/assets/api/http.js'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
        dataList:[]
    };
  },
  methods: {
      newsList(){
        let params = {
            cate_id:this.cate_id
        }
     return http.newsList(params).then(value=>{
            if(value.code == 200){
                this.dataList = value.data;
            }
        })
    },
    toDetails(item){
        this.$router.push({
            path:'/newsDetails',
            query:{
                'news_id':item.id
            }
        })
    }
  },
  created() {
      this.newsList()
  },
  mounted() {

  },
  components: {},
}
</script>

<style scoped lang="scss">
@import '@/assets/style/minix.scss';
.nav_list{
    display:flex;
    justify-content: space-between;
    border-bottom: 1px solid #DCDCDC;
    padding: 30px 20px;
    .list_con{
        flex:1;
        // display:flex;
        // flex-direction: column;
        height: 140px;
        // justify-content: space-between;
        cursor: pointer;
    }
    .list_title{
        color:#333333;
        font-size:20px;
    }
    .news_jieshao{
                    margin-bottom:28px;
                    color:#666666;
                    @include sinleline();
                    height:50px;
                    margin-top:10px;
                }
    img{
        width: 207px;
        height: 140px;
        border-radius: 8px;
        margin-left:20px
    }
    .news_info{
        color:#666666;
        font-size:14px;
        display:flex;
        align-items: center;
        span{
            margin-right:40px;
            img{
                width:20px;
                height:20px;
            }
        }
    }
}
</style>