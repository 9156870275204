<template>
  <div>
      <!-- 轮播 -->
    <el-carousel height="450px" style="min-width:1200px;">
        <el-carousel-item v-for="(item,index) in swiperList" :key="index" class="index_swiper">
            <img :src="item.cover | fixImg" alt="" @click="swiperLink(item)">
        </el-carousel-item>
    </el-carousel>
    <div class="container">
        <!-- 名师推荐 -->
        <div class="module_title">
            <span></span>
            名师推荐
        </div>
        <teacherList
            :dataList="recoTeachersList"
        ></teacherList>
        <!-- 热门课程  -->
        <div class="module_title">
            <span></span>
            <p>热门课程</p>
            <p class="more" @click="toPath('/curriculum')">更多精彩课程</p>
        </div>
        <classList
            :dataList="hotClassList"
        ></classList>

        <!-- 资讯 -->
        <div class="module_title">
            <span></span>
            <p>资讯</p>
            <!-- <p class="more">查看全部</p> -->
        </div>
        <ul class="news_list">
            <li class="news_list_item" v-for="(item,index) in newsList" :key="index" @click="zixunDetails(item)">
                <div class="news_details">
                    <div class="news_title">
                       {{item.title}}
                    </div>
                    <div class=" news_jieshao">
                        {{item.intro}}
                    </div>
                    <div class="news_info">
                        <span>{{timestampToTime(item.created_at*1000)}}</span>
                        <span>阅读：{{item.look_count}}</span>
                    </div>
                </div>
                <img :src="item.cover_pc | fixImg" alt="">
            </li>
            <li class="news_list_item" style="background:transparent;height:0;margin-bottom:0"></li>
        </ul>
    </div>
    
  </div>
</template>

<script>
import classList from '@/components/classList/classList'
import teacherList from '@/components/teacherList/teacherList'
import * as http from '@/assets/api/http.js'
export default {
  data() {
    return {
        newsList:[],
        hotClassList:[],
        recoTeachersList:[],
        swiperList:[]
    };
  },
  methods: {
      toPath(path){
          this.$router.push({
              path
          })
      },
    //   获取资讯列表
    recoNews(){
        http.recoNews().then(value=>{
            if(value.code == 200){
                this.newsList = value.data;
            }
        })
    },
    // 获取课程列表
    hotCourses(){
        http.hotCourses().then(value=>{
            if(value.code == 200){
                this.hotClassList = value.data;
            }
        })
    },
    // 获取教师列表
    recoTeachers(){
        http.recoTeachers().then(value=>{
            if(value.code == 200){
                this.recoTeachersList = value.data;
            }
        })
    },
    // 轮播图列表
    bannerList(){
        let that = this;
        let formdata = new FormData();
        //   分类
        formdata.append('path',1);
        http.bannerList(formdata).then(value=>{
            if(value.code == 200){
                this.swiperList = value.data;
            }
        })
    },
    // banner跳转
    swiperLink(item){
        window.location.href = item.url;
    },
    zixunDetails(item){
        this.$router.push({
            path:'/newsDetails',
            query:{
                news_id:item.id
            }
        })
    }
  },
  created() {
      this.recoNews();
      this.hotCourses();
      this.recoTeachers();
      this.bannerList()
  },
//   mounted() {

//   },
  components: {classList,teacherList},
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/minix.scss';
.index_swiper{
    img{
        width:100%;
        height: 450px;
    }
}
.container{
    width:1200px;
    margin:0 auto;
    .module_title{
        margin-top:80px;
        margin-bottom: 15px;
        padding-left:20px;
        position: relative;
        color:#242424;
        font-size:26px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        span{
            position: absolute;
            width: 6px;
            height: 20px;
            background: #DC091D;
            left:0%;
            top:50%;
            margin-top:-10px;
        }
        .more{
            color:#666666;
            font-size:14px;
            cursor: pointer;
        }
    }
    .news_list{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .news_list_item{
            background:#fff;
            width:590px;
            border-radius:8px;
            padding:20px;
            box-sizing: border-box;
            display:flex;
            color:#666666;
            font-size:14px;
            margin-bottom: 20px;
            cursor: pointer;
            img{
                width:205px;
                height:140px;
                margin-left:30px;
            }
            .news_details{
                flex:1;
                .news_title{
                    color:#333333;
                    margin-bottom: 18px;
                    font-size: 20px;
                     @include sinleline();
                }
                .news_jieshao{
                    margin-bottom:38px;
                    @include sinleline();
                    height:42px;
                }
                .news_info{
                    display: flex;
                    justify-content: space-between;
                }
            }
        }   
    }
}

</style>