<template>
  <div class="my_order">
      <h3 class="class_title">个人资料</h3>
      <ul>
          <li class="my_data">
              <div class="info_name">
                  头像：
              </div>
              <img  v-if="!editStatus" :src="editPerson.uhead | fixImg" alt="" >
               <el-upload
                     v-if="editStatus"
                    ref="upload"
                    class="avatar-uploader"
                    action="https://jsonplaceholder.typicode.com/posts/"
                    :show-file-list="false"
                    :before-upload="beforeUpload"
                    :on-success="handleAvatarSuccess"
                    >
                    <img v-if="editStatus" :src="editPerson.uhead | fixImg" class="avatar">
                </el-upload>
          </li>
          <li class="my_data">
              <div class="info_name">
                  昵称：
              </div>
              <span v-if="!editStatus">{{editPerson.nickname}}</span>
              <el-input v-if="editStatus" placeholder="请输入昵称" size="mini" style="width:200px;border:none" v-model="editPerson.nickname" ></el-input>
          </li>
          <li class="my_data">
              <div class="info_name">
                  真实姓名：
              </div>
              <span v-if="!editStatus">{{editPerson.real_name}}</span>
              <el-input v-if="editStatus" placeholder="请输入昵称" size="mini" style="width:200px;border:none" v-model="editPerson.real_name" ></el-input>
          </li>
          <li class="my_data">
              <div class="info_name">
                  个人介绍：
              </div>
              <span v-if="!editStatus">{{editPerson.intro}}</span>
              <el-input v-if="editStatus" placeholder="请输入昵称" size="mini" style="width:200px;border:none" v-model="editPerson.intro" ></el-input>
          </li>
          <li class="my_data">
              <div class="info_name">
                  性别：
              </div>
              <span v-if="editPerson.sex == 1 && !editStatus">男</span>
              <span v-if="editPerson.sex == 2 && !editStatus">女</span>
              <span v-if="editPerson.sex == 0 && !editStatus">未知</span>
              <el-select v-if="editStatus" v-model="editPerson.sex" size="mini" placeholder="请选择">
                    <el-option
                    size="mini"
                    v-for="item in sexList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
          </li>
      </ul>
      <span class="save_btn" @click="edit" v-if="!editStatus">编辑</span>
      <span class="save_btn" @click="save" v-if="editStatus">保存</span>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import * as http from '@/assets/api/http.js'
export default {
  data() {
    return {
        imageUrl: '',
        editStatus:false,
        editPerson:{},
        sexList:[
            {
                label:'请选择',
                value:0
            },
            {
                label:'男',
                value:1
            },{
                label:'女',
                value:2
            }
        ]
    };
  },
  mounted(){
      this.editPerson = JSON.parse(JSON.stringify(this.personInfo))
      console.log(this.editPerson)
  },
  computed:{
    ...mapState(['personInfo']),
  },
  methods: {
      ...mapMutations(['saveUid','saveToken','savePersonInfo','clearInfo']),
      edit(){
          this.editStatus = true;
      },    
      save(){
          this.editStatus = false;
          this.editUdetail();
      },
      handleAvatarSuccess(res, file) {
        this.imageUrl = URL.createObjectURL(file.raw);
      },
      upLoad(){
          if(this.editStatus == true){
              this.$refs.upload.click()
          }
      },
      beforeUpload(file){
          console.log(file)
          this.upload(file)
      },
    // 文件上传
    upload(file){
        let that = this;
        let formdata = new FormData();
        formdata.append('file',file);
        formdata.append('filename',file.name);
        http.upload(formdata).then(value=>{
                if(value.code == 200){
                this.$message.success(value.msg)
                this.editPerson.uhead = value.data.path;
                }else{
                this.$message.error(value.msg)
                }
            })
    },
    // 修改个人资料
    editUdetail(){
        let that = this;
        let formdata = new FormData();
        formdata.append('uid',that.$store.state.uid);
        formdata.append('token',that.$store.state.token);
        formdata.append('uhead',that.editPerson.uhead);
        formdata.append('nickname',that.editPerson.nickname);
        formdata.append('sex',that.editPerson.sex);
        formdata.append('intro',that.editPerson.intro);
        formdata.append('real_name',that.editPerson.real_name);
        formdata.append('type',2);
        http.editUdetail(formdata).then(value=>{
                if(value.code == 200){
                this.$message.success(value.msg)
                this.getUdetail()
                }else{
                this.$message.error(value.msg)
                }
            })
    },
    // 获取个人信息
    getUdetail(){
      let that = this;
      let formdata = new FormData();
      formdata.append('uid',that.$store.state.uid);
      formdata.append('token',that.$store.state.token);
      // 端 1 APP 2 PC
      formdata.append('type',2);
      http.getUdetail(formdata).then(value=>{
            if(value.code == 200){
              this.savePersonInfo(value.data)
            }else{
              this.$message.error(value.msg)
            }
        })
    },
  },
//   created() {

//   },
//   mounted() {

//   },
  components: {},
}
</script>

<style lang="scss" scoped>
.my_order{
    padding:40px 30px;
    .class_title{
        color:#666666;
        font-size:24px;
        margin-bottom: 30px;
    }
    .my_data{
        display:flex;
        align-items: center;
        color:#333333;
        font-size:14px;
        margin-bottom: 44px;
        line-height: 28px;
        img{
            width:73px;
            height: 73px;
            border-radius: 50%;
        }
        .info_name{
            margin-right:28px;
            width:80px;
        }
        .avatar-uploader{
            width:73px;
            height: 73px;
            border-radius: 50%;
        }
    }
}
.save_btn{
    width: 95px;
    height: 40px;
    background: #E1410F;
    border-radius: 10px;
    line-height:40px;
    text-align: center;
    display:inline-block;
    font-size:16px;
    color:#fff;
    cursor:pointer;
}
</style>