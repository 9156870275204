<template>
  <div class="container">
     <div class="login_contaniner">
       <div class="login_title">
         <span class="title">登录文运法硕</span>
         <span class="btn" @click="changeLogin" v-show="showStatus == 1">登录</span>
         <span class="btn" @click="toRegisiter" v-show="showStatus == 2 ">注册</span>
         <span class="btn" @click="changeLogin" v-show="showStatus == 3 ">密码登录</span>
       </div>
       <!-- form -->
       <ul class="form">
          <li class="form_items">
            <input type="text" placeholder="手机号" v-model="phone">
          </li>
          <li class="form_items" v-show="showStatus == 1 || showStatus == 3 || showStatus == 4">
            <input type="text" placeholder="请输入验证码" v-model="code">
            <span v-loading="loading" @click="sendVerify" style="cursor:pointer" v-show="!codeShow">获取验证码</span>
            <span v-show="codeShow" style="text-align:center;padding-right:30px">{{times}}</span>
          </li>
          <li class="form_items" v-show="showStatus != 3">
            <input type="password"  :placeholder="showStatus==1?'设置密码':'请输入密码'" v-model="pwd">
          </li>
       </ul>
       <p class="tips" v-show="showStatus == 1 || showStatus == 3  || showStatus == 4">收不到验证码？联系客服：{{systemInfo.customer_tel}}</p>
       <div class="login_tips">
         <span @click="codeLogin" v-show="showStatus == 2">验证码登录</span>
         <span @click="forgetPassword" v-show="showStatus == 2">忘记密码？</span>
       </div>
       <div class="regisiter" v-show="showStatus == 1" @click="register">
         <span >注册</span>
       </div>
       <div class="regisiter" v-show="showStatus == 2 ||showStatus == 3 || showStatus == 4" @click="toLogin">
         <span>登录</span>
       </div>
     </div>
  </div>
</template>

<script>
import * as http from '@/assets/api/http.js'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
        showStatus:2,   //1注册2密码登录3验证码登录4忘记密码
        phone:'',
        code:'',
        pwd:'',
        pid:'',
        times:60,
        codeShow:false,
        loading:false,
        systemInfo:{}
    };
  },
  created(){
    this.clearInfo();
    this.getSysConfig()
  },
  methods:{
    ...mapMutations(['saveUid','saveToken','savePersonInfo','clearInfo']),
    changeLogin(){
      this.showStatus = 2
    },
    toRegisiter(){
      this.showStatus = 1
    },
    // 验证码登录
    codeLogin(){
      this.showStatus = 3
    },
    // 忘记密码
    forgetPassword(){
      this.showStatus = 4;
    },
    toRegisiter(){
      this.showStatus = 1
    },
    toPath(path){
      this.$router.push({
        path
      })
    },
    // 登录
    toLogin(){
      if(this.showStatus ==4){
        // 忘记密码
        this.forgetPwd()
      }else{
        // 登录 验证码登录
        this.login()
      }
    },
    // 点击验证码
    getCode(){
      this.codeShow = true;
      this.timer = setInterval(()=>{
        this.times--
        if(this.times===0){
          this.codeShow = false
          clearInterval(this.timer);
          this.times = 60;
        }
      },1000)
    },
    // 发送验证码
    sendVerify(){
      let that = this;
      let formdata = new FormData();
      formdata.append('phone',that.phone);
      formdata.append('type',that.showStatus == 1?1:2);
      
      this.loading = true;
      http.sendVerify(formdata).then(value=>{
        this.loading = false;
            if(value.code == 200){
              this.$message.success(value.msg)
              this.getCode()
            }else{
              this.$message.error(value.msg)
            }
        })
    },
    // 注册
    register(){
      let that = this;
      let formdata = new FormData();
      formdata.append('phone',that.phone);
      formdata.append('code',that.code);
      formdata.append('pwd',that.pwd);
      http.register(formdata).then(value=>{
            if(value.code == 200){
              this.$message.success(value.msg)
              this.login()
            }else{
              this.$message.error(value.msg)
            }
        })
    },
    // 登录
    login(){
      let that = this;
      let formdata = new FormData();
      formdata.append('phone',that.phone);
      formdata.append('code',that.code);
      formdata.append('pwd',that.pwd);
      // 登录端 1 APP 2 PC
      formdata.append('state',2);
      // 登录类型 1 密码登录 2 验证码登录
      let type;
      this.showStatus == 2?type=1:type=2;
      formdata.append('type',type);
      http.login(formdata).then(value=>{
            if(value.code == 200){
              this.$message.success(value.msg);
              this.saveUid(value.data.uid)
              this.saveToken(value.data.token)
              this.getUdetail()
              this.$router.push({
                path:'/index'
              })
            }else{
              this.$message.error(value.msg)
            }
        })
    },
    // 忘记密码登录
    forgetPwd(){
      let that = this;
      let formdata = new FormData();
      formdata.append('phone',that.phone);
      formdata.append('code',that.code);
      formdata.append('pwd',that.pwd);
      http.forgetPwd(formdata).then(value=>{
            if(value.code == 200){
              this.$message.success(value.msg);
              this.saveUid(value.data.uid)
              this.saveToken(value.data.token)
              this.getUdetail()
              this.$router.push({
                path:'/index'
              })
            }else{
              this.$message.error(value.msg)
            }
        })
    },
    // 获取个人信息
    getUdetail(){
      let that = this;
      let formdata = new FormData();
      formdata.append('uid',that.$store.state.uid);
      formdata.append('token',that.$store.state.token);
      // 端 1 APP 2 PC
      formdata.append('type',2);
      http.getUdetail(formdata).then(value=>{
            if(value.code == 200){
              this.savePersonInfo(value.data)
            }else{
              this.$message.error(value.msg)
            }
        })
    },
    // 系统设置信息
    getSysConfig(){
        http.getSysConfig().then(value=>{
            if(value.code == 200){
                this.systemInfo = value.data;
            }
        })
    },
  }
}
</script>

<style scoped lang="scss">
.login_contaniner{
  width: 440px;
  border-radius:20px;
  background:#fff;
  box-sizing: border-box;
  padding:40px;
  margin:0 auto;  
  .login_title{
    display:flex;
    justify-content: space-between;
    align-items: center;
    .title{
      color:rgb(168,168,168);
      font-size:26px;
    }
    .btn{
      font-size:16px;
      color:#1875E1;
      cursor: pointer;
    }
  }
  .form{
    margin-top:25px;
    .form_items{
      line-height:70px;
      height: 70px;
      border-bottom: 1px solid #C5C5C5;
      padding-left:40px;
      background: url("../../assets/image/home/fdj.png") left center no-repeat;
      background-size:18px 21px;
      display: flex;
      justify-content: space-between;
      color:rgb(168,168,168);
      input{
        width:50%;
        border:none;
        height: 100%;
        line-height: 70px;
        color:rgb(168,168,168);
        font-size:16px;
        background:transparent;
      }
    }
  }
  .tips{
    color:rgb(168,168,168);
    font-size:14px;
    margin-top:10px;
    cursor: pointer;
  }
  .login_tips{
    display:flex;
    justify-content: space-between;
    color:#212E3D;
    font-size:14px;
    cursor: pointer;
    margin-top:10px;
  }
  .regisiter{
    text-align: center;
    height: 56px;
    line-height: 56px;;
    background: #CF0A00;
    border-radius: 28px;
    color:#fff;
    font-size:16px;
    margin-top:24px;
    cursor: pointer;
  }
}
.container{
  width:100%;
  height: 750px;
  background: url("../../assets/image/login/login.png") left center no-repeat;
  background-size:100% 100%;
  position: relative;
  display:flex;
  justify-content: center;
  align-items: center;
}
</style>