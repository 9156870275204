<template>
 <div class="mallContainer">
     <div class="mallBanner">
          <!-- 轮播 -->
        <el-carousel height="380px" style="min-width:1200px;">
            <el-carousel-item v-for="(item,index) in swiperList" :key="index" class="index_swiper">
                <img :src="item.cover | fixImg" alt="" @click="swiperLink(item)">
            </el-carousel-item>
        </el-carousel>
     </div>
     <div class="nav_list">
         <div class="nav_list_left">
             <div class="nav_list_item"  v-for="(item,index) in navList" :key="index" @click="changeNav(index)">
                <span :class="[{'active':index == selectNav}]">{{item.name}}</span>
            </div>
         </div>
         
         <div>
             <el-input size="mini" clearable placeholder="请输入商品名称" v-model="goods_name" style="width:200px;"></el-input>
             <el-button size="mini" type="primary" @click="goodsList">搜索</el-button>
         </div>
         
     </div>
     <mallList
     v-loading="loading"
     :goodList="goodList"
     ></mallList>
     <div style="margin-bottom:20px;">
         <pagition
            :count="count"
            pageSize="12"
            @currentChange='currentChange'
        >
        </pagition>
     </div>
     
 </div>
</template>

<script>
import mallList from '@/components/mallList/mallList.vue'
import * as http from '@/assets/api/http.js'
 export default {
  name: '',
  data () {
    return {
        loading:true,
        goods_name:'',
        navList:[
            {
                name:'全部',
            },
            {
                name:'课程',
            },
            {
                name:'图书',
            },
            {
                name:'模拟试题',
            },
            {
                name:'其他',
            },
        ],
        selectNav:0,
        swiperList:[],
        page:1,
        count:0,
        goodList:[]
    }
  },
  created(){
      this.goodsCateList();
      this.bannerList();

  },
  methods: {
      // banner跳转
    swiperLink(item){
        if(!item.url){return}
        window.location.href = item.url;
    },
      //   获取资讯列表
    goodsCateList(){

        http.goodsCateList().then(value=>{
            if(value.code == 200){
                this.navList = value.data;
                let obj = {
                    name:'全部',
                    id:''
                }
                this.navList.unshift(obj)
                this.goodsList()
            }
        })
    },
    // 轮播图列表
    bannerList(){
        let that = this;
        let formdata = new FormData();
        //   分类
        formdata.append('path',7);
        http.bannerList(formdata).then(value=>{
            if(value.code == 200){
                this.swiperList = value.data;
                this.swiperList.forEach(v=>{
                    if(v.type ==3 && v.target_id != null){
                        v.url=`${this.$shareUrl}#/curriculumDetails?course_id=${v.target_id}`
                    }else if(v.type ==2 && v.target_id != null){
                        v.url=`${this.$shareUrl}#/mallDetails?goods_id=${v.target_id}`
                    }
                })
            }
        })
    },
    // 获取商品列表
    goodsList(){
        let params = {
            cate_id:this.navList[this.selectNav].id,
            p:this.page,
            goods_name:this.goods_name
        }
        http.goodsList(params).then(value=>{
            if(value.code == 200){
                this.goodList = value.data;
                this.count = value.count;
                this.loading = false;
            }
        })
    },
    
    currentChange(val){
          console.log(val)
          this.page=val;
          this.goodsList()
      },
      changeNav(index) {
        this.selectNav = index;
        this.page = 1;
        this.goodsList()
      }
    },
  components: {
      mallList
  }
 }
</script>

<style scoped lang="scss">
.mallContainer{
    width:1200px;
    margin:0 auto;
    .mallBanner{
        margin-top:20px;
        // img{
        //     width:100%;
        //     height:380px;
        // }
    }
    .nav_list{
        margin-top:30px;
        height:74px;
        line-height: 74px;
        display:flex;
        color:#222F3E;
        background:#fff;
        padding-right:20px;
        justify-content: space-between;
        .nav_list_left{
            display:flex;
        }
        .nav_list_item{
            padding-left:37px;
            margin-right:40px;
            span{
                cursor: pointer;
                padding-bottom:10px;
                &.active{
                    border-bottom: 6px solid #DC091D;
                    border-radius: 3px;
                }
            }
        }
    }
    
}
/deep/ .el-button--primary{
        background-color: #CF0A00 !important;
    border-color: #CF0A00 !important;
    &:hover{
         background-color: #CF0A00;
    border-color: #CF0A00;
    }
}
 
</style>
