<template>
  <div >
      <div class="answer_tag">
          <div class="ans_tab_list">
              <span :class="['ans_tab_list_item',{'active':index == tabIndex}]" v-for="(item,index) in tabList" :key="index" @click="changeTab(index)">
                  {{item.name}}
              </span>
          </div>
          <el-button type="primary" size="mini" style="margin-leftL20px;" @click="ask">
              我要提问
          </el-button>
      </div>
      <div class="ans_content">
          <div class="ans_total" v-if="tabIndex != 2">
                <p>总提问：
                    <span>{{total.questions_num}}</span>
                </p>
                <p>总回复：
                    <span>{{total.reply_num}}</span>
                </p>
          </div>
          <!-- 最新和热门 -->
          <div class="ques_list" v-for="(item,index) in dataList" :key="index" v-if="tabIndex !=2">
              <div class="per_info">
                  <img class="head_img" :src="item.user_uhead | fixImg" alt="">
                  
              </div>
              <div class="ques_list_con">
                  <div>
                      <p :title="item.user_name" class="head_name">{{item.user_name}}</p>
                      <p>{{item.content}}</p>
                  </div>
                  

                  <div class="teacher_ans" v-if="item.reply.user_name">
                      <span>{{item.reply.user_name}}</span>
                      回复
                      {{item.reply.content}}
                  </div>
                  <div class="ques_info" v-if="item.reply.user_name">
                      <span>提问时间：{{timestampToTime(item.reply.created_at*1000)}}</span>
                      <div class="ques_info_reply">
                          <span style="cursor: pointer;" @click="lookreply(item)" v-if="item.reply.evaluate_num-0>0">查看回复{{item.reply.evaluate_num}}</span>
                          <span class="zan">
                              <img v-show="item.reply.is_goods == 0" src="../../assets/image/dianzan.png" alt="" @click="questionGoods(item.reply.id)">
                              <img v-show="item.reply.is_goods !=0" src="../../assets/image/dianzanActive.png" alt="" @click="questionGoods(item.reply.id)">
                              <span>{{item.reply.goods_num}}</span>
                          </span>
                      </div>
                  </div>
              </div>
          </div>
          <!-- 我的提问 -->
          <div class="ques_list" v-for="(item,index) in dataList" :key="index"  v-if="tabIndex ==2">
              <div class="per_info">
                  <img class="head_img" :src="item.user_uhead | fixImg" alt="">
                  <p :title="item.user_name" class="head_name">{{item.user_name}}</p>
              </div>
              <div class="ques_list_con">
                  <p>{{item.content}}</p>
                  <div class="ques_info">
                      <span>提问时间：{{timestampToTime(item.created_at*1000)}}</span>
                      <div class="ques_info_reply">
                          <!-- <span style="cursor: pointer;" @click="lookreply(item)">查看回复{{item.evaluate_num}}</span> -->
                          <span class="zan">
                              <img src="../../assets/image/dianzan.png" alt="">
                              <span>{{item.goods_num}}</span>
                          </span>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <!-- 我要提问 -->
      <el-dialog

        :visible.sync="dialogVisible"
        width="475px"
        >
        <div>
            <p class="dialog_title">
                请输入您的疑问
            </p>
            <div class="ask_content">
                <textarea v-model="askContent" class=""></textarea>
            </div>
            
        </div>
        <div class="ask_footer">
            <span @click="cancle">取消</span>
            <span class="submit" @click="submit">提交</span>
        </div>
     </el-dialog>
     <!-- 评论列表 -->
     <el-dialog

        :visible.sync="pinglunFlag"
        width="30%"
        >
        <div>
            <ul class="pl_list_container">
                <li class="pl_list" v-for="(item,index) in pinglunList" :key="index">
                    <div class="pl_list_person">
                        <img :src="item.user_uhead |fixImg" alt="">
                    </div>
                    <div class="pl_list_content">
                        <p class="pl_list_name">
                            {{item.user_name}}
                        </p>
                        <div class="pl_list_con">
                            {{item.content}}
                        </div>
                        <div class="pl_list_info">
                            <span>提问时间：{{timestampToTime(item.created_at*1000)}}</span>
                        </div>
                    </div>
                </li>
            </ul>
            
        </div>
        <div class="my_ask">
           <el-input size="mini" placeholder="写下你的评论" v-model="huifucontent"></el-input>
           <el-button class="sendInfo" type="primary" size="mini" @click="sendInfo">发送</el-button>
        </div>
        <!-- <div class="ask_footer">
            <span @click="cancle">取消</span>
            <span class="submit" @click="submit">提交</span>
        </div> -->
     </el-dialog>
  </div>
</template>

<script>
import * as http from '@/assets/api/http.js'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
        askContent:'',
        tabList:[
            {
                name:'最新',
                id:0
            },
            {
                name:'热门',
                id:1
            },
            {
                name:'我的提问'
            }
        ],
        dataList:[],
        total:{},
        tabIndex:0,
        dialogVisible:false,
        pinglunFlag:false,
        pinglunList:[],
        pinglunReply:'',
        huifucontent:'',
        curreplyId:'',          //被回复者ID
        firstId:''              //一级评论ID
    };
  },
  methods: {
      changeTab(index){
          this.tabIndex = index;
          if(index<2){
              this.questionList()
          }else{
              this.myQuestion()
          }
      },
    //   提问列表
     questionList(){
        let params = {
            sort:this.tabIndex
        }
      http.questionList(params).then(value=>{
            if(value.code == 200){
                if(value.data.data){
                    this.dataList = value.data.data;
                }
                if(value.data.total){
                    this.total = value.data.total
                }
                
            }
        })
    },
    ask(){
        this.dialogVisible = true;
    },
    submit(){
        this.addQuestion();
        this.dialogVisible = false;
    },
    cancle(){
        this.dialogVisible = false;
        this.pinglunFlag = false;
    },
    lookreply(item){
        console.log(item,'item')
        this.pinglunFlag = true;
        this.firstId = item.reply.user_id;
        this.curreplyId= item.reply.id;
        this.replyList(item.reply.id)
        console.log(this.pinglunList,'this.pinglunList')
    },
    sendInfo(){
        let params ={
            content:this.huifucontent,
            pid:this.curreplyId,
            to_user_id:this.firstId
        }
        http.addQuestion(params).then(value=>{
            if(value.code == 200){
                this.$message.success('发布成功')
                this.replyList(this.curreplyId)
                this.huifucontent=''
            }
        })
    },
    // 发布提问
    addQuestion(){
        let params ={
            content:this.askContent,
            
        }
        http.addQuestion(params).then(value=>{
            if(value.code == 200){
                this.$message.success('发布成功')
                
            }
        })
    },
    //  我的提问
    myQuestion(){
        http.myQuestion().then(value=>{
            if(value.code == 200){
                this.dataList = value.data.data;
            }
        })
    },
    // 评论回复列表
    replyList(comment_id){
        let params = {
            comment_id,
            
        }
        http.replyList(params).then(value=>{
            if(value.code == 200){
                this.pinglunList = value.data;
            }
        })
    },
    // 点赞/取消
    questionGoods(comment_id){
        let params = {
            comment_id,
            
        }
        http.questionGoods(params).then(value=>{
            if(value.code == 200){
               this.questionList()
            }
        })
    },
  },
  created() {
      this.questionList()
  },
  mounted() {

  },
  components: {},
}
</script>

<style lang="scss" scoped>
.answer_tag{
    display:flex;
    padding-bottom: 16px;
    border-bottom: 1px solid #DCDCDC;
    justify-content: space-between;
    .ans_tab_list{
        display: flex;
        flex:1;
        flex-wrap: wrap;
        .ans_tab_list_item{
            cursor: pointer;
            padding:7px 16px;
            border-radius: 9px;
            color:#333;
            font-size:14px;
            &.active{
                background:#E1410FFF;
                color:#fff;
            }
        }
    }
}
.ans_content{
    .ans_total{
        font-size:14px;
        color:#666666;
        margin-top:14px;
        margin-bottom: 16px;
        display:flex;
        p{
            margin-right:20px;
        }
    }
    
}
.ques_list{
    padding:25px;
    display:flex;
    justify-content: space-between;
    border-bottom:1px solid #ccc;
    .head_img{
        width:67px;
        height:67px;
        border-radius: 50%;
    }
    .head_name{
        margin-bottom:10px;
        color:#333333;
        // text-align: center;
    }
    .per_info{
        margin-right:30px;
    }
    .ques_list_con{
        flex:1;
        display:flex;
        flex-direction: column;
        // justify-content: space-between;
        // height: 114px;
    }
    .teacher_ans{
        background:#f8f8fb;
        color:#515f68;
        margin-top:10px;
        margin-bottom:15px;
        padding-left:30px;
        span{
            color:#9aacb7;
            font-size:14px;
        }
    }
    .ques_info{
        display:flex;
        color:#999999;
        font-size:14px;
        justify-content: space-between;
    }
    .ques_info_reply{
        margin-left:20px;
        display:flex;
    }
    .zan{
        cursor: pointer;
        display:flex;
        align-items: center;
        margin-left:20px;
        line-height:20px;
        img{
            width:15px;
            height:15px;
            margin-right:5px;
        }
        span{}
    }
}
.dialog_title{
    text-align: center;
    color:#333333;
    font-size: 20px;
}
.ask_content{
    width:80%;
    margin:0 auto;
    margin-top:25px;
    border: 1px solid #ccc;
    border-radius: 7px;
    box-sizing: border-box;
    padding-right:2px;
    textarea{
        width:99%;
            height:200px;
            border:none;
            outline:none;
            resize:none;
            // background: #f8f8fb;
    }
}
/deep/ .el-dialog__header{
    display:none
}
/deep/.el-dialog{
    border-radius:20px;
}
.ask_footer{
    display:flex;
    justify-content: space-between;
    margin:0 auto;
    margin-top:20px;
    width:80%;
    span{
        border:1px solid #ccc;
        border-radius:16px;
        flex:0.48;
        height: 52px;
        text-align: center;
        line-height: 52px;
        color:#272C2F;
        font-size:16px;
        cursor: pointer;
        &.submit{
            background:#DC091D;
            color:#fff;
        }
    }
}
.pl_list{
    display:flex;
    justify-content: space-between;
    .pl_list_person{
        display:flex;
        flex-direction: column;
        margin-right:20px;
        img{
            width:50px;
            height:50px;
            border-radius:50%;
        }
        
    }
    .pl_list_name{
            margin-bottom:10px;
            font-size:14px;
            color:#333;
        }
    .pl_list_content{
        display:flex;
        flex:1;
        flex-direction: column;
    }
    .pl_list_info{
        display:flex;
        justify-content: space-between;
        margin-top:10px;
        .ques_info_reply{
            margin-left:20px;
            display:flex;
        }
        .zan{
            cursor: pointer;
            display:flex;
            align-items: center;
            margin-left:20px;
            line-height:20px;
            img{
                width:15px;
                height:15px;
                margin-right:5px;
            }
            span{}
        }
    }
}
.pl_list_container{
    max-height:500px;
    overflow: auto;
}
.my_ask{
    margin-top:20px;
    display:flex;
    .sendInfo{
        margin-left:20px;
    }
}
</style>