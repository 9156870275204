import axios from 'axios';
import svue from '../main.ts'
// 拦截器
axios.interceptors.request.use(
  config => {
    config.method ='post' ;
    return config
  },
  err => {
    return Promise.reject(err)
  })
axios.interceptors.response.use(
  config => {
    if(config.data.code == 600){
      if(!JSON.parse(sessionStorage.getItem('uid'))){
        svue.$message.warning('请登录')
      }else{
        svue.$message.warning(config.data.msg)
      }
      
      window.location.href = "#/login";
    }else{
      return config
    }
    
  },
  err => {
    return Promise.reject(err)
  })
export default{
    request(url,data={}){
      data.uid|| (data.uid = JSON.parse(sessionStorage.getItem('uid')));
      data.token|| (data.token = JSON.parse(sessionStorage.getItem('token')));
        let options = {
            url,
            data,
            baseURL: 'https://app.wyfashuo.com/api'
            // baseURL: 'https://wenyun.1234ok.cn/api'
        }
        return axios(options).then(res => {
          return res
        }).catch( (error)=> {
            return error
        });
    }
}